<form (submit)="onSubmitRegister()" [formGroup]="formRegister" id="formRegister" >

  <br>
  <div class="row">
    <div class="col-sm-5 col-sm-5-mobiyo ">
      <label class="text-left" for="inputEmail">Votre email </label>
      <!-- Invalid  Email-->
      <div *ngIf="f.customerEmail.errors" class="row" id="invalidEmail-mobiyo">
        <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern" class="col-sm-10">
          <p class=" notice-error-message">
            Votre email est incorrect
          </p>
        </div>
      </div>

    </div>
    <div class="col-sm-5 col-sm-5-mobiyo">
      <input
        [class.is-invalid]="(f.customerEmail.errors && !f.customerEmail.errors.required)|| emailExist ==  true "
        [ngClass]="{ 'is-valid': f.customerEmail.valid}"
        class="form-control "
        formControlName="customerEmail"
        id="inputEmail"
        placeholder=""
        type="email"
      >
    </div>
  </div>
  <br>

  <div class="row">
    <div class="col-sm-5 col-sm-5-mobiyo">
      <label class="text-left ml-0" > Votre mot de passe </label>
      <!-- Password not Valid -->
      <div *ngIf="f.customerPassword.errors?.pattern" class="row" id="invalidPassword-mobiyo">
        <div *ngIf="f.customerPassword.errors.pattern" class="col-sm-10">
          <p class="col-sm-5 notice-error-message" style="max-width:100%">
            {{regexPasswordText}}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-5 col-sm-5-mobiyo">
      <input
        [class.is-invalid]="f.customerPassword.errors?.pattern"
        [ngClass]="{ 'is-valid': f.customerPassword.valid }" class="form-control"
        formControlName="customerPassword"
        placeholder=""
        type="text"
      >
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-5 col-sm-5-mobiyo">
      <label class="text-left"> Vérification du mot de passe </label>
      <!-- Password not matching -->
      <div *ngIf="f.confirmPassword.hasError('mustMatch') " class="row" id="passwordNotmatching-mobiyo">
        <div class="col-sm-10">
          <p class="col-sm-5 notice-error-message" style="max-width:100%">Votre deuxième mot de passe est différent du
            premier. </p>
        </div>
      </div>
    </div>

    <div class="col-sm-5 col-sm-5-mobiyo">
      <input

        [class.is-invalid]=" f.confirmPassword.hasError('mustMatch')"
        [ngClass]="{ 'is-valid': f.confirmPassword.valid }" class="form-control"
        formControlName="confirmPassword"
        placeholder=""
        type="text"
      >


    </div>

  </div>
  <br>


  <div class="row">
    <div class=" col-sm-3 offset-sm-5 offset-sm-5-mobiyo">
      <button [disabled]="formRegister.invalid" class="btn btn-sm btn-success text-center btn-mobiyo" type="submit">

        Valider
      </button>
    </div>
  </div>
  <!-- Email Already used-->
  <div *ngIf="f.customerEmail.hasError('emailExist') && !f.customerEmail.errors.required" class="row"
       id="usedEmail-mobiyo">
    <div *ngIf="f.customerEmail.errors " class="col-sm-10">
      <label class="col-sm-4 notice-error-message-email">
        Cet email est déja utilisé !
      </label>
      <a (click)="closeRegisterModal()" class="btn-link col-sm-4 nav-link-mobiyo"
         data-toggle="modal" data-target="#registerModal">Identifiez-vous</a>
    </div>
  </div>


</form>
<br>



<!-- REVIEWS ADD -->

<div class="spinner-border spinner-border-sm d-inline-block ml-2 mb-1" role="status" *ngIf="!(transaction.call_duration )">
  <span class="sr-only">Loading...</span>
</div>

<div class="tabs-item active" *ngIf="transaction.call_duration">

      <div class="row">
        <div class="col-sm-12">
          <p>Conversation Privée effectuée avec {{consultantName}} le {{transaction.date.replaceAll('-','/') | slice:0:16|date:'dd/MM/yyyy'}} à {{transaction.date.replaceAll('-','/') | slice:0:16|date:'H:mm'}}</p>
        </div>
       </div>

      <div class="row">
        <div class="col-sm-12">
          <p>Durée totale :
            <span *ngIf="(transaction.call_duration * 1000 | date:'H':'UTC')!='0'">
            {{  (transaction.call_duration * 1000 | date:'H':'UTC')}} h
            </span>
            <span *ngIf="(transaction.call_duration * 1000 | date:'mm':'UTC')!='0'">
              {{ transaction.call_duration * 1000 | date:'mm':'UTC'}} min
            </span>
            {{ transaction.call_duration * 1000 | date:'ss':'UTC'}} s
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p>Débit sur votre moyen de paiement : {{transaction.amount | number:'1.2-2' }}   €</p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p>Notez la prestation :
            <span style="font-size: 24px">
              <ngb-rating class="text-warning" [(rate)]="customerNoticeNotation" (hover)="hovered=$event" (leave)="hovered=0" [readonly]="readonly"></ngb-rating>
            </span>
          </p>
        </div>
      </div>

    <form [formGroup]="formAddReview" (ngSubmit)="onSubmit()">
      <div class="row mb-4">
        <div class="form-group col-sm-12">
            <label for="customerRemark">Postez un commentaire</label>
            <textarea
              formControlName="customerRemark"
              type="email"
              class="form-control"
              id="customerRemark"
              autocomplete="off"
            >
            </textarea>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-sm-12">
          <button type="button" class="btn btn-success-mobiyo" [disabled]="customerNoticeNotation==0" type="submit">Continuer</button>
        </div>
      </div>


    </form>

</div>


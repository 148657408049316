import {Route, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Domain} from '../_models/domain';
import {Banner} from '../_models/banner';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AudiotelBannerService {
  private apiUrlAudiotelBanner = environment.apiUrlBanner;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  getAudiotelBanner(bannerIdentifier, cabinetId , merchantId , startDate) {
    const params = new HttpParams({
      fromObject: {
        bannerIdentifier,
        cabinetId,
        merchantId,
        startDate
      }
    });

    return this.http.get<Banner>(`${this.apiUrlAudiotelBanner}/get-banner`, {params});
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {PaiementService} from '../_services/paiement.service';
import {HomeService} from '../_services/home.service';
import {Definitions} from '../_models/definitions';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  @Input() definitions: Definitions;
  cardNumber: string;
  hiddenValueCard = 'xxxxxx';

  constructor(
    public paiementService: PaiementService,
    public homeService: HomeService
  ) {
  }

  ngOnInit(): void {
    this.checkPaymentSuccess();
  }

  checkPaymentSuccess() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeResponse = urlParams.get('codeResponse');
    const twoLastDigits = urlParams.get('twoLastDigits');
    const sixFirstDigits = urlParams.get('sixFirstDigits');
    if (twoLastDigits !== null && sixFirstDigits !== null) {
      this.cardNumber = sixFirstDigits.concat(this.hiddenValueCard, twoLastDigits);
    }
    if (codeResponse !== null) {
      if (codeResponse === '00000') {
        window.history.replaceState(null, null, window.location.pathname);
        document.getElementById('openModalAddPaymentCardSuccess').click();
      } else {
        document.getElementById('openModalAddPaymentCardFailed').click();
      }
    }

  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../_services/profile.service';
import { RegisterService } from '../../_services/register.service';
import { Definitions } from '../../_models/definitions';
import { DefinitionService } from '../../_services/definition.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';


declare var $: any;

@Component({
  selector: 'app-first-name-update',
  templateUrl: './first-name-update.component.html',
  styleUrls: ['./first-name-update.component.css']
})
export class FirstNameUpdateComponent implements OnInit {

  @Input() customerFirstName: string;
  customerOldFirstName: string;
  form: FormGroup;
  definitions: Observable<Definitions>;
  usingSendethic: boolean = false;
  sendethicApiKey: string = '';
  infoDefinitions: any;
  hasError = false;
  @Output() newFirstName = new EventEmitter<string>();
  formData: any;
  success = false;
  error = false;

  constructor(
    private fb: FormBuilder,
    private registerservice: RegisterService,
    private definitionService: DefinitionService,
    private profileService: ProfileService,
    private store: Store<AppState>
  ) {
    this.definitions = store.select('definitions');
    this.definitions.subscribe((definitions) => {
      this.infoDefinitions = definitions;
    });
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {

    this.definitions.subscribe(definitions => {
      this.usingSendethic = definitions?.using_sendethic;
      this.sendethicApiKey = definitions?.sendethic_api_key;
    });

    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerOldFirstName: [{ value: '', disabled: true }],
      customerFirstName: ['', [
        Validators.required,
        Validators.pattern('[a-zA-Z _\\-,\'éèà]+$')
      ]],
    });
    $('#update-first-name-modal')
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerOldFirstName: this.customerFirstName,
          customerFirstName: this.customerFirstName
        });
      });
  }

  onSubmit() {

    if (this.form.valid) {
      this.form.value.customerFirstName = this.form.value.customerFirstName.trim();

      this.profileService.accountUpdatePersonal(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            $('.modal-backdrop').remove();
            $('.show').remove();
            $('#update-first-name-modal').modal('hide');
            this.newFirstName.emit(this.form.value.customerFirstName);
            if (this.usingSendethic) {
              this.registerservice.addContactFirstnameInSendEthic(this.sendethicApiKey, localStorage.getItem('customerEmail'), this.form.value.customerFirstName).subscribe(
                (result: any) => {
                  if (result.statusCode === undefined) {
                  } else {
                    console.log(result?.message);
                    console.log(result?.body);
                  }
                }
              );
            }

          } else {
            console.log('error: ', result);
            this.next(false);
          }
        },
        (error) => {
          console.log('error :', error);
          this.next(false);
        }
      );
    } else {
      console.log('Form not Valid', this.form.errors);
      this.next(false);
    }

  }

  next(state = true): void {
    this.success = state;
    this.error = !state;
  }

  clearError() {
    this.hasError = false;
  }

  onClickBtn() {
    $('#update-first-name-modal').modal('hide');
  }

  onOpenModal() {
    this.success = false;
    this.error = false;
  }


}

<div  class="container mt-4">

<div class="row">
  <div class="col-sm-3">
    <h6 class="">{{consultantName}}</h6>
    <i [class.text-danger]="callInProgress == true" [class.text-success]="callConsultantConnected == true"
       class="fa fa-phone phone fa-4x"></i>

  </div>
  <div class="col-sm-2 mt-lg-5">
    <span *ngIf="!callEnd">
      <i class="i-border-left arrow-left first-arrow"></i>
      <i class=" i-border-left arrow-left second-arrow"></i>
      <i class="i-border-left arrow-left third-arrow"></i>
    </span>
  </div>
  <div class="col-sm-3 mt-lg-5 font-weight-bold ">
    <h3>{{(definitions | async)?.sms_brand}}</h3>
  </div>
  <div *ngIf="callClientInProgress == true || this.callClientConnected == true" class="col-sm-2 mt-lg-5">
    <span *ngIf="!callEnd">
      <i class="i-border-right arrow-right first-arrow"></i>
      <i class="i-border-right arrow-right second-arrow"></i>
      <i class="i-border-right arrow-right third-arrow"></i>
    </span>
  </div>
  <div *ngIf="callClientInProgress == true || this.callClientConnected == true" class="col-sm-1">
    <h6>Vous</h6>
    <i
       [class.text-danger]="(callClientInProgress == true && this.callClientConnected == false) || callEnd"
       [class.text-success]="callClientConnected == true && !callEnd"
       class="fa fa-phone phone  fa-4x"
       style="-webkit-transform: rotate(250deg) !important;"
    >

    </i>
  </div>

</div>
<div class="row mt-lg-2 align-items-center">
  <div class="col-sm-8 offset-md-4">
    <h5 *ngIf="callInProgress == true " class="justify-content-center">{{(definitions | async)?.sms_brand}} appelle
      votre
      consultant {{consultantName}}</h5>
    <div *ngIf="callClientInProgress == true && callConsultantConnected == true " class="justify-content-center">
      <h5>Votre consultant {{consultantName}}  vous attend.</h5>
      <h5>Maintenant {{(definitions | async)?.sms_brand}} vous appelle sur votre téléphone.</h5>

    </div>
    <div *ngIf="callClientConnected == true " class="justify-content-center">
      <h5>Vous êtes en ligne avec {{consultantName}}, votre consultant.</h5>
      <h6>  Durée de la Conversation Privée: <span class="text-success">{{conversationDuration}}</span> </h6>
    </div>

  </div>

</div>

</div>

<div aria-hidden="false" aria-labelledby="ConsultantLostLabel" class="modal fade" id="ConsultantLost" role="dialog"
     tabindex="-1">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p>{{consultantName}} n'a pas répondu à l'appel d'{{(definitions | async)?.sms_brand}}</p>
        <p>La conversation privée est annulée !</p>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button class="btn btn-success btn-lg " data-dismiss="modal" type="button">Continuer</button>
        </div>

      </div>

    </div>
  </div>
</div>


<div aria-hidden="false"  class="modal fade" id="ClientLost" role="dialog"
     tabindex="-1">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title justify-content-center">Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p>Vous n'avez pas répondu à l'appel d'{{(definitions | async)?.sms_brand}}</p>
        <p>La conversation privée est annulée !</p>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button class="btn btn-success btn-lg" data-dismiss="modal" type="button">Continuer</button>

        </div>

      </div>

    </div>
  </div>
</div>


<div aria-hidden="false" aria-labelledby="BillingActivatedLabel" data-backdrop="static" class="modal fade" id="BillingActivated" role="dialog"
     tabindex="-1">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="text-center">
          <p>Votre conversation avec {{consultantName}} est terminée.</p>
          <p>Durée totale: {{conversationDuration}} pour {{totalformated}}€</p>
          <p>Votre paiement est en cours</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button class="btn btn-success btn-lg"  data-dismiss="modal" type="button">Continuer</button>
        </div>
      </div>

    </div>
  </div>
</div>
<div aria-hidden="false" aria-labelledby="BillingDeactivatedLabel" data-backdrop="static" class="modal fade" id="BillingDeactivated"
     role="dialog"
     tabindex="-1">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title justify-content-center">Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="redirectToHomePage()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="text-center">
          <p>Votre conversation est terminé.</p>
          <p>Elle ne sera pas facturée !</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button class="btn btn-success btn-lg " (click)="homeService.redirect('/')" data-dismiss="modal" type="button">Continuer</button>

        </div>

      </div>

    </div>
  </div>
</div>

<div aria-hidden="false"  class="modal fade" data-backdrop="static" id="ModalErrorStartConversation" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title justify-content-center" >Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="redirectToHomePage()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>
              Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer la conversation privée
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}}
          au {{(definitions | async)?.provider_call_number}}</p>

      </div>
      <div class="modal-footer">
        <button class="btn btn-success text-center" (click)="homeService.redirect('/')" data-dismiss="modal" type="button">Continuer</button>

      </div>
    </div>
  </div>
</div>


<div aria-hidden="false" class="modal fade" data-backdrop="static" id="ModalErrorContinueConversation" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title justify-content-center" >Conversation privée </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="redirectToHomePage()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>
              Une erreur vient de survenir et nous ne sommes pas en mesure de continuer votre conversation privée
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}}
          au {{(definitions | async)?.provider_call_number}}</p>

      </div>
      <div class="modal-footer">
        <button class="btn btn-success text-center" (click)="homeService.redirect('/')" data-dismiss="modal" type="button">Continuer</button>
      </div>
    </div>
  </div>
</div>



<!-- Modal consultant reviews -->

<div  class="modal fade" id="review" tabindex="-1" role="dialog" aria-labelledby="reviewLabel" aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="reviewLabel">{{consultantName}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="customerNoticeEnabled" class="p-4">

      <app-add-review id="addNotice"
                        [consultantId]="consultantId"
                        [consultantName]="consultantName"
                        [transacationId]="lastTransactionId"
                        [closeModal] = true
                        [redirect] = false
                        [numberOfCall] = "numberOfExtendedCall"
        ></app-add-review>

      </div>
      </div>
  </div>
</div>


<div class="modal fade" id="converationReSelect" tabindex="-1" role="dialog" aria-labelledby="converationSelectLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prolongation d'appel - Sélection du tarif</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-select-private-conversation
        *ngIf="consultantId"
        [consultantId]="consultantId"
        [consultantName]="consultantName"
        [price]="consultantCost"
        [isExtendCall]= true
      ></app-select-private-conversation>
    </div>
  </div>
</div>


<div class="modal fade" id="errorExtendCall" tabindex="-1" role="dialog" aria-labelledby="errorExtendCallLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prolongation d'appel</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-8 m-auto">
            <p>Impossible de prolonger l'appel</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private apiKey = environment.apiKey;
  apiUrl = environment.apiUrlAccountCustomer;
  constructor(private http: HttpClient) { }

  addMember(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`)
      .set('Content-Type', 'application/json');
    formData.merchantId = environment.merchantId;
    formData.officeId = environment.officeId;
 
    return this.http.post(`${this.apiUrl}/newsletter-member-create`, formData, { headers: header });
  }
}

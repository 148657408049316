

  <div class="container">
    <h2 class="text-center mb-3 form-contact-us"> ABONNEZ VOUS A NOTRE NEWSLETTER </h2>
    <p class="text-center contact-title"> <b>Découvrez nos derniers promotions</b> </p>
    <div class="row">
      <div class="col-md-6 offset-md-3">
          <form [formGroup]="formAddmember" (submit)="onSubmitAddNewMemberToNewsLetter()" id="formAddmember">

            
            <div class="form-group">
              <label for="firstname">Prénom : </label>
              <input
              class="input-contact"
              [class.is-invalid]="(f.firstname.errors && !f.firstname.errors.required) "
              [ngClass]="{ 'is-valid': f.firstname.valid}"
              class="form-control "
              formControlName="firstname"
              id="inputfirstname"
              placeholder=""
              type="text"
              >
        
          </div>
          <div class="form-group">
            <label for="lastname">Nom : </label>
            <input
            class="input-contact"
            [class.is-invalid]="(f.lastname.errors && !f.lastname.errors.required) "
            [ngClass]="{ 'is-valid': f.lastname.valid}"
            class="form-control "
            formControlName="lastname"
            id="inputlastname"
            placeholder=""
            type="text"
            >
           
        </div>
        <div class="form-group">
          <label for="birth-date">Date de Naissance</label>
          <div class="input-group">
            <input
              id="birth-date"
              class="form-control"
              placeholder="JJ/MM/AAAA"
              name="dp"
              formControlName="customerBirthday"
              ngbDatepicker
              #d="ngbDatepicker"
              autocomplete="off"
              (click)="d.toggle()"
              [class.is-valid]="f.customerBirthday.valid"
              [class.is-invalid]="submitted && f.customerBirthday.invalid"
              [minDate]="minDate"
              [maxDate]="maxDate"
            >
            <div class="input-group-append">
              <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
                <div class="form-group">
                    <label for="Email">Votre email : </label>
                    <input
                    class="input-contact"
                    [class.is-invalid]="(f.customerEmail.errors && !f.customerEmail.errors.required) "
                    [ngClass]="{ 'is-valid': f.customerEmail.valid}"
                    class="form-control "
                    formControlName="customerEmail"
                    id="inputEmail"
                    placeholder="example@example.com"
                    type="email"
                    >
                    <div *ngIf="f.customerEmail.errors" class="row" id="invalidEmail-mobiyo">
                        <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern" class="col-sm-10">
                          <p class=" notice-error-message">
                            Votre email est incorrect
                          </p>
                        </div>
                    </div>
                </div>

        
                <div >
                  <label for="Email">Votre Numéro de téléphone : </label>
                  <div class="row align-items-center">
                    <div class="col-sm-1" style="font-size: 30px">
                      <ngx-select-flags
                        [selectedCountryCode]="selectedCountryCode"
                        [countryCodes]="countryCodes"
                        [showLabels]="false"
                        (changedCountryCode)="changeCodeNumber($event)"
                      ></ngx-select-flags>

                  
                    </div>
                    <div class="col-sm-11">
               
                      <input
                        type="text"
                        formControlName="memberCallNumber"
                        class="form-control"
                        [ngClass]="{'is-invalid':f.memberCallNumber.errors && !f.memberCallNumber.errors.required}"
                        [class.is-valid]=" f.memberCallNumber.valid"
                        required>
                        </div>
                  
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <label><strong></strong></label>
                    <div class="checkbox-group" [class.is-invalid]="f['choicesFormGroup'].invalid">
                      <div *ngFor="let item of choices; let i = index" class="checkbox">
                        <label><input type="checkbox" [formControl]="choicesFormGroup?.controls[item.id]" /> {{ choices[i]?.title }}
                        </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" role="alert">
                    </div>
                   
                  </div>  
                <div class="form-group">
                  
                  <label for="acceptTerms" class="">
                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" 
                 
                  [class.is-invalid]="(submitted && f.acceptTerms.errors) "
                  [ngClass]="{ 'is-valid': submitted && f.acceptTerms.valid}"
                   />
                    J'accepte les conditions Générales de vente ainsi que la politique de Données personnelles et Cookies.</label>
                </div>
                
              <div class="row">
                <div class=" col-sm-3 offset-sm-5 offset-sm-5-mobiyo">
                  <button [disabled]="formAddmember.invalid" class="btn btn-sm btn-success text-center btn-mobiyo btn-contact-form" type="submit">
            
                    Valider
                  </button>
                </div>
              </div>
         </form>
        </div>
    </div>
</div>
  
  
  


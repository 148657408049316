import { Session } from '../_models/session';
import * as SessionActions from '../actions/session.actions';

const initialState: Session = {
  isLoggedIn: (!localStorage.getItem('customerSessionId') || !localStorage.getItem('customerId')) ? false : null,
  customerSessionId: localStorage.getItem('customerSessionId') || null,
  customerId: parseInt(localStorage.getItem('customerId'), 10) || null,
  customerEmail: null,
  selectedConsultantId: null,
  consultantCallNumber: null,
  consultantName: null
}

export function reducer(state: Session = initialState, action: SessionActions.Actions) {
  switch (action.type) {
    case SessionActions.SET_SESSION:
      return action.payload;
    case SessionActions.PUT_SESSION:
      state = {...state, ...action.payload};
      return state;
    case SessionActions.DELETE_SESSION:
      return undefined;
    default:
      return state;
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class HomeService {

  constructor(
    private  router: Router
  ) {}

  resetForm() {
    $('#formlogin').trigger('reset');
  }

  resetFormRegister() {
    $('#formRegister').trigger('reset');
  }

  outerRestForm() {
    document.addEventListener('click', e => {
      const target = e.target as HTMLTextAreaElement;
      if (target.className === 'modal fade') {
        this.resetForm();
      }
    }, false);
  }

  outerRestFormRegister() {
    document.addEventListener('click', e => {
      const target = e.target as HTMLTextAreaElement;
      $('registerModal').hide();
      if (target.className === 'modal fade') {
        this.resetFormRegister();

      }
    }, false);
  }
  redirect(redirectTo) {
       window.location.href = redirectTo;
      /*this.router.navigateByUrl(redirectTo).then(() => {
          window.location.reload();
        });*/
  }
}

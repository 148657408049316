import {Component, OnInit} from '@angular/core';
import {PaiementService} from '../../_services/paiement.service';
import {environment} from '../../../environments/environment';
import {HomeService} from '../../_services/home.service';
declare var $: any;

@Component({
  selector: 'app-paiement',
  templateUrl: './add-payment-card.component.html',
  styleUrls: ['./add-payment-card.component.css']
})
export class AddPaymentCardComponent implements OnInit {
  cardslist: any;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  merchandId = environment.merchantId;
  paymentId: number;
  expirationDate: number;
  isDefaultCardEmpty: boolean;
  defaultCard: boolean;
  isCardExpired: boolean;
  statusCard = false;
  data: any;
  newDate = new Date();
  currentYear = this.newDate.getFullYear();
  availableCards = 0;

  constructor(
    private paiementservice: PaiementService,
    public homeService: HomeService
  ) {
  }

  ngOnInit(): void {
    this.cardList();

  }

// retrieve  all cards
  cardList() {
    this.paiementservice.sendGetcards(this.customerId, this.merchandId).subscribe(
      (res: any) => {

      if (res.statusCode === undefined) {
          this.setDefaultCard(res.data.cards);
          this.cardslist = res.data.cards;
          for (const card of this.cardslist) {
          const expired = parseInt(card.exp_year, 10) < this.currentYear;
          if (card.active && !expired) {
            this.availableCards++;
          }
        }
      } else {
        $('#ModalErrorCardList').modal('show');

      }
      },
      error => {
        $('#ModalErrorCardList').modal('show');
      }
    );
  }

  // add a new card
  onSubmitSetCard() {
    this.paiementservice.addCard();
    this.cardList();
  }

  // deactivate a card
  OnSubmitStatusCard(merchantId, customerId, paymentId) {
    this.data = {
      merchantId,
      customerId,
      paymentId,
      statusCard: this.statusCard

    };
    document.getElementById('openModalDeleteCard').click();
    document.getElementById('deleteCard').addEventListener('click', () => {
      this.paiementservice.setStatusCard(this.data).subscribe(
        (result: any[]) => {
          this.cardList();
        },
        error => {
          console.log('error');
        }
      );
    });
  }

  // make card primary
  OnsubmitPrimary(merchantId, customerId, paymentId) {
    this.data = {
      merchantId,
      customerId,
      paymentId
    };

    this.paiementservice.makeCardPrimary(this.data).subscribe(
      (result: any[]) => {
        this.cardList();
      },
      error => {
        console.log('error');
        document.getElementById('openModalErrorPrimary').click();
      }
    );
  }

  // set default card
  setDefaultCard(cardData) {
    this.isDefaultCardEmpty = false;
    let val = 0;
    for (const card of cardData) {
      if (card.active === true) {
        this.expirationDate = parseInt(card.exp_year, 10);
        if (this.expirationDate >= this.currentYear && card.main_payment === true) {
          return;
        } else {
          this.paymentId = card.id;
          val = val + 1;
          this.isDefaultCardEmpty = true;
        }

      }
    }
    if (this.isDefaultCardEmpty === true) {
      this.data = {
        merchantId: this.merchandId,
        customerId: this.customerId,
        paymentId: this.paymentId
      };
      this.paiementservice.makeCardPrimary(this.data).subscribe(
        (result: any) => {
          if (result.data.status === 'OK') {
            this.ngOnInit();
          }
        },
        err => {
          console.log(err);
        }
      );
      return;

    }


  }

  checkExpirationDate(input: string) {
    this.expirationDate = parseInt(input, 10);
    this.isCardExpired = this.expirationDate < this.currentYear;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ConsultantProfileComponent} from './consultant-profile/consultant-profile.component';
import {ConsultantComponent} from './consultant.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReviewModule} from '../review/review.module';
import {PrivateConversationModule} from '../private-conversation/private-conversation.module';
import { SvaComponent } from './sva/sva.component';
import {PromotionModule} from '../promotion/promotion.module';
import { ConsultantPlanningComponent } from './consultant-planning/consultant-planning.component';
import {NgxContentLoadingModule} from 'ngx-content-loading';
import { ConsultantPrivateConversationComponent } from './consultant-private-conversation/consultant-private-conversation.component';
import { ConsultantLivePrivateConversationComponent } from './consultant-live-private-conversation/consultant-live-private-conversation.component';
import { ConsultantLiveAudiotelComponent } from './consultant-live-audiotel/consultant-live-audiotel.component';


import { ChatModalComponent } from '../chat/chat-modal/chat-modal.component';
import { SelectChatPackComponent } from '../chat/select-chat-pack/select-chat-pack.component';
import { FinishPaymentComponent } from '../chat/finish-payment/finish-payment.component';
import { AvatarComponent } from '../chat/avatar/avatar.component';

import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { DeliveryComponent } from '../shared/delivery/delivery.component';
@NgModule({
  declarations: [
    ConsultantProfileComponent,
    ConsultantComponent,
    SvaComponent,
    ConsultantPlanningComponent,
    ConsultantPrivateConversationComponent,
    ConsultantLivePrivateConversationComponent,
    ConsultantLiveAudiotelComponent,
    ChatModalComponent,
    SelectChatPackComponent,
    FinishPaymentComponent,
    AvatarComponent,
    SpinnerComponent,
    DeliveryComponent

  ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SlickCarouselModule,
        NgbModule,
        ReviewModule,
        PrivateConversationModule,
        PromotionModule,
        NgxContentLoadingModule
    ],
  exports: [
    ConsultantComponent ,
    ConsultantProfileComponent,
    SvaComponent
  ]
})
export class ConsultantModule { }

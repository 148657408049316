import { Component, Input, OnInit, } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConsultantService } from '../_services/consultant.service';
import 'dayjs/locale/fr';
import * as dayjs from 'dayjs';
import { LoginService } from '../_services/login.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CryptoService } from '../_services/crypto.service';
import { RegisterService } from '../_services/register.service';
import { Account } from '../_models/account';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Definitions } from '../_models/definitions';
import { PaiementService } from '../_services/paiement.service';
import { paidPack as PaidPackSchema } from '../_models/paidPack'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NoticeService} from '../_services/notice.service';
import {
  NgbRatingConfig
} from '@ng-bootstrap/ng-bootstrap';
import {HomeService} from '../_services/home.service';
declare var $: any

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [NgbRatingConfig]
})
export class ChatComponent implements OnInit {
  // Consultants ---------------------
  account: Observable<Account>;
  servicetype: string = 'Chat';
  @Input() timeZoneClient: string;
  isPlanned: boolean;
  definitions: Observable<Definitions>;
  setfilter: boolean;
  data: any;
  isValidtoken: boolean;
  isLoggedIn$: Observable<boolean>;
  private isLoggedIn: boolean;
  customerID: number = null;
  customerInfo: {} = null;
  listData: any;
  listDataFiltered: any = [];
  listDataOriginal: any;
  consultationModesFilter: any = [];
  voyanceTypesFilter: object = null;
  languagesTypesFilter: object = null;
  private apiKey = environment.apiKey;
  filters: any = { consultantName: '', consultationModesFilter: [], voyanceTypesFilter: [], languagesTypesFilter: [], timeFrames: '' };
  consultantNotice: any;
  consultantReviews: any;
  transactions: any;
  consultantImageUrl: any;
  consultantName: any;
  customerNoticeNotation = 0;
  hovered = 0;
  readonly = false;
  customerRemark: string;
  tooltipClass: string;
  startHour;
  endHour;
  customerCountries: string[];
  consultantInfo: any;
  result: any;
  isLogging: boolean;
  langTitle = { FR: '', ES: '¡ Yo también hablo español !', EN: 'I also speak english !' };
  consultationModesTitle = {
    AUDIOTEL: 'Je consulte en appelant un numéro surtaxé',
    CB: ' Avec ma CB, j\'effectue une conversation privée',
    CHAT: 'Avec ma CB, je chatte avec un consultant',
    MAIL: 'Avec ma CB, je reçois ma consultation par email'
  };
  public showAddNotice: any;
  public showAllNotice: any;
  activateLink = false;
  voyanceTypes = [
    'tarologist_type',
    'runes_type',
    'astrologer_type',
    'fortune_teller_type',
    'oracle_type',
    'numerologist_type',
    'psychic_seer_type',
    'pendulum_type',
    'dowsing_type',
    'medium_type',
  ];
  loading = true;
  timeFrames = [];
  public slideConfig: {};
  startSlider: number;
  listDataCloned = [];
  isDisabled: any;
  dayName: string;
  dayNumber: string;
  yearNumber: string;
  monthName: string;
  transactionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // ---------------------------------

  private apiUrl = environment.apiUrlChat;

  /**
   * Chat modal
   */
  selectedConsultant: any = null;
  chatModalIsVisible: Boolean = false;

  // Payment
  emptyCard: boolean;
  paymentModalIsVisible: boolean = false;
  paidPack: PaidPackSchema = null;
  finishPaymentModalIsVisible: Boolean = false;

  constructor(
    private registerService: RegisterService,
    public consultantservice: ConsultantService,
    private paiementService: PaiementService,
    private store: Store<AppState>,
    private crypto: CryptoService,
    private http: HttpClient,
    config: NgbRatingConfig,
    private  noticeService: NoticeService,
    private cryptoService: CryptoService,
    private homeService: HomeService,
  ) {
    this.definitions = store.select('definitions');
    this.account = store.select('account');
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit(): void {
    // Get the connected user from local storage
    this.account.subscribe((account) => {
      this.customerID = account?.customerId
      this.customerInfo = account
    });

    if (!this.timeZoneClient) {
      this.timeZoneClient = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    this.listConsultant(this.servicetype, this.isPlanned, this.setfilter);
  }

  // /**
  //  * - Close if there's any prior open modal
  //  * - Retrieve if there's any old conversation
  //  *    - There's and the remaining_time is greater than zero: Open chat modal
  //  *    - Else: Process the pack payment
  //  * @param Number consultantID
  //  * @returns void
  //  */
  // openConversation = (consultantID) => {
  //   /**
  //    * Make sure to close if there's any open modal (payment and chat)
  //    */
  //   this.closeConversation()
  //   console.log('openConversation 1')
  //   // Make sure the chosen consultant does exist
  //   const index = this.listData.findIndex(item => item.consultantid == consultantID)
  //   if (index < 0) {
  //     return
  //   }
  //   // Retrieve the chosen consultant
  //   this.selectedConsultant = this.listData[index]


  //   const headers = new HttpHeaders()
  //     .set('x-api-key', `${this.apiKey}`);
  //   // const url = `${this.apiUrl}/get-conversation?consultant_id=${consultantID}&customer_id=${this.customerID}`
  //   const url = `${this.apiUrl}/get-conversation`
  //   const payload = {
  //     'consultant_id': consultantID,
  //     'customer_id': this.customerID,
  //   }

  //   // Retrieve the paidPack if there is any
  //   // get-conversation endpoint
  //   this.http.post(url, payload, {
  //     headers
  //   })
  //     .subscribe(
  //       ((response: any) => {
  //         console.log('openConversation 2')
  //         // Check if there's any actif conversation
  //         if (response.hasOwnProperty('data') && response.data.hasOwnProperty('remaining_time') && parseInt(response.data.remaining_time) > 0) {
  //           console.log('openConversation 2')
  //           this.conversationPaymentSucceded(response.data)
  //         } else {
  //           console.log('openConversation 3')
  //           // Trigger payment process
  //           this.processPackPayment(consultantID)
  //         }
  //       }),
  //       (error: any) => {
  //         console.log('openConversation 4')
  //         // Trigger payment process
  //         this.processPackPayment(consultantID)
  //       }
  //     )
  // }

  // processPackPayment(consultantID) {
  //   console.log('processPackPayment')
  //   if (this.selectedConsultant.statutCode !== 0) {
  //     console.warn('Consultant unavailable')
  //     alert('Le consultant est absent!')
  //     return
  //   }

  //   this.activateLink = true;
  //   this.consultantservice.showSVAModalAction(this.activateLink);
  //   const paimentModal: any = $('#selectChatPackModal')
  //   paimentModal.modal('show');
  //   this.paymentModalIsVisible = true
  // }

  // /**
  //  * If the pack has just been paid and not enabled yet, process the payment to enable
  //  * this recent paid pack
  //  * otherwise open the chat modal
  //  * @param data any
  //  */
  // conversationPaymentSucceded = (data: any) => {
  //   // Hide payment modal if already open
  //   const paimentModal: any = $('#selectChatPackModal')
  //   paimentModal ? paimentModal.modal('hide') : null;
  //   this.paymentModalIsVisible = false
  //   console.log('conversationPaymentSucceded 1')

  //   // if data has the property `transaction_id` it means, the payment has just processed
  //   // Then we must activate the pack by creating or appending the conversation with the according remaining_time
  //   if (data.hasOwnProperty('enabled') && !data.enabled) {
  //     // Retrieve the paidPack if there is any
  //     const headers = new HttpHeaders()
  //       .set('x-api-key', `${this.apiKey}`);
  //     const url = `${this.apiUrl}/process-payment`
  //     const payload = {
  //       'payment_history_id': data.transaction_id,
  //       'authorization_id': data.authorization_id,
  //     }
  //     // process-payment endpoint
  //     this.http.post(url, payload, {
  //       headers
  //     })
  //       .subscribe(
  //         ((response: any) => {
  //           console.log('conversationPaymentSucceded 2')
  //           let paidPack: PaidPackSchema = {
  //             consultant_id: response.data.consultant_id,
  //             remaining_time: response.data.remaining_time,
  //             transaction_id: response.data.last_transaction_id,
  //             authorization_id: response.data.authorization_id,
  //             customer_id: this.customerID,
  //             consultation_mode: 'chat',
  //             enabled: true,
  //             conversation_started_at: response.data.conversation_started_at,
  //             watcher_value: null,
  //             service_duration: response.data.service_duration,
  //             total_amount: response.data.total_amount,
  //             conversation_status: response.data.conversation_status,
  //             payment_one_click_id: response.data.payment_one_click_id,
  //           }
  //           this.paidPack = paidPack

  //           // Open the conversation model when the payment is done
  //           this.chatModalIsVisible = true
  //         }),
  //         (error: any) => {
  //           console.log('conversationPaymentSucceded 3')
  //           console.warn('error', error)
  //         }
  //       )
  //   } else {
  //     console.log('conversationPaymentSucceded 4')
  //     let paidPack: PaidPackSchema = {
  //       consultant_id: data.consultant_id,
  //       remaining_time: data.remaining_time,
  //       transaction_id: data.last_transaction_id,
  //       authorization_id: data.authorization_id,
  //       customer_id: this.customerID,
  //       consultation_mode: 'chat',
  //       enabled: true,
  //       conversation_started_at: data.hasOwnProperty('conversation_started_at') ? data.conversation_started_at : null,
  //       watcher_value: data?.watcher_value,
  //       service_duration: data?.service_duration,
  //       total_amount: data?.total_amount,
  //       conversation_status: data.conversation_status,
  //       payment_one_click_id: data.payment_one_click_id,
  //     }
  //     this.paidPack = paidPack

  //     // Open the conversation model when the payment is done
  //     this.chatModalIsVisible = true
  //   }
  // }


  // /**
  //  *
  //  * @param amount Number, the amount to get from a given transaction
  //  * if null, it means get the full amount of the paidPack
  //  */
  // sendGetMoneyRequest = (paidPack: PaidPackSchema = null) => {
  //   // Only process if there's a pack that has been selected
  //   if (!paidPack) {
  //     return;
  //   }
  //   console.log('sendGetMoneyRequest 1')
  //   // Open payment modal
  //   this.finishPaymentModalIsVisible = true;
  // }

  // closeFinishPaymentModal = () => {
  //   this.finishPaymentModalIsVisible = false;
  // }

  // /**
  //  * @return void
  //  * close chat modal
  //  */
  // closeConversation = () => {
  //   this.chatModalIsVisible = false
  //   this.selectedConsultant = null
  //   this.paymentModalIsVisible = false
  // }

  /** --------- Consultants --------- */

  /**
   * Get List of consultants
   * @param serviceType
   * @param isplanned
   * @param setFilter
   */
  listConsultant(serviceType = 'Chat', isplanned = true, setFilter = false) {
    if (environment.merchantId && environment.officeId) {
      this.consultantservice.sendGetConsutalnt(environment.merchantId, environment.officeId, this.timeZoneClient).subscribe(
        (res: any) => {
          this.listData = res.body.data.filter(item => item.prestations.includes(serviceType));
          this.loading = false;
          this.listData.forEach(  consultant => {
            const result =  this.consultantservice.handle(consultant, this.setfilter, this.servicetype);
            consultant.statut = result.statut;
            consultant.statutColor = result.colorClass;
            consultant.prestationSorted = result.prestations;
            consultant.statutCode = result.statutCode;
            consultant.cost = result.cost;
            consultant.date = result.date;
            consultant.service = result.service;
            consultant.isPlannedForToday = result.isPlannedForToday;
            consultant.framesDetails = result.framesDetails;
            consultant.planningDays = result.planningDays;
            consultant.startHour = [];
            if (consultant.planningDays.length > 0) {
              result.planningDays.forEach(el => {
                el.startHour.forEach(elm => {
                  consultant.startHour.push(elm);
                });

              });
            }

          });

          this.listData.sort((a, b) => {
            if (b.statutCode === -1) {
              return -1;
            }
            if ((a.statutCode === 0 || a.statutCode === 1) && (b.statutCode === 0 || b.statutCode === 1)) {
              return (a.statutCode < b.statutCode || (b.service !== 'Conversation' && a.statutCode === 0)) ? -1 : 1;
            }
            if (b.statutCode === 2) {
              return dayjs(a.date) < dayjs(b.date) ? -1 : 1;
            }
            return 1;
          });

          if (setFilter && serviceType === 'Conversation') {

            this.listData = this.listData.filter(data => (data.service === serviceType && data.isPlannedForToday === isplanned));
            this.listDataOriginal = this.listData;
            this.listData = this.filterService(this.listData);
          } else if (setFilter && serviceType === 'Audiotel') {
            this.listDataCloned = this.listData;
            const currentHour = dayjs().format('HH') + 'h';
            this.listData.forEach(elementData => {
              elementData.planningDays.forEach(el => {
                if (el.day === dayjs().format('YYYY-MM-DD')) {
                  elementData.startHour = el.startHour;
                  elementData.service = 'audiotel';
                }
              });
            });
            this.listData = this.listData.filter(data => (
              data.service === serviceType.toLowerCase() && data.isPlannedForToday === isplanned && dayjs(data.date).format('HH') === dayjs().format('HH')
              && data.statutCode === 0 && data.startHour.includes(currentHour)));
            this.listDataOriginal = this.sortByTouchNumber(this.listData, currentHour, dayjs());
          } else if (serviceType === 'all') {
            this.listDataOriginal = this.listData;
            this.listData = this.filterService(this.listData);

          } else if (serviceType === 'Chat') {
            this.listDataOriginal = this.listData;
            this.listData = this.filterService(this.listData);

          } else {
            this.listData = this.listData.filter(data => (data.service === serviceType));
            this.listDataOriginal = this.listData;
            this.listData = this.filterService(this.listData);

          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  filterService(data) {
    data.forEach(el => {
      el.prestationSorted = el.prestationSorted.filter(item => item.name !== 'Audiotel');
    });
    return data;
  }

  /**
   *
   * @param data
   * @param selectedHour
   */
  sortByTouchNumber(data, selectedHour, seletedDay) {
    data.forEach(el => {
      el.framesDetails.forEach(frame => {
        // console.log('frame.startHourFrame.substring(0, 2)' , frame.startHourFrame.substring(0, 2) + 'h' === selectedHour);
        if ((frame.startHourFrame.substring(0, 2) + 'h') === selectedHour && dayjs(frame.day).format('YYYY-MM-DD') === dayjs(seletedDay).format('YYYY-MM-DD')) {
          el.touchNumber = frame.toucheFrame;
        }
      });

    });
    return data.sort((a, b) => a.touchNumber - b.touchNumber);
  }

   /**
   *
   * @param consultant
   * @constructor
   */
   ModalConsultantReviews(consultant) {


    this.noticeService.showAllNotice.subscribe(res => {
      this.showAllNotice = res;
    });
    this.noticeService.showAddNotice.subscribe(res => {
      this.showAddNotice = res;
    });

    this.noticeService.showAddNotice.next(false);
    this.noticeService.showAllNotice.next(true);


    this.consultantInfo = consultant;
    this.consultantNotice = [];
    this.consultantReviews = [];
    this.transactions = [];
  //  this.consultantId = this.consultantInfo.consultantid;
    this.getConsultantNotice(this.consultantInfo.consultantid);
    this.getConsultantReviews(this.consultantInfo.consultantid);
    if (this.isLoggedIn$) {
      this.getCustomerTransactions(this.consultantInfo.consultantid);

    }
    this.consultantImageUrl = this.consultantInfo.consultantpictureurl;
    this.consultantName = this.consultantInfo.consultantlogin;

  }
   /**
   *
   * @param consultantId
   */
   getConsultantNotice(consultantId) {

    this.noticeService.consultantNotice(environment.merchantId, consultantId).subscribe(
      (result: any) => {

        if (result.statusCode === undefined) {

          this.consultantNotice = result.body;

        } else {
          $('#modalConsultantReviewsError').modal('show');

        }

      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }

  /**
   *
   * @param consultantId
   */
  getConsultantReviews(consultantId) {

    this.noticeService.consultantReviews(environment.merchantId, consultantId).subscribe(
      (result: any) => {
        this.consultantReviews = result.body;
        if (result.statusCode === undefined) {
          $('#modalConsultantReviews').modal('show');

        } else {
          $('#modalConsultantReviewsError').modal('show');

        }

      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }

  /**
   *
   * @param consultantId
   */
  getCustomerTransactions(consultantId) {
    const merchantId = environment.merchantId;
    this.noticeService.customerTransactions(
      merchantId,
      localStorage.getItem('customerId'),
      localStorage.getItem('customerSessionId'),
      consultantId
    ).subscribe(
      (result: any) => {
        if (result.body[0]) {
          this.transactions = result.body[0];
          this.transactionStatus.next(true);
        } else {
          this.transactionStatus.next(false);

        }
      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }
  redirectToConsultant(consultantId) {
    // const  redirectTo = '/consultant-details';
    localStorage.setItem('ctcx', this.cryptoService.set(consultantId));
    localStorage.setItem('serviceType', this.cryptoService.set('Chat'));
    this.homeService.redirect('/consultant-details');
  }

}



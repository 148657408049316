const JSON2 = {

  parse: (text: string): any => {
    try {
      return JSON.parse(text);
    } catch (err) {
      return text;
    }
  }

};

export default JSON2;

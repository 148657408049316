import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DefinitionService} from '../../_services/definition.service';
import {CryptoService} from '../../_services/crypto.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import { Account } from '../../_models/account';
import { AppState } from '../../app.state';
import {AccountService} from '../../_services/account.service';
import * as phoneUtil from 'google-libphonenumber';
import {Definitions} from '../../_models/definitions';
import {HomeService} from "../../_services/home.service";
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})


export class ProfileComponent implements OnInit {
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  phoneUtil: any;
  formatedPhoneNumber = {
    principalCallNumber: '',
    secondaryCallNumber: '',
    principalCallNumberCountry: '',
    secondaryCallNumberCountry: ''
  };
  customerBirthDay: any;

  constructor(
    private fb: FormBuilder,
    private definitionService: DefinitionService,
    private router: Router,
    private crypto: CryptoService,
    private store: Store<AppState>,
    private accountService: AccountService,
    public homeService: HomeService
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.phoneUtil = phoneUtil.PhoneNumberUtil.getInstance();
  }

  ngOnInit(): void {
  //  this.definitionService.fetchDefinitions();
    this.account.subscribe((account) => {
      if (account?.principalCallNumber) {
        const principalCallNumber = this.phoneUtil.parseAndKeepRawInput(account.principalCallNumber, 'FR');
        this.formatedPhoneNumber.principalCallNumberCountry = 'flag-icon-' + this.phoneUtil.getRegionCodeForNumber(principalCallNumber).toLowerCase();
        this.formatedPhoneNumber.principalCallNumber = '+' + principalCallNumber.getCountryCode() + ' (0) ' + principalCallNumber.getNationalNumber();
      }
      if (account?.secondaryCallNumber) {
        const secondaryCallNumber = this.phoneUtil.parseAndKeepRawInput(account.secondaryCallNumber, 'FR');
        this.formatedPhoneNumber.secondaryCallNumberCountry = 'flag-icon-' + this.phoneUtil.getRegionCodeForNumber(secondaryCallNumber).toLowerCase();
        this.formatedPhoneNumber.secondaryCallNumber = '+' + secondaryCallNumber.getCountryCode() + ' (0) ' + secondaryCallNumber.getNationalNumber();
      }

    });
  }

  updateUserInfo(val: string, attr: string) {
    this.accountService.update(attr, val);
  }
}

<div class="container">
    <h2 class="text-center mb-3 form-contact-us"> CONTACTER NOUS </h2>
    <p class="text-center contact-title"> <b>Vous avez des questions ?</b> n'hésitez pas à contacter notre service par e-mail</p>
    <div class="row">
      <div class="col-md-6 offset-md-3">
          <form [formGroup]="formContact" (submit)="onSubmitContact()" id="formContact">

                <div class="form-group">
                    <label for="Email">Votre email : </label>
                    <input
                    class="input-contact"
                    [class.is-invalid]="(f.customerEmail.errors && !f.customerEmail.errors.required) "
                    [ngClass]="{ 'is-valid': f.customerEmail.valid}"
                    class="form-control "
                    formControlName="customerEmail"
                    id="inputEmail"
                    placeholder="example@example.com"
                    type="email"
                    >
                    <div *ngIf="f.customerEmail.errors" class="row" id="invalidEmail-mobiyo">
                        <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern" class="col-sm-10">
                          <p class=" notice-error-message">
                            Votre email est incorrect
                          </p>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="fullName">Votre nom complet :</label>
                    <input
                    class="input-contact"
                    [class.is-invalid]="(f.fullName.errors && !f.fullName.errors.required) "
                    [ngClass]="{ 'is-valid': f.fullName.valid}"
                    class="form-control "
                    formControlName="fullName"
                    id="inputEmail"
                    placeholder="Nom complet"
                    type="email"
                    >
                    <div *ngIf="f.fullName.errors" class="row" id="invalidEmail-mobiyo">
                        <div *ngIf="f.fullName.errors.email || f.fullName.errors.pattern" class="col-sm-10">
                          <p class=" notice-error-message">
                            Ce champs est obligatoire
                          </p>
                        </div>
                    </div>
                </div>
                
               
             <div class="form-group">
                <label for="comment">Votre Message : </label>
                <textarea 
                [class.is-invalid]="(f.comment.errors && !f.comment.errors.required) "
                    [ngClass]="{ 'is-valid': f.comment.valid}"
                class="form-control input-contact" formControlName="comment" name="comment">
                </textarea>
                <div *ngIf="f.comment.errors" class="row" id="invalidEmail-mobiyo">
                    <div *ngIf="f.comment.errors.email || f.comment.errors.pattern" class="col-sm-10">
                      <p class=" notice-error-message">
                        ce champs est obligatoire
                      </p>
                    </div>
                </div>
            </div>
             <div class="row">
                <div class=" col-sm-3 offset-sm-5 offset-sm-5-mobiyo">
                  <button [disabled]="formContact.invalid" class="btn btn-sm btn-success text-center btn-mobiyo btn-contact-form" type="submit">
            
                    Envoyer
                  </button>
                </div>
              </div>
         </form>
        </div>
    </div>
</div>
  
  
  

<!--   Login Modal -->
<div class="modal" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header modal-login-header">
        <h5 class="modal-title login-modal-title">S’identifier</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="this.homeService.resetForm()">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
        <app-login></app-login>
      </div>
    </div>
  </div>
</div>

<!--  Email verification modal  -->

<div class="modal fade" id="verificationModal" tabindex="-1" role="dialog" aria-labelledby="verificationModal"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="verificationModalLongTitle">veuillez vérifier votre boîte de réception</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="redirectToHome()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Nous sommes heureux que vous vous soyez inscrit à allomegane. Pour commencer votre prochaine consultation,
        veuillez confirmer votre adresse e-mail.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="redirectToHome()"> Confirmer</button>
      </div>
    </div>
  </div>
</div>

<!--   Reset Password Modal -->
<div class="modal fade" id="resetPasswordModal" tabindex="-1" role="dialog" aria-labelledby="resetPasswordLabel"
  aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">RÉINITIALISER VOTRE MOT DE PASSE</h5>
        <button type="button" id="closeModalPasswordResetReqest" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-password-reset-request></app-password-reset-request>
      </div>

    </div>
  </div>
</div>

<!--   Btn Reset Password Modal Error  -->
<button style="display: none" id="openModalErrorResetPassword" [hidden]="true" type="button" class="btn btn-primary"
  data-toggle="modal" data-target="#ModalErrorResetPassword">
</button>

<!--    Reset Password Modal Error  -->
<div class="modal fade" id="ModalErrorResetPassword" tabindex="-1" role="dialog"
  aria-labelledby="ModalErrorResetPasswordLabel" aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalErrorResetPasswordLabel">Réinitialisation de votre mot de passe </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p id="openModalErrorResetPasswordMessage">
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer le mail pour réinitialiser
              votre mot de passe
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions |
          async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>

<button style="display: none" id="openModalSuccessResetPassword" [hidden]="true" type="button" class="btn btn-primary"
  data-toggle="modal" data-target="#modalSuccessResetPassword">
</button>

<!--    Reset Password Modal Error  -->
<div class="modal fade" id="modalSuccessResetPassword" tabindex="-1" role="dialog"
  aria-labelledby="modalSuccessResetPasswordLabel" aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalSuccessResetPasswordLabel">RÉINITIALISER VOTRE MOT DE PASSE</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p>
              Le mail pour ré-initialiser votre mot de passe a été envoyé, si vous ne le trouvez pas, n'hésitez pas à
              vérifier qu'il n'est pas rangé dans vos spams.
            </p>
            <p>

            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success d-block" data-dismiss="modal">Continuer</button>
      </div>
    </div>
  </div>
</div>

<!-- Register Modal -->
<div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalLabel"
  aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="titleCreateAccount">Créer son compte {{(definitions | async)?.sms_brand}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="this.homeService.resetFormRegister()">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body" id="bodyModalRegistration">
        <app-register></app-register>
      </div>

      <div class="modal-body p-4" id="bodyModalValidationEmail" style="display: none">
        <p>
          FÉLICITATIONS ! Votre compte est créé mais vous devez l'activer pour utiliser {{(definitions |
          async)?.sms_brand}} !
        </p>
        <p> 1- Vous allez recevoir un mail sur {{customerEmail}}, expédié par {{(definitions | async)?.provider_mail}}
        </p>
        <p class="ml-4"> ATTENTION : Vérifiez qu'il ne soit pas rangé dans vos spams !</p>
        <p> 2- Consultez votre mail, puis cliquez sur le lien pour activer votre compte</p>

        <div class="row justify-content-md-center mt-4 mb-4">
          <button type="button" class="btn btn-success" data-dismiss="modal">J'ai compris</button>
        </div>
        <div class="row justify-content-md-center" id="sendEmailCountDown">
          <p class="ol-sm-12" id="countDown"></p>
        </div>
        <div class="row justify-content-md-center" id="requestMail">
          <label class="col-md-3 col-sm-6">Je n'ai pas reçu de mail ? </label>
          <a class="btn-link col-md-3 nav-link-mobiyo" (click)="sendMail()">En recevoir un nouveau ici </a>
        </div>
        <div class="row justify-content-md-center" id="ResetMail">
          <label class="col-md-3 col-sm-6">Mon mail est erroné ? </label>
          <a class="btn-link col-md-3 nav-link-mobiyo" (click)="resetEmail()">Le modifier ici </a>
        </div>
      </div>
      <div class="modal-body p-4" id="bodyModalResetEmail" style="display: none">
        <app-email-reset></app-email-reset>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ModalErrorRegister" tabindex="-1" role="dialog" aria-labelledby="ModalErrorRegisterLabel"
  aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalErrorRegisterLabel"> ACTIVER MON COMPTE {{(definitions | async)?.sms_brand |
          uppercase}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer un mail pour activer votre
              compte
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions |
          async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>
      </div>
    </div>
  </div>
</div>

<!--    Mobile phone btn Error  -->
<button style="display: none" id="openModalErrorEmailValidation" [hidden]="true" type="button" class="btn btn-primary"
  data-toggle="modal" data-target="#modalErrorEmailValidation">
</button>
<!--    Mobile phone Modal Error  -->
<div class="modal fade" id="modalErrorEmailValidation" tabindex="-1" role="dialog"
  aria-labelledby="modalErrorEmailValidationLabel" aria-hidden="false">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalErrorEmailValidationLabel">ACTIVER MON COMPTE {{(definitions |
          async)?.sms_brand}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer un mail pour activer votre
              compte.
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions |
          async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>
      </div>
    </div>
  </div>
</div>


<!-- Password Reset Module  -->

<!-- Btn  Login Modal -->
<a id="openModalInvalideToken" [hidden]="true" data-toggle="modal" data-target="#invalideToken">
</a>
<div class="modal fade" id="invalideToken" tabindex="-1" role="dialog" aria-labelledby="invalideTokenLabel"
  aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalEmailValidationErrorInvalide">Clic sur lien WEB</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Désolé, mais ce lien est périmé ou invalide !</p>
            <p id="clickWebLinkInvalideTokenMessage">Cliquez sur le lien "Réinitialiser le mot de passe" pour recevoir
              un nouveau lien.</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">J'ai compris!</a>
      </div>
    </div>
  </div>
</div>
<a id="openModalErrorAccountApi" [hidden]="true" data-toggle="modal" data-target="#errorAccountApi">
</a>
<div class="modal fade" id="errorAccountApi" tabindex="-1" role="dialog" aria-labelledby="errorAccountApiLabel"
  aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Clic sur lien WEB</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p id="clickWebLinkApiErrorMessage"></p>
            <p></p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions |
          async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>
<a id="openModalClickWebLinkSuccess" [hidden]="true" data-toggle="modal" data-target="#clickWebLinkSuccess">
</a>
<!--    Register Modal Success  -->
<div class="modal fade" id="clickWebLinkSuccess" tabindex="-1" role="dialog" aria-labelledby="clickWebLinkSuccessLabel"
  aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="clickWebLinkSuccessTitle">Clic sur lien WEB</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-check-circle text-success"></i>
          </div>
          <div class="col-md-9" style="margin: auto; padding: 10px;">
            <p id="clickWebLinkSuccessMessage"></p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<a id="openModalPasswordReset" [hidden]="true" data-toggle="modal" data-target="#passwordResetModal">
</a>
<!--   Login Modal -->

<div class="modal fade" id="passwordResetModal" tabindex="-1" role="dialog" aria-labelledby="passwordResetModalLabel"
  aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="font-weight-normal">Modifier votre Mot de Passe</h5>
        <button type="button" id="closeModalPasswordReset" class="close" data-dismiss="modal" aria-label="Close"
          (click)="this.homeService.resetForm()">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
        <app-password-reset *ngIf="definitions | async" [infoDefinitions]="definitions | async"></app-password-reset>
      </div>

    </div>
  </div>
</div>
<app-notification [definitions]="(definitions | async)" [customerId]="(account | async)?.customerId"
  [customerEmail]="(account | async)?.customerEmail" [customerPhone]="(account | async)?.principalCallNumber"
  [customerPhoneValidate]="(account | async)?.isPrincipalCallNumberValidate"
  [customerSecondPhone]="(account | async)?.secondaryCallNumber"></app-notification>
<app-promotion></app-promotion>
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_models/user';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Account} from '../_models/account';
import {AccountService} from './account.service';
import {SessionService} from './session.service';
import {HomeService} from './home.service';
import {CookieService} from 'ngx-cookie-service';
import {PromotionService} from './promotion.service';
import {CryptoService} from './crypto.service';
import * as dayjs from 'dayjs';
import {Definitions} from '../_models/definitions';
declare var $: any;


@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private apiUrl = environment.apiUrlAccountCustomer;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private addPhone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private validatePhone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private PhonePrimary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('customerId')));
  public customerNameSubject = new BehaviorSubject(null);
  customerName = this.customerNameSubject.asObservable();
  sharedUser = this.currentUserSubject.asObservable();
  account: Observable<Account>;
  public definitions: Observable<Definitions>;
  private daysBeforePromo: string;
  private  apiKey = environment.apiKey;


  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>,
    private accountService: AccountService,
    private sessionService: SessionService,
    public  homeService: HomeService,
    private cookieService: CookieService,
    private promotionService: PromotionService,
    private cryptoService: CryptoService

  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }


  login(user: User, fbLogin: Boolean = false) {

    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);

    // Login with facebook is exempted from password typing
    if (!user.customerPassword && !fbLogin) {
      throw new Error("Le mot de passe ne doit pas être vide.");
    }

    if (user.customerEmail !== '') {
      user.merchantId = environment.merchantId;
      user.cabinetId = environment.officeId;
      let url = `${this.apiUrl}/account-create-session`
      url = fbLogin ? `${url}-with-facebook` : url
      return this.http.post(url, user , { headers: header});
    }
  }

  logout() {
    this.sessionService.logout();
    this.accountService.destroy();
    localStorage.removeItem('customerSessionId');
    localStorage.removeItem('customerId');
    localStorage.removeItem('customerCallNumber');
    localStorage.removeItem('customerEmail');
    this.handleCookies();
    this.loggedIn.next(false);
    this.addPhone.next(false);
    this.validatePhone.next(false);
    this.PhonePrimary.next(false);
    this.homeService.redirect('/');

    // Logout from socialAuth provider as well
  }

  checkSession(customerSessionId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return  this.http.post(`${this.apiUrl}/account-check-session`, {customerSessionId} , { headers: header} );
  }
  getUserInfo(customerSessionId, customerId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new  HttpParams({
      fromObject : {
        customerId,
        customerSessionId
      }
    });
    this.http.get(`${this.apiUrl}/get-account`, {params: params , headers: header}).subscribe(
      (result: any) => {
        delete result.headers;
        this.accountService.init(result);
        localStorage.setItem('customerCallNumber', result.principalCallNumber);
        localStorage.setItem('customerEmail', result.customerEmail);
        this.customerNameSubject.next(result.customerFirstName);
        this.currentUserSubject.next(result.customerId);
        $('#loginModal').modal('hide');
        $('.modal-backdrop').remove();
        this.promotionService.fetchPromotions('Connexion');
        this.checkBirthday();
      },
      error => {
        console.log(error);
      }
    );
  }

  getcurrentUser(customerSessionId, customerId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new  HttpParams({
      fromObject : {
        customerId,
        customerSessionId
      }
    });
    return this.http.get(`${this.apiUrl}/get-account`, {params: params , headers: header});
  }
  handleCookies() {
    const allCookies: {} = this.cookieService.getAll();
    for (const [key, value] of Object.entries(allCookies)) {
      if (![this.cryptoService.set('Acces')].includes(key)) {
        this.cookieService.delete(key);
      }
    }
  }

  checkBirthday() {
    const sessionID = localStorage.getItem('customerSessionId');
    this.definitions.subscribe(definitions => {
      this.daysBeforePromo = definitions?.days_before_promo_diplay;
    });
    this.account.subscribe(
      (res: any) => {
        if (res && res?.customerBirthday) {
          const birthday = dayjs(res?.customerBirthday);
          const timeFromNow = dayjs().add(Number(this.daysBeforePromo), 'day');
          if (sessionID && timeFromNow.month() === birthday.month() && timeFromNow.daysInMonth() === birthday.daysInMonth()) {
            this.promotionService.fetchPromotions('Anniversaire');
          }
        }
      }
    );
  }

}

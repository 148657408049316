import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {Definitions} from '../_models/definitions';
import * as DefinitionsActions from '../actions/definitions.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import JSON2 from '../_helpers/JSON2';
import {DomainService} from './domain.service';
import {CryptoService} from './crypto.service';


@Injectable({
  providedIn: 'root'
})

export class DefinitionService {
  private apiUrl = environment.apiUrlDefinition;
  private apiUrlDomaine = environment.apiUrlDomain;
  private  apiKey = environment.apiKey;

  private fields: string[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private domainService: DomainService,
    private cryptoService: CryptoService,
    private store: Store<AppState>
  ) {
    this.fields = [
      'customer_address_activated',
      'customer_birth_country_activated',
      'customer_birth_date_activated',
      'customer_birth_place_activated',
      'customer_birth_time_activated',
      'customer_gender_activated',
      'customer_secondary_phone_activated',
      'customer_countries',
      'provider_call_number',
      'provider_mail',
      'sms_brand',
      'tel_validate',
      'sva_number',
      'sva_number_tariff',
      'regex_password',
      'regex_password_text',
      'type_buy_promo_diff',
      'days_before_promo_diplay',
      'duration_before_extand_call',
      'recipient_email_for_alerts',
      'fixed_call_number',
      'using_sendethic',
      'sendethic_api_key'
    ];
  }
  getMerchantParams(domainName) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        domainName: domainName
      }
    });
    return this.http.get(`${this.apiUrlDomaine}/get-merchant`, {params: params , headers: header});
  }
  getDefinitions(merchantId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        merchantId: String(merchantId),
        variables: this.fields.join(',')
      }
    });
    return this.http.get(`${this.apiUrl}/get-merchant-definition`, {params: params , headers: header});
  }
  init(definitions: Definitions): void {
    definitions = this.preInit(definitions);
    this.store.dispatch(new DefinitionsActions.SetDefinitions(definitions));
    this.postInit(definitions);
  }

  private preInit(definitions: Definitions): Definitions {
    for (const key in definitions) {
      let value = definitions[key];
      value = ((value.merchant) && (value.merchant)  !== 'null') ? value.merchant : value.provider;
      definitions[key] = JSON2.parse(value);
    }
    return definitions;
  }

  private postInit(definitions: Definitions): void {
    // ...
  }

  destroy(): void {
    this.store.dispatch(new DefinitionsActions.DeleteDefinitions());
  }
  fetchDefinitions() {

    let finalArr: Definitions;
    this.domainService.getMerchantParams().subscribe(
      (result: any) => {
        localStorage.setItem('m', this.cryptoService.set(result.merchant_id));
        localStorage.setItem('o', this.cryptoService.set(result.office_id) );

        this.getDefinitions(result.merchant_id).subscribe(
          (res: any) => {
            delete res.headers;
            finalArr = res;
            // @ts-ignore
            finalArr.officeId = {
              merchant: result.office_id
            } ;
            // @ts-ignore
            finalArr.merchantId = {
              merchant: result.merchant_id
            } ;
            this.init(finalArr);
          });
      });
  }
}

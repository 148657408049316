<br>
<form (submit)="onSubmitResetPassword()" [formGroup]="formResetPassword" class="#">

  <div class="row">
    <div class="col-sm-12">
      Veuillez enregistrer votre nouveau mot de passe.
    </div>
  </div>

  <br>
  <br>

  <div class="row">
    <div class="col-sm-5">
      <label class="text-left"> Votre nouveau mot de passe </label>
    </div>
    <div class="col-sm-5">
      <input
        [class.is-invalid]="f.customerPassword.errors?.pattern"
        [ngClass]="{ 'is-valid': f.customerPassword.valid }" class="form-control"
        formControlName="customerPassword"
        placeholder=""
        type="password"
      >
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-5">
      <label class="text-left"> Vérification du mot de passe </label>
    </div>

    <div class="col-sm-5">
      <input

        [class.is-invalid]=" f.confirmPassword.hasError('mustMatch')"
        [ngClass]="{ 'is-valid': f.confirmPassword.valid }" class="form-control"
        formControlName="confirmPassword"
        placeholder=""
        type="password"
      >


    </div>

  </div>
  <br>


  <div class="row">
    <div class=" col-sm-3 offset-sm-5">
      <button [disabled]="formResetPassword.invalid" class="btn btn-sm btn-success text-center btn-mobiyo" type="submit">
        Valider
        <span class="spinner-border spinner-border-sm align-self-center" role="status" aria-hidden="true" [hidden]="!submitting"></span>
      </button>
    </div>
  </div>
  <br>


  <!-- Password not Valid -->
  <div *ngIf="f.customerPassword.errors?.pattern" class="row" id="invalidPassword-mobiyo">
    <div *ngIf="f.customerPassword.errors?.pattern &&  !f.customerPassword.errors.required" class="col-sm-1">
      <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
    </div>
    <div *ngIf="f.customerPassword.errors.pattern" class="col-sm-10">
      <p class="col-sm-5" style="max-width:100%">
        Votre mot de passe doit être constitué de 8 caractères avec au moins une lettre et un chiffre</p>
    </div>
  </div>
  <!-- Password not matching -->
  <div *ngIf="f.confirmPassword.hasError('mustMatch') " class="row" id="passwordNotmatching-mobiyo">
    <div class="col-sm-1">
      <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>

    </div>
    <div class="col-sm-10">
      <p class="col-sm-5" style="max-width:100%">Votre deuxième mot de passe est différent du premier </p>
    </div>
  </div>


</form>
<br>



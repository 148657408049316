<div class="container mt-3 my-5">

  <div class="row align-items-center">
    <div class="col">
      <a (click)="this.homeService.redirect('/')" class="btn btn-success-mobiyo btn-sm mr-5 btn-back-mobiyo">< Revenir à la liste des Consultants</a>
      <span class="col" *ngIf="!!(definitions | async)">Le secrétariat d'{{ (definitions | async)?.sms_brand }} est disponible au {{ (definitions | async)?.provider_call_number }}</span>
    </div>
  </div>

  <div class="row mt-5" *ngIf="!consultantInfo">
    <div class="col text-center">
      <div *ngIf="!consultantInfo" class="spinner-border" role="status" aria-hidden="true">
        <span class="sr-only">Chargement...</span>
      </div>
    </div>
  </div>

  <div *ngIf="!!consultantInfo">
    <div class="row mt-2">
      <div class="col-sm-3">
        <h3>{{ consultantInfo?.consultantlogin }}</h3>
      </div>
    </div>
     <div class="row mr-3 mb-4 row-cols-sm-3 row-cols-sm-3-mobiyo">
        <span *ngFor="let lang of consultantInfo?.language || []" >
          <span class="col" *ngIf="lang !== 'FR'">
            <i
              class="fa-{{lang.toLocaleLowerCase()}} flag-consultant-details"
              aria-hidden="true"
            ></i>&nbsp;
            <span class=" lang-consultant-profile">{{ this.findLanguage(lang)?.text }}</span>
          </span>
        </span>
     </div>
    <div class="row">
      <div class="col-sm-3">
        <img class="img-fluid img-thumbnail" src="{{consultantInfo?.consultantpictureurl || 'assets/images/male.png'}}" alt="">
      </div>
      <div class="col-sm">
        <div class="row">
          <div class="col">
            <p class="card-text">
              {{ consultantInfo?.consultantdescription }}
            </p>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <div class="col">
            <div class="row align-items-center">
              <div class="col text-center text-md-right" style="font-size: 2rem;">
                <ngb-rating  [starTemplate]="t" [readonly]="true" [max]="5" class="text-warning" [rate]="consultantInfo.reviewnote || 0"></ngb-rating>
              </div>
              <div class="col text-center text-md-left mt-1" style="font-size: 1.1rem;">
                {{ consultantInfo?.nbconsultation || 0 }} Consultations
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col text-center">
                <a [ngClass]="{'disabled': !(consultantInfo?.reviewnote > 0)}" [routerLink]="" queryParamsHandling="preserve" (click)="ModalConsultantReviews()">
                  Consulter les avis sur {{ consultantInfo?.consultantlogin }} ?
                </a>
              </div>
            </div>
          </div>
          <div class="col">
            <p class="text-center">Ecoutez {{ consultantInfo?.consultantlogin }} se présenter :</p>
            <audio controls="" src="{{consultantInfo?.consultantsoundurl}}" target="" style="width: 100%"></audio>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <span *ngIf="consultantInfo?.voyanceTypes.length>0">Compétences : </span>
            <span *ngFor="let type of consultantInfo?.voyanceTypes || [] let i='index'">
              {{voyanceTypes[type]}}<span *ngIf="i+1!=consultantInfo?.voyanceTypes.length">,</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col text-white text-uppercase text-center">
          <a
            class="nav-link-mobiyo btn-available"
            style="color: white;"
            (click)="getClickedConsultantId(consultantInfo?.consultantid, consultantInfo?.consultantlogin ,status,  serviceType)"
            [ngClass]="{'disabled': statusCode !== 0 }"
          >
            <p class="p-3 m-0 rounded" [ngClass]="statusColor">

            {{ status }}
            </p>

          </a>
      </div>
    </div>

    <div class="row mt-2 align-items-start">
      <div
        class="col-12 col-sm pt-0 mt-1"
        *ngFor="let benefit of benefits"
        [ngClass]="{
          'btn': benefit.status === 'available'
        }"
        (click)="getClickedConsultantId(consultantInfo?.consultantid, consultantInfo?.consultantlogin, benefit.status, benefit.type)"
      >
        <div
          class="rounded px-3"
          [ngClass]="['service-' + benefit.status]"
          placement="bottom"
          [ngbTooltip]="benefit.tooltipContent"
          [tooltipClass]="'tooltip-' + benefit.status + ' tlp-bottom'"
          style="min-height: 75px;"
          (click)="showSVAModal()"
        >
          <div class="row h-50 pt-1" >
            <div class="col">
              <div *ngIf="benefit.type === 'audiotel'" class="mt-2 text-left">
                <app-sva
                  *ngIf="!!(definitions | async)"
                  [definitions]="definitions | async"
                  [zoom]="45"
                  [isLinkActive]="activateLink"
                ></app-sva>
              </div>
              <div *ngIf="benefit.type !== 'audiotel'" class="d-flex align-items-center">
                <span
                  class="icon-service-type h-auto"
                  style="width: 36px;"
                  [ngClass]="[benefit.className]"
                >
                </span>
                <span *ngIf="benefit.cost" class="ml-2" style="font-size: 0.8rem;">
                  {{ benefit.cost }}{{ convertCurrency(consultantInfo.currency) }}
                  <span *ngIf="benefit.type !== 'mail'">/ min</span>
                  <span *ngIf="benefit.type === 'mail'">/ Mail</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-2 text-left">
            <div class="col" style="font-size: 0.9rem;">
              {{ benefit.text }}
            </div>
          </div>
        </div>
        <p *ngIf="benefit.type === 'mail'" class="text-center">Réponse dans les 48 heures</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2>Le Planning de {{ consultantInfo.consultantlogin }}
        </h2>
      </div>
    </div>


    <!-- Planning consultant -->

    <div class="row mt-1 mb-2">
      <section class="col slick">

      <app-consultant-planning
        [consultantId]="consultantInfo.consultantid"
      ></app-consultant-planning>

      </section>
    </div>


    </div>

    <!-- Modal consultant reviews -->
    <div class="modal fade" id="modalConsultantReviews" tabindex="-1" role="dialog" aria-labelledby="ModalessageLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-lg" role="document">
        <ng-template #t let-fill="fill">
          <span class="star" [class.full]="fill === 100">
            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
          </span>
        </ng-template>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{consultantInfo?.consultantlogin}}&nbsp;
              <ngb-rating  [starTemplate]="t" [readonly]="true" [max]="5" *ngIf="consultantInfo?.reviewnote>0" class="text-warning" [rate]="consultantInfo?.reviewnote"></ngb-rating>&nbsp;
              <span *ngIf="consultantInfo?.reviewcount>0">({{consultantInfo?.reviewcount}} avis)</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div *ngIf="showAllNotice">
          <app-show-reviews
            *ngIf="consultantInfo"
            id="allNotices"
            [consultantId]="consultantInfo.consultantid"
            [consultantImageUrl]="consultantInfo.consultantpictureurl"
          >
          </app-show-reviews>
          </div>
          <div *ngIf="showAddNotice" class="p-4">
            <app-add-review
              *ngIf="consultantInfo"
              id="addNotice"
              [consultantId]="consultantInfo.consultantid"
              [consultantName]="consultantInfo.consultantlogin"
              [closeModal] = false
              [redirect]= false
              [numberOfCall] = 1
            ></app-add-review>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal modalConsultantReviewsError  -->
    <div class="modal fade" id="modalConsultantReviewsError" tabindex="-1" role="dialog" aria-labelledby="modalConsultantReviewsErrorLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalConsultantReviewsErrorLabel">Les avis</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-2">
                <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-md-9">
                <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de visualiser les avis.</p>
                <p>Merci d'essayer de nouveau plus tard !</p>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <a  class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal modalConsultantAddReviewsError  -->
    <div class="modal fade" id="modalConsultantAddReviewsError" tabindex="-1" role="dialog" aria-labelledby="modalConsultantAddReviewsErrorLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalConsultantAddReviewsErrorLabel">Donner son avis</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-2">
                <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-md-9">
                <p>Une erreur vient de survenir et Allo Mégane n'est pas en mesure de prendre en compte votre avis !</p>
                <p>Merci d'essayer de nouveau plus tard !</p>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <a (click)="homeService.redirect('/')" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="converationSelect" tabindex="-1" role="dialog" aria-labelledby="converationSelectLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Sélection du tarif</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <app-select-private-conversation
            *ngIf="consultantInfo"
            [consultantId]="consultantInfo.consultantid"
            [consultantName]="consultantInfo.consultantlogin"
            [price]="consultantInfo.conversationcost"
            [isExtendCall]= false
          ></app-select-private-conversation>
        </div>
      </div>
    </div>
    <!-- Modal modalShowAutioTel  -->
    <div class="modal fade" id="modalShowAutioTel" tabindex="-1" role="dialog" aria-labelledby="modalShowAutioTelLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalShowAutioTelLabel">Conversations par Téléphone</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <p>Vous pouvez appeler votre consultant sur le {{(definitions | async)?.sva_number}}</p>
                <div class="text-center">
                  <app-sva
                    *ngIf="!!(definitions | async)"
                    [definitions]="definitions | async"
                    [zoom]="75"
                  ></app-sva>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <a class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
          </div>
        </div>
      </div>
    </div>


  </div>


  <!-- Modal Conversation Error -->
  <div class="modal fade" id="converationError" tabindex="-1" role="dialog" aria-labelledby="converationErrorLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sélection du tarif</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
            </div>
            <div class="col-md-8">
              <p>Une erreur vient de survenir et nous ne sommes pas en mesure de sélectionner votre tarif</p>
              <p>Merci d'essayer de nouveau plus tard!</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal Error -->

  <app-chat-modal *ngIf="chatModalIsVisible" [consultant]="consultantInfo" [paidPack]="paidPack"
      [customerID]="customerId" (processGetMoney)="sendGetMoneyRequest($event)"
      (closeConversationModal)="closeConversation()"
      (extendConversation)="openConversation(consultantInfo,true)"
      >
    </app-chat-modal>

  <app-finish-payment *ngIf="finishPaymentModalIsVisible" (close)="closeFinishPaymentModal()" [paidPack]="paidPack" [consultant]="consultantInfo">
  </app-finish-payment>

  <div class="modal fade" id="selectChatPackModal" tabindex="-1" role="dialog" aria-labelledby="converationSelectLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Sélection du tarif</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <app-select-chat-pack *ngIf="consultantInfo && paymentModalIsVisible" [customerInfo]="customerInfo"
                  [consultant]="consultantInfo" [isExtendCall]=false
                  (paymentSuccess)="conversationPaymentSucceded($event)"></app-select-chat-pack>
          </div>
      </div>
  </div>

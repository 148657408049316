<div  class="modal fade" id="promotionPopup" tabindex="-1" role="dialog" aria-labelledby="promotionPopupLabel"  data-backdrop="static"  >
  <div class="modal-dialog modal-lg" role="document" >
    <div class="modal-content popup-bg" [ngStyle]="
    {
    'background-image': 'url(' + this.pictureUrl + ')',
    'background-size': 'contain',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'border-bottom':' 0 none'

    }">
      <div class="modal-header" [ngStyle]="
    {
    'border-bottom':' 0 none'
    }">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="text-center" *ngIf="!(promotions | async) || (promotions | async).length === 0">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngFor="let promo of (promotions | async)">
          <div class="row" *ngIf="promo?.isValideCode">
            <div class="col-sm-12 promoText">
              <p *ngIf="this.pictureUrl==null">{{promo?.description}} <b>{{promo?.code}}</b></p>
            </div>
          </div>
          <div class="row mt-3 " *ngIf="promo?.isValideCode" >
            <div class="col-md-6 offset-md-3 mt-5">
              <button aria-label="Close" class="btn btn-danger mx-3 use-promo-btn " data-dismiss="modal" type="button"  (mousedown)="this.promotionService.closePopup()" >Utiliser plus tard
              </button>
              <a href="https://www.allomegane.com/blog/utilisation-code-promo">   <button  class="btn btn-success mx-3 use-promo-btn" type="button" id="useLater">Utiliser la
                promotion
              </button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

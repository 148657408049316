import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {HomeService} from './home.service';
import * as dayjs from 'dayjs';
import * as PromotionsActions from '../actions/promotions.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {CookieService} from 'ngx-cookie-service';
import {CryptoService} from './crypto.service';
import {Observable} from 'rxjs';
import {Account} from '../_models/account';
import {Definitions} from '../_models/definitions';
import { DeviceDetectorService } from 'ngx-device-detector';
import {CustomDateParserFormatter} from '../_helpers/CustomDateParserFormatter';
declare var $: any;



@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  private  apiUrl =  environment.apiUrlPromotion;
  merchantId = environment.merchantId;
  public account: Observable<Account>;
  public definitions: Observable<Definitions>;
  private  apiKey = environment.apiKey;

  constructor(
    private router: Router,
    private http: HttpClient,
    private homeService: HomeService,
    public store: Store<AppState>,
    private cookieService: CookieService,
    private cryptoService: CryptoService,
    private detectorService: DeviceDetectorService,
    private dateParserFormatter: CustomDateParserFormatter
  ) {
    this.definitions = this.store.select('definitions');
    this.definitions.subscribe(definitions => {
      if (definitions) {
        this.merchantId = definitions.merchantId;
      }
    });
  }

  checkPromotion(code , customerId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new  HttpParams({
      fromObject : {
        merchantId : String(this.merchantId),
        customerId: customerId || Number(localStorage.getItem('customerId')),
        code: code
      }
    });
    return this.http.get(`${this.apiUrl}/check-promotion`, {params: params , headers: header});

  }

  usePromotion(code , history, customerId , command) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const data = {
      merchantId: this.merchantId,
      customerId: customerId || Number(localStorage.getItem('customerId')),
      code: code,
      command: command,
      transactionId: history
    };
    return this.http.post(`${this.apiUrl}/use-promotion`, data , {headers: header});

  }

  getPromotion(position: string) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new  HttpParams({
      fromObject : {
        merchantId : String(this.merchantId),
        positionSpot: position,
        active: '1'
      }
    });
    return this.http.get(`${this.apiUrl}/get-promotions`, {params: params , headers: header});
  }
  useCodepromotion() {
    const paths = ['/' , '/consultant-details'];
    const  sessionId = localStorage.getItem('customerSessionId');
    const currentLocation = window.location.pathname;
    if (sessionId ) {
      paths.includes(currentLocation) ? $('#promotionPopup').modal('hide') : this.homeService.redirect('/');
  } else {
    $('#promotionPopup').modal('hide');
    $('#loginModal').modal('show');
   }
  }
  closePopup() {
    $('#promotionPopup').modal('hide');
  }

  handlePromotionBonus(promotion: any) {
    let bonus: string;
    switch (promotion.promotionNature) {
      case 'AMOUNT':
        bonus = promotion.promotionValue + '€ de remise';
        break;
      case 'PERCENTAGE':
        bonus = promotion.promotionValue + ' % de réduction';
        break;
      case 'DURATION':
        bonus = promotion.promotionValue + ' minutes offertes';
        break;
    }
    return {
      bonus
    };
  }

  fetchPromotions(position: string) {
    if (!this.cookieService.check(this.cryptoService.set(position))) {
      this.getPromotion(position).subscribe(
        async (res: any) => {
          const initialResult = this.dateParserFormatter.adaptDateArray(res.promotions, { startDateTime: true, endDateTime: true });
  
          for (const promotion of initialResult) {
            promotion.isValideCode = false;
            const result = this.handlePromotionBonus(promotion);
            promotion.bonus = result.bonus;
            if (promotion.endDateTime == null) {
              promotion.expirationDate = 'Illimitée';
            } else {
              promotion.expirationDate = dayjs(promotion.endDateTime).format('DD/MM/YYYY');
            }
  
            const customerID = localStorage.getItem('customerId');

             const checkPromotionResult: any = await this.checkPromotion(promotion.code, customerID).toPromise();
              if (checkPromotionResult.statusCode === undefined && checkPromotionResult.isValid) {
               
                
                promotion.isValideCode = true;
                console.log(promotion.isValideCode);
              }
          
          }
          const filteredResult = initialResult.filter(promotion => promotion.isValideCode);

          filteredResult.sort((a, b) => a.expirationDate > b.expirationDate ? 1 : -1);
  
          if (filteredResult.length) {
            $('#promotionPopup').modal('show');
            this.store.dispatch(new PromotionsActions.SetPromotions(filteredResult));
            if (position !== 'Fin Consultation') {
              this.cookieService.set(this.cryptoService.set(position), '1');
            }
          } else {
          
            if (position === 'Fin Consultation') {
              this.homeService.redirect('/');
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    
  }

  }

}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '../../_services/register.service';
import { MustMatch } from '../../_helpers/must-match.validator';
import { debounceTime, delay, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { HomeService } from '../../_services/home.service';
import { DefinitionService } from '../../_services/definition.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Definitions } from '../../_models/definitions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
declare const $: any;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  formRegister: FormGroup;
  emailExist: boolean;
  emailLoading: boolean;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  emailToken: string;
  customerEmail = String;
  definitions: Observable<Definitions>;
  regexPassword: string;
  usingSendethic: boolean = false;
  sendethicApiKey: string = '';
  regexPasswordText: any;
  infoDefinitions: any;

  constructor(
    private fb: FormBuilder,
    private registerservice: RegisterService,
    private definitionService: DefinitionService,
    private homeService: HomeService,
    private store: Store<AppState>
  ) {
    this.definitions = store.select('definitions');
    this.definitions.subscribe((definitions) => {
      this.infoDefinitions = definitions;
    });
  }

  get f() {
    return this.formRegister.controls;
  }


  ngOnInit(): void {

    this.definitions.subscribe(definitions => {
      this.regexPassword = definitions?.regex_password;
      this.usingSendethic = definitions?.using_sendethic;
      this.sendethicApiKey = definitions?.sendethic_api_key;
      this.formRegister = this.fb.group({
        customerEmail: ['',
          [Validators.required, Validators.email, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')],
          [this.emailValidations.bind(this)]],
        customerPassword: ['', [Validators.required, Validators.pattern(this.regexPassword)]],
        confirmPassword: ['', [Validators.required]],
      }, {
        validator: MustMatch('customerPassword', 'confirmPassword')
      });
    });
  
  }
  onSubmitRegister() {
    const formData = this.formRegister.getRawValue();
    this.registerservice.customerOldEmail.next(formData.customerEmail);

    if (this.formRegister.valid) {
      this.registerservice.register(formData).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.customerId = result.customerId;
            const data = { recipientMail: formData.customerEmail, customerId: this.customerId };
            this.registerservice.sendValidationMail(data);
            this.newAccountMerchantNotification(formData.customerEmail);
            this.registerservice.customerOldEmail.next(formData.customerEmail);
            this.registerservice.currentUserIdSubject.next(this.customerId);
            $('#titleCreateAccount').html('ACTIVER MON COMPTE ALLO MEGANE');
            $('#bodyModalRegistration').hide();
            $('#bodyModalValidationEmail').show();
            $('#loginModal').hide();
            $('.modal-backdrop').remove();
            $('#verificationModal').modal('toggle');
            this.homeService.resetFormRegister();
            this.customerEmail = formData.customerEmail;
           if (this.usingSendethic) {
             
            this.registerservice.createContactInSendethic(this.sendethicApiKey, this.customerEmail)
             .subscribe(
                (result: any) => {
                  if (result.statusCode === undefined) {
                    console.log('contact created');
                  }
                  else {
                    console.error(result?.body);
                  }
                }
              );
           }
          } else {
            console.error(result?.body);
            $('#registerModal').modal('hide');
            $('#ModalErrorRegister').modal('show');
            this.homeService.resetFormRegister();
          }

        },
        error => {
          console.error(error);
          $('#registerModal').modal('hide');
          $('#ModalErrorRegister').modal('show');
          this.homeService.resetFormRegister();
        }
      );
    } else {
      console.log('error');
    }
  }

  emailValidations(ctr: AbstractControl) {
    this.emailLoading = true;
    return ctr
      .valueChanges.pipe(
        delay(10),
        debounceTime(100)
        , distinctUntilChanged()
        , switchMap(value => this.registerservice
          .checkEmail(ctr.value).pipe(
            map((result: any) => {
              this.emailLoading = false;
              if (result.emailExists === true) {
                ctr.setErrors({ emailExist: true });

              } else {
                ctr.setErrors(null);

              }
            })
          ))
      );
  }

  closeRegisterModal() {
    $('#closeRegisterModal').click();
    $('#loginModal').modal('show');
    $('#bodyModalValidationEmail').hide();
    $('#bodyModalRegistration').show();
    this.homeService.resetFormRegister();
  }

  newAccountMerchantNotification(customerEmail) {

    const newDate = new Date();

    const date = ('0' + Number(newDate.getDate())).slice(-2) + '/' + ('0' + (Number(newDate.getMonth()) + 1)).slice(-2) + '/' + newDate.getFullYear();
    const hour = (Number(newDate.getHours())) + ':' + (Number(newDate.getMinutes()));

    const dataMailSend = {
      merchantId: environment.merchantId,
      recipientMail: this.infoDefinitions.provider_mail,
      templateParams:
      {
        creationDate: date,
        creationHour: hour,
        customerEmail: customerEmail
      },
      template: 'url_template_new_account_merchant_notification',
      subject: 'Création d\'un nouveau compte',
    };

    this.registerservice.sendEmail(dataMailSend).subscribe();

  }



}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../_services/profile.service';
declare var $: any;

@Component({
  selector: 'app-birth-place',
  templateUrl: './birth-place.component.html',
  styleUrls: ['./birth-place.component.css']
})
export class BirthPlaceComponent implements OnInit {
  @Input() birthInfo: string;
  @Input() infoDefinitions: any;
  @Output() birthInfoEvent = new EventEmitter<string>();
  form: FormGroup;
  success = false;
  error = false;
  submitting = false;
  inputMaxLength = 50;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerBirthPlace: ['', [
        Validators.required,
        Validators.maxLength(this.inputMaxLength)
      ]],
    });
    $('#add-birth-place-modal')
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerBirthPlace: this.birthInfo
        });
      }).on('hidden.bs.modal', () => {
      this.reset();
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitting = true;
      this.profileService.editBirthDate(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.birthInfoEvent.emit(this.form.value.customerBirthPlace);
            this.next();
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true): void {
    if (state) {
      $('#add-birth-place-modal').modal('hide');
      $('.modal-backdrop').remove();
      $('.show').remove();
    } else {
      this.success = state;
      this.error = !state;
    }
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.form.patchValue({
      customerBirthPlace: ''
    });
    this.submitting = false;
  }

}

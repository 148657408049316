import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})


export class SpinnerComponent implements OnInit {
  @Input() height: Number = 50;
  @Input() color: string = '#6563ff';

  constructor() { }

  ngOnInit(): void {
  }

}

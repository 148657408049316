<!--Step 1-->
<form (keypress)="stopEnterSubmitting($event , price , minutes)" class="form-horizontal" [formGroup]="form" (submit)="form.valid && askMoney()" *ngIf="step === 1 && !error && !erroCodePromo && isMinForfaitValid">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h5>
          Quelle durée maximale voulez-vous pour votre conversation privée ?
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <select class="form-select form-control"
          [(ngModel)]="minutes"
          name="conversationDuration"
          id="conversationDuration"
          formControlName="conversationDuration"
          (change)="applyPromotion(price , minutes)"
        >
          <option *ngFor="let option of options; let idx = index" [value]="option.numberPaidMin" (change)="applyPromotion(price , option.numberPaidMin)" >{{option.description}}</option>

        </select>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-6">
        <label >Avez-vous un code promotion ? </label>
      </div>
      <div class="col-sm-2 mx-0">
          <input type="text"  (paste)="onpaste($event)" id="promoCode" class="form-control" autocomplete="off" (keyup)="customValidator()">
      </div>
      <div class="col-sm-4">
        <button  type="button" id="applyPromoBtn" class="btn"  (click)="applyPromotion(price ,minutes)"  [ngClass]="{'btn-success':validCode , 'btn-secondary':!validCode }"  [disabled]="!validCode"> Valider le code promotion </button>
      </div>
    </div>
    <div *ngIf="isNotValidCodePromo" class="row  mt-3">
      <div class="col-md-2 ml-3">
        <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
      </div>
      <div class="col-md-8 ">
        <p>Ce code promotion n'est pas valide !</p>
        <p  *ngIf="isNotValidCodePromoMaxDuration">Ce code est utilisable pour une durée maximale inférieur à  {{codepromoMaxDuration}} min</p>

      </div>
    </div>

    <div class="divider py-1"></div>
    <div class="row">
    <div class="col-md-12">
      <table class="table">
        <tr>
          <td>Consultant {{consultantName}}: {{price}}€/min. pendant {{minutes}} min. :</td>
          <td *ngIf="!isNaN(price * minutes)">{{ price * minutes | number : '0.2-2' }} €</td>
        </tr>
        <tr *ngIf=" codePromo &&  this.promotionValue ">
          <td>Code promotion: {{codePromo}} ({{this.promotionValue}}):</td>
          <td *ngIf="codePromo &&  this.promotionValue  ">
         <span id="bonus" attr.data-value="{{bonus}}" *ngIf="bonus">-{{bonus}} </span>
            <button [disabled]="isPromoAlreadyApplied" type="button" class="btn btn-outline-danger" (click)="deletePromotion()">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
              </svg>
            </button>
          </td>
        </tr>
        <tr>
          <td>Total à payer</td>
          <td>
            <div id="finalTotal" attr.data-value="{{finalTotal}}" *ngIf="finalTotal">{{ finalTotal | number : '0.2-2'}} €</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  </div>
  <div class="modal-footer">
    <button   type="submit" class="btn btn-success float-right" id="btnPaiement" [disabled]="form.invalid">Valider</button>
  </div>
</form>

<div *ngIf="step === 1 && error">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3 text-center">
        <i class="fa fa-4x fa-exclamation-triangle text-danger"></i>
      </div>
      <div class="col-md-8">
        <p>Une erreur vient de survenir et nous ne sommes pas en mesure d'accéder à vos moyens de paiements.</p>
        <p>Merci d'essayer de nouveau plus tard!</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
  </div>
</div>
<!---->
<!--Step 2-->
<!-- Selection Bank -->
<div *ngIf="step === 2 && !error && !erroCodePromo && isMinForfaitValid">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h5>
          Votre banque a seulement autorisé {{(definitions | async)?.sms_brand}} à vous proposer le forfait {{minutes}} min.
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <tr>
            <td>Consultant {{consultant.name}}: {{price}}€/min. pendant {{minutes}} min. :</td>
            <td *ngIf="!isNaN(price * minutes)">{{ price * minutes | number : '0.2-2' }} €</td>
          </tr>
          <tr>
            <td>Total à payer</td>
            <td>
              <div *ngIf="!isNaN(price * minutes)">{{ price * minutes | number : '0.2-2' }} €</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="redirect()">J'ai compris {{ time }}</button>
  </div>
</div>

<!-- Error Banque -->
<div *ngIf="step === 2 && error">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3">
        <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
      </div>
      <div class="col-md-8">
        <p>{{ (definitions | async)?.sms_brand }} est désolée, mais votre banque indique qu'elle refusera le paiement de la conversation privée </p>
        <p>Merci d'essayer de nouveau plus tard!</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
  </div>
</div>
<!---->
<!-- Error Banque -->
<div *ngIf="erroCodePromo">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3">
        <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
      </div>
      <div class="col-md-8">
        <p>{{erroMessage}}</p>
        <p>{{messageMerchant}} {{(definitions | async)?.sms_brand}} au {{(definitions | async)?.provider_call_number}}!</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
  </div>
</div>
<!---->
<!-- Error Banque -->
<div *ngIf="!isMinForfaitValid">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3">
        <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
      </div>
      <div class="col-md-8">
        <p>{{ (definitions | async)?.sms_brand }} Impossible d'appliquer la promotion<span *ngIf="finalTotal>0">, choisissez un forfait supérieur ou égal à {{minForfait}}€</span>
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">J'ai compris</button>
  </div>
</div>
<!---->


<div class="container">
  <div class="jumbotron">
    <h1 class="display-4">Allomegane- Voyance CB</h1>
    <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr class="my-4">
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <p class="lead">
      <a class="btn btn-primary btn-lg" [routerLink]="['/consultants']" role="button">List Consultant</a>
    </p>
  </div>

</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowReviewsComponent } from './show-reviews/show-reviews.component';
import {NgbModule, NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from "@angular/router";
import {AddReviewComponent} from "./add-review/add-review.component";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
    declarations: [ShowReviewsComponent,AddReviewComponent],
    exports: [
        ShowReviewsComponent,
        AddReviewComponent
    ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule

  ],
    providers: [NgbRatingConfig]
})
export class ReviewModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultant-live-private-conversation',
  templateUrl: './consultant-live-private-conversation.component.html',
})
export class ConsultantLivePrivateConversationComponent implements OnInit {
  private servicetype: string;
  private setfilter: boolean;

  constructor() { }

  ngOnInit(): void {
    this.servicetype = 'Conversation';
    this.setfilter = true;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Definitions } from '../../_models/definitions';
import { ConsultantService } from '../../_services/consultant.service';
import { AudiotelBannerService } from '../../_services/audiotel-banner.service';
import { environment } from '../../../environments/environment';
import { DefinitionService } from '../../_services/definition.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { info } from 'console';
import { createImmediatelyInvokedFunctionExpression } from 'typescript';

declare var $: any;

@Component({
  selector: 'app-sva',
  templateUrl: './sva.component.html',
  styleUrls: ['./sva.component.css']
})
export class SvaComponent implements OnInit {
  @Input() definitions: Definitions;
  @Input() zoom: string;
  @Input() full = false;
  @Input() isLinkActive = false;
  public svaNumber: string;
  public svaTarif: string;
  public showDetails = false;
  @Input() isFixedNumber = false;

  private readonly svaNumbers: any;
  definitionsObs: Observable<Definitions>;
  private customer_countries;

  constructor(
    public consultantservice: ConsultantService,
    private bannerService: AudiotelBannerService,
    private definitionService: DefinitionService,
    private store: Store<AppState>,


  ) {
    this.svaNumbers = {
      A005: 'Service 0,05€/appel <br/>+ prix appel',
      D040: 'Service 0,40 € / min<br/>+ prix appel',
      A008: 'Service 0,08 €/appel <br/>+ prix appel',
      A009: 'Service 0,09 €/appel  <br/>+ prix appel',
      A010: 'Service 0,10 €/appel <br/>+ prix appel',
      A012: 'Service 0,12 €/appel <br/>+ prix appel',
      A015: 'Service 0,15 €/appel <br/>+ prix appel',
      A020: 'Service 0,20 €/appel <br/>+ prix appel',
      A030: 'Service 0,30 €/appel <br/>+ prix appel',
      A040: 'Service 0,40 €/appel <br/>+ prix appel',
      A050: 'Service 0,50 €/appel <br/>+ prix appel',
      A060: 'Service 0,60 €/appel <br/>+ prix appel',
      A065: 'Service 0,65 €/appel <br/>+ prix appel',
      A079: 'Service 0,79 €/appel <br/>+ prix appel',
      A080: 'Service 0,80 €/appel <br/>+ prix appel',
      A090: 'Service 0,90 €/appel <br/>+ prix appel',
      A099: 'Service 0,99 €/appel <br/>+ prix appel\n',
      A100: 'Service 1 €/appel <br/>+ prix appel',
      A150: 'Service 1,5 €/appel <br/>+ prix appel',
      A199: 'Service 1,99 €/appel <br/>+ prix appel',
      A200: 'Service 2 €/appel <br/>+ prix appel',
      A250: 'Service 2,5 €/appel <br/>+ prix appel',
      A299: 'Service 2,99 €/appel <br/>+ prix appel',
      A300: 'Service 3 €/appel <br/>+ prix appel',
      D005: 'Service 0,05 €/min  <br/>+ prix appel',
      D006: 'Service 0,06 €/min <br/>+ prix appel',
      D009: 'Service 0,09 €/min <br/>+ prix appel',
      D010: 'Service 0,10 €/min <br/>+ prix appel',
      D012: 'Service 0,12 €/min <br/>+ prix appel',
      D015: 'Service 0,15 €/min  <br/>+ prix appel',
      D018: 'Service 0,18 €/min <br/>+ prix appel',
      D020: 'Service 0,20 €/min  <br/>+ prix appel',
      D025: 'Service 0,25 €/min <br/>+ prix appel',
      D030: 'Service 0,30 €/min <br/>+ prix appel',
      D035: 'Service 0,35 €/min <br/>+ prix appel',
      D045: 'Service 0,45 €/min <br/>+ prix appel',
      D050: 'Service 0,50 €/min <br/>+ prix appel',
      D060: 'Service 0,60 €/min  <br/>+ prix appel',
      D070: 'Service 0,70 €/min <br/>+ prix appel',
      D080: 'Service 0,80 €/min <br/>+ prix appel',
      M001: 'Service 0,08 €/appel  <br/>+ 0,02€/min',
      M002: 'Service 0,12 €/appel <br/>+ 0,04€/min'
    };
    this.definitionsObs = store.select('definitions');

  }

  ngOnInit(): void {
    setTimeout(() => {
      const dataParams = this.getBannerIdenfier();
      const bannerIdentifier = dataParams['0'] || localStorage.getItem('bannerIdentifier');
      const startDate = dataParams['1'] || localStorage.getItem('startDate');
      const defInfos = this.definitionsNumbers();
      if (bannerIdentifier && startDate) {
        this.bannerService.getAudiotelBanner(String(bannerIdentifier), environment.officeId, environment.merchantId, startDate
        ).subscribe(res => {
          if (this.isFixedNumber) {
            if (!res?.wired_phone) {
              this.svaNumber = defInfos[0].toString()
            }
            else {
              this.svaNumber = res?.wired_phone.toString();
            }
          } else {
            if (!res?.mobile_phone) {
              this.svaNumber = defInfos[1].toString();
              this.svaTarif = defInfos[2];
              this.svaNumber = this.numberWithSpaces(this.svaNumber);
              this.customer_countries = defInfos[3];
              if (this.customer_countries?.length && this.customer_countries[0] === 'FR') {
                this.svaTarif = this.svaNumbers[this.svaTarif];
                this.showDetails = true;
              }
            }
            else {
              this.svaTarif = 'Service ' + res?.banner_price.toFixed(2).replace('.', ',') + '€ / min<br/>+ prix appel';
              this.svaNumber = res?.mobile_phone.toString();
            }

          }
        }, error => {
          console.log(error);
        });
      } else {
        this.definitionsObs.subscribe(definitions => {
          if (this.isFixedNumber) {
            this.svaNumber = definitions?.fixed_call_number;
          } else {
            this.svaNumber = definitions?.sva_number;
            this.svaTarif = definitions?.sva_number_tariff;
            this.customer_countries = definitions?.customer_countries;
            this.svaNumber = this.numberWithSpaces(this.svaNumber);
            if (this.customer_countries?.length && this.customer_countries[0] === 'FR') {
              this.showDetails = true;
              this.svaTarif = this.svaNumbers[this.svaTarif];
            }
          }
        });
      }
    }, 1000);

  }


  definitionsNumbers(): any {
    let infos;
    this.definitionsObs.subscribe(definitions => {
      const dfFixedCallNumber = definitions?.fixed_call_number;
      const dfSvaNumber = definitions?.sva_number;
      const dfSvaNumberTariff = definitions?.sva_number_tariff;
      const dfcustomerCountries = definitions?.customer_countries;
      infos = [
        dfFixedCallNumber,
        dfSvaNumber,
        dfSvaNumberTariff,
        dfcustomerCountries,
      ]
    })

    return infos;
  }
  numberWithSpaces(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  showSVModal() {
    this.consultantservice.showSVAModalAction(this.isLinkActive);
  }

  getBannerIdenfier() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const bannerIdentifier = urlParams.get('bannerIdentifier');
    const startDate = urlParams.get('startDate');
    if (bannerIdentifier) {
      localStorage.setItem('bannerIdentifier', bannerIdentifier);
      localStorage.setItem('startDate', startDate);
    }
    return [
      bannerIdentifier,
      startDate
    ];
  }

  formatPhoneNumber(input) {
    const formatted = input.split('');
    let formattedNumber = '';

    // tslint:disable-next-line:forin
    for (const el in formatted) {
      if (['0', '3', '6'].includes(el)) {
        formatted[el] = formatted[el] + ' ';
      }
      formattedNumber += formatted[el];

    }
    return formattedNumber;
  }

}

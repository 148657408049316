import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import * as SessionActions from '../actions/session.actions';
import {Session} from '../_models/session';


@Injectable({
  providedIn: 'root'
})

export class SessionService {

  constructor(
    private store: Store<AppState>
  ) {
  }

  init(session: Session): void {
    session = this.preInit(session);
    this.store.dispatch(new SessionActions.SetSession(session));
    this.postInit(session);
  }

  private preInit(session: Session): Session {
    return session;
  }

  private postInit(session: Session): void {
    // ...
  }

  update(data: {}): void {
    this.store.dispatch(new SessionActions.PutSession(data));
  }

  destroy(): void {
    this.store.dispatch(new SessionActions.DeleteSession());
  }

  login(customerSessionId, customerId): void {
    this.update({
      isLoggedIn: true,
      customerSessionId,
      customerId
    });
  }

  logout(): void {
    this.update({
      isLoggedIn: false,
      customerSessionId: null,
      customerId: null
    });
  }
}

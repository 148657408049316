import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Definitions } from '../_models/definitions';
declare var $: any;



@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  definitions: Observable<Definitions>;

  apiUrl = environment.apiUrlAccountCustomer;
  private data: any;
  public customerOldEmail = new BehaviorSubject(null);
  public currentUserIdSubject = new BehaviorSubject(null);
  sharedUserId = this.currentUserIdSubject.asObservable();
  private apiKey = environment.apiKey;


  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.definitions = store.select('definitions');
  }

  register(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    formData.merchantId = environment.merchantId;
    formData.cabinetId = environment.officeId;
    return this.http.post(`${this.apiUrl}/account-create`, formData, { headers: header });
  }

  addContactPhoneNumberInSendEthic(authorizationKey, email, phone) {
 
    const payload = 
    {
      "contactKey": email,
      "attributes": [
         {
          "id": "mobile",
          "fieldName": "mobile",
          "fieldValue": phone
        }
      ] };
    
    const header = new HttpHeaders().set('authorization',
    'Basic '+authorizationKey).set('content-type','application/json')
    .set('access-Control-Allow-Origin','*');
    return this.http.put('/api/rest/v4/contact/attribute', payload, { headers: header });
  }

  addContactFirstnameInSendEthic(authorizationKey, email, firstname) {
 
    const payload = 
    {
      "contactKey": email,
      "attributes": [
         {
          "id": "firstname",
          "fieldName": "firstname",
          "fieldValue": firstname
        }
      ] };
    
    const header = new HttpHeaders().set('authorization',
    'Basic '+authorizationKey).set('content-type','application/json')
    .set('access-Control-Allow-Origin','*');
    return this.http.put('/api/rest/v4/contact/attribute', payload, { headers: header });

  }

  addContactLastnameInSendEthic(authorizationKey, email, lasname) {
    const payload = 
    {
      "contactKey": email,
      "attributes": [
         {
          "id": "lastname",
          "fieldName": "lastname",
          "fieldValue": lasname
        }
      ] 
  };
    
    const header = new HttpHeaders().set('authorization',
   'Basic '+authorizationKey).set('content-type','application/json')
   .set('access-Control-Allow-Origin','*');
    return this.http.put('/api/rest/v4/contact/attribute', payload, { headers: header });
  }


  createContactInSendethic(authorizationKey, email) {
    const payload = 
    {
      "contactKey": email,
      "attributes": [
         {
          "id": "email",
          "fieldName": "email",
          "fieldValue": email
        }
      ]
    
  };
    
   const header = new HttpHeaders().set('authorization',
   'Basic '+authorizationKey).set('content-type','application/json')
   .set('access-Control-Allow-Origin','*');
    return this.http.post('/api/rest/v4/contact/attribute',payload, { headers: header });

  }


  checkEmail(email: string) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    this.data = {
      merchantId: environment.merchantId,
      cabinetId: environment.officeId,
      customerEmail: email
    };
    return this.http.post(`${this.apiUrl}/account-create-check`, this.data, { headers: header });

  }

  checkToken(customerToken, deleteToken = '0') {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);

    const params = new HttpParams({
      fromObject: {
        customerToken,
        deleteToken
      }
    });
    return this.http.get(`${this.apiUrl}/account-check-token`, { params: params, headers: header });

  }

  createToken(data) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-create-token`, data, { headers: header });
  }

  sendEmail(data) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    this.definitions.subscribe((definitions) => {
      if (definitions?.provider_mail) {
        data.senderMail = definitions.provider_mail;
      }
    });
    return this.http.post(`${this.apiUrl}/account-mail-send`, data, { headers: header });
  }

  createTemporarySession(data) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-create-temporary-session`, data, { headers: header });
  }

  resetPassword(data) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-password-init`, data, { headers: header });
  }


  sendValidationMail(data) {
    const baseUrl = window.location.origin + location.pathname;
    const dataToken = { customerId: data.customerId, commandToken: 'VALIDATE-MAIL' };
    const recipientMail = data.recipientMail;
    this.createToken(dataToken).subscribe(
      (result: any) => {
        if (result.statusCode === undefined) {
          const emailToken = result.customerToken;
          if (result.customerToken) {
            const dataMailSend = {
              merchantId: environment.merchantId,
              recipientMail,
              templateParams: { url: baseUrl + '?to=' + emailToken },
              template: 'url_template_account_activation',
              subject: 'Activation de votre compte',
            };
            this.sendEmail(dataMailSend).subscribe(
              (res: any) => {
                if (res.statusCode === undefined) {
                  // ...
                } else {
                  $('#registerModal').modal('hide');
                  $('#modalErrorEmailValidation').modal('show');
                }
              },
              error => {
                $('#registerModal').modal('hide');
                $('#modalErrorEmailValidation').modal('show');
              }
            );
          }
        } else {
          $('#registerModal').modal('hide');
          $('#modalErrorEmailValidation').modal('show');
        }
      },
      error => {
        $('#registerModal').modal('hide');
        $('#modalErrorEmailValidation').modal('show');
      }
    );

  }


}

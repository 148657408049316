import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../_services/register.service';
import {HomeService} from '../../_services/home.service';
import {MustMatch} from '../../_helpers/must-match.validator';
import {LoginService} from '../../_services/login.service';
import {environment} from '../../../environments/environment';
import {Definitions} from '../../_models/definitions';
declare var $: any;

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  @Input() infoDefinitions: Definitions;
  formResetPassword: FormGroup;
  emailExist: boolean;
  emailLoading: boolean;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  submitting = false;

  constructor(private fb: FormBuilder, private registerService: RegisterService, private loginService: LoginService, private homeService: HomeService) {
  }

  get f() {
    return this.formResetPassword.controls;
  }


  ngOnInit(): void {
    this.formResetPassword = this.fb.group({
      merchantId: environment.merchantId,
      cabinetId: environment.officeId,
      customerPassword: ['', [Validators.required, Validators.pattern(this.infoDefinitions?.regex_password)]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: MustMatch('customerPassword', 'confirmPassword')
    });
  }

  onSubmitResetPassword() {
    const formData = this.formResetPassword.getRawValue();
    if (this.formResetPassword.valid) {

      this.resetPassword(formData.customerEmail, formData.customerPassword, formData.merchandId, formData.cabinetId);

    } else {
      console.log('error');
    }
    // formData.reset();
    this.formResetPassword.patchValue({
      customerPassword: '',
      confirmPassword: ''
    });
  }


  resetPassword(customerEmail, customerPassword, merchandId, cabinetId) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerToken = urlParams.get('to');
    console.log(customerToken);

    if (customerToken) {
      this.submitting = true;
      // Check if token is valid

      this.registerService.checkToken(customerToken).subscribe(

        (resultCheckToken: any) => {

          if (resultCheckToken.isValid) {

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const customerTmpSession = urlParams.get('ts');
            console.log(customerTmpSession);

            // create temporary session
            this.loginService.checkSession(customerTmpSession).subscribe(
              (result: any) => {

                // @todo to review
                if (customerTmpSession) {

                  const dataResetPassword = {
                    customerId: resultCheckToken.customerId,
                    customerTemporarySessionId: customerTmpSession,
                    customerPassword
                  };

                  this.registerService.resetPassword(dataResetPassword).subscribe(
                    (result2: any) => {

                      if (result2.statusCode === undefined) {
                        // Reset password with success
                        $('#closeModalPasswordReset').click();
                        $('#clickWebLinkSuccessMessage').html('FÉLICITATIONS ! Votre mot de passe est modifié ! vous allez pouvoir vous identifier.');
                        $('#openModalClickWebLinkSuccess').click();
                        this.registerService.checkToken(customerToken, '1').subscribe();
                        this.submitting = false;

                      } else {
                        // Can't reset password
                        $('#closeModalPasswordReset').click();
                        $('#clickWebLinkApiErrorMessage').html('ERREUR : Votre email ou votre mot de passe sont invalides! Votre mot de passe n\'a pas été réinitialisé.');
                        $('#openModalErrorAccountApi').click();
                        this.submitting = false;

                      }

                    },
                    error => {
                      // Can't create temporary session (error api account fct resetPassword)
                      console.log(error);
                      $('#closeModalPasswordReset').click();
                      $('#clickWebLinkApiErrorMessage').html('Une erreur vient de survenir et nous ne sommes pas en mesure de modifier votre mot de passe.');
                      $('#openModalErrorAccountApi').click();
                    }
                  );
                } else if (result.body.statusCode === -20) {
                  // Can't reset password
                  $('#closeModalPasswordReset').click();
                  $('#clickWebLinkApiErrorMessage').html('ERREUR : Votre email ou votre mot de passe sont invalides! Votre mot de passe n\'a pas été réinitialisé.');
                  $('#openModalErrorAccountApi').click();

                } else {
                  $('#closeModalPasswordReset').click();
                  $('#clickWebLinkApiErrorMessage').html('Une erreur vient de survenir et nous ne sommes pas en mesure de modifier votre mot de passe.');
                  $('#openModalErrorAccountApi').click();

                }

              },
              error => {
                // Can't connect to API account
                $('#closeModalPasswordReset').click();
                $('#clickWebLinkApiErrorMessage').html('Une erreur vient de survenir et nous ne sommes pas en mesure de modifier votre mot de passe.');
                $('#openModalErrorAccountApi').click();
              }
            );

          } else {
            // Invalide token
            $('#invalideToken').modal('show');
          }

        },
        error => {
          // Can't connect to API account
          $('#clickWebLinkApiErrorMessage').html('Une erreur vient de survenir et nous ne sommes pas en mesure de vérifier votre lien.');
          $('#openModalErrorAccountApi').click();
        }

      );
    }


  }

}


<app-navbar></app-navbar>
<div>
  <div class="row mt-5" *ngIf="(session | async).isLoggedIn === null">
    <div class="col text-center">
      <div class="spinner-border" role="status" aria-hidden="true">
        <span class="sr-only">Chargement...</span>
      </div>
    </div>
  </div>
  <router-outlet *ngIf="(session | async).isLoggedIn !== null"></router-outlet>
</div>


<!-- Account Module  -->
<app-account></app-account>

<!-- Payment  Module  -->
<app-payment [definitions]="definitions | async"></app-payment>



import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../_services/profile.service';
import {Definitions} from '../../../_models/definitions';
declare var $: any;

@Component({
  selector: 'app-birth-country',
  templateUrl: './birth-country.component.html',
  styleUrls: ['./birth-country.component.css']
})
export class BirthCountryComponent implements OnInit {
  @Input() birthInfo: string;
  @Input() infoDefinitions: Definitions;
  @Output() birthInfoEvent = new EventEmitter<string>();
  form: FormGroup;
  success = false;
  error = false;
  countries = [];
  submitting = false;
  countriesApi: { endpoint: string, fields: string[], codes: string[] };
  customCountryCodes: { code: string, childes: string[] }[];
  customCountryName: { code: string, name: string }[];

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
      this.countriesApi = {
        // endpoint: 'https://restcountries.eu/rest/v2/alpha',
        endpoint: 'https://restcountries.eu/rest/v2',
        fields: ['translations', 'name', 'alpha2Code'],
        codes: []
      };
      this.customCountryCodes = [{
        code: 'FR',
        childes: ['GP', 'GF', 'MQ', 'YT', 'RE', 'MF']
      }];
      this.customCountryName = [{
        code: 'GF',
        name: 'Guyane'
      }];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerBirthCountry: ['', [
        Validators.required
      ]],
    });
    $('#add-birth-country-modal')
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerBirthCountry: this.birthInfo
        });
      })
      .on('hidden.bs.modal', () => {
        this.reset();
      });

    const apiUrl = this.parseApiUrl(true);
    fetch(apiUrl)
      .then(response => {
        return response.json();
      })
      .then((data: any[]) => {
        const countries = [];
        if (this.infoDefinitions.customer_countries) {
          this.infoDefinitions.customer_countries.forEach((cc: string) => {
            const country = data.find(({alpha2Code}) => alpha2Code === cc);
            if (country !== undefined) {
              countries.push(country);
            }
            const customCountry = this.customCountryCodes.find(({code}) => code === cc);
            if (customCountry !== undefined) {
              customCountry.childes.forEach((child) => {
                const childCountry = data.find(({alpha2Code}) => alpha2Code === child);
                if (childCountry !== undefined) {
                  countries.push(childCountry);
                }
              });
            }
          });
          data.forEach((item) => {
            if (countries.find(({alpha2Code}) => alpha2Code === item.alpha2Code) === undefined) {
              countries.push(item);
            }
          });
          this.countries = countries.map((country) => {
            const customCountryName = this.customCountryName.find(({code}) => code === country.alpha2Code);
            return {
              code: country.alpha2Code,
              name: customCountryName?.name || country.translations?.fr || country.name
            };
          });
        } else {
          this.countries = data;
        }

        this.form.patchValue({
          customerBirthCountry: this.birthInfo
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitting = true;
      this.profileService.editBirthDate(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.birthInfoEvent.emit(this.form.value.customerBirthCountry);
            this.next();
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true): void {
    if (state) {
      $('#add-birth-country-modal').modal('hide');
      $('.modal-backdrop').remove();
      $('.show').remove();
    } else {
      this.success = state;
      this.error = !state;
    }
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.form.patchValue({
      customerBirthCountry: ''
    });
    this.submitting = false;
  }

  parseApiUrl(fields = false, codes = false): string {
    let url = `${this.countriesApi.endpoint}?`;
    if (fields) {
      url += `fields=${this.countriesApi.fields.join(';')}`;
    }
    if (codes) {
      url += (fields ? '&' : '') + `codes=${this.countriesApi.codes.join(';')}`;
    }
    return url;
  }

}

<!--- btn add card Modal notice -->
<button style="display: none" id="openModalAddPaymentCardNotice" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#addPaymentCardNotice">
</button>


<!--- btn Modal add card notice -->

<div  class="modal fade" id="addPaymentCardNotice" tabindex="-1" role="dialog" aria-labelledby="addPaymentCardModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h1 class="font-weight-normal"> Ajouter un moyen de paiement</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
        <p>Pour utiliser nos services, vous devez renseigner un moyen de paiement.</p>
        <p>Nous allons réserver 0,10€ pour valider votre CB mais SANS DEBITER cette somme.</p>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button type="button" class="btn btn-success btn-lg " data-dismiss="modal" (click)="this.paiementService.addCard()">J'ai compris</button>

        </div>

      </div>

    </div>
  </div>
</div>




<!--    Add card btn Error  -->
<button style="display: none" id="openModalErrorAddcard" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalErrorAddCard">
</button>

<!--    Add card Modal Error  -->
<div class="modal fade" id="ModalErrorAddCard" tabindex="-1" role="dialog" aria-labelledby="ModalErrorRegisterLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalErrorRegisterLabel"> Ajouter un moyen de paiement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure d'ajouter votre moyen de paiement.
            </p>
          </div>


        </div>
        <p>Merci de contacter directement {{definitions?.sms_brand}} au {{definitions?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>


<!--    make card primary  btn Error  -->
<button style="display: none" id="openModalErrorPrimary" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalErrorCardPrimary">
</button>

<!--     make card primary Modal Error  -->
<div class="modal fade" id="ModalErrorCardPrimary" tabindex="-1" role="dialog" aria-labelledby="ModalErrorRegisterLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalErrorCardPrimaryLabel"> Mes Moyens de paiement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer votre demande.
            </p>
          </div>


        </div>
        <p class="col-md-9 offset-md-2">Merci d'essayer de nouveau plus tard ! </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>

<!--    list of card payment  btn Error  -->
<button style="display: none" id="openModalErrorCardList" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalErrorCardList">
</button>

<!--    Add make card primary Modal Error  -->
<div class="modal fade" id="ModalErrorCardList" tabindex="-1" role="dialog" aria-labelledby="ModalErrorRegisterLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalErrorCardListLabel"> Mes Moyens de Paiement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure d'accéder à vos moyens de paiements.
            </p>
          </div>


        </div>
        <p class="col-md-9 offset-md-2">Merci d'essayer de nouveau plus tard ! </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>



<!--- btn add card Modal success -->
<button style="display: none" id="openModalAddPaymentCardSuccess" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#addPaymentCardSuccess">
</button>


<!--- btn Modal add card success -->

<div class="modal fade" id="addPaymentCardSuccess" tabindex="-1" role="dialog" aria-labelledby="addPaymentCardModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h2 class="font-weight-normal"> Ajouter un moyen de paiement</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
        <p class="">FELICITATIONS ! Votre carte bancaire N° {{cardNumber}} est validée !</p>
        <p>Vous pourrez gérer vos moyens de paiement dans <a data-dismiss="modal" class="btn-details" (click)="homeService.redirect('add-payment-card')" >Mes Moyens de Paiements</a> si nécessaire.</p>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="justify-content-center">
          <button type="button" class="btn btn-success btn-lg " data-dismiss="modal" >Continuer</button>
        </div>

      </div>

    </div>
  </div>
</div>


<!--- btn add card Modal notice -->
<button style="display: none" id="openModalAddPaymentCardFailed" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#addPaymentCardFailed">
</button>


<!--- btn Modal add card notice -->

<div class="modal fade" id="addPaymentCardFailed" tabindex="-1" role="dialog" aria-labelledby="addPaymentCardModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h2 class="font-weight-normal "> Ajouter un moyen de paiement</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
        <div class="row justify-content-center">
          <div class="col-sm-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-8">
            <p >ATTENTION : Votre moyen de paiement n'a pas été accepté !</p>
            <p>Nous vous invitons à recommencer afin d’ajouter un moyen de paiement </p>
          </div>
        </div>


      </div>
      <div class="modal-footer justify-content-center">
        <div >
          <button type="button" class="btn btn-success btn-lg " data-dismiss="modal" >Continuer</button>

        </div>

      </div>

    </div>
  </div>
</div>

<div class="container mt-4">
  <div class="row align-items-center">
  <div class="col">
    <a (click)="homeService.redirect('/')" class="btn btn-success-mobiyo btn-sm mr-5">< Revenir à la liste des Consultants</a>
  </div>
</div>
<table class="table table-condensed mt-4">
  <thead>
  <tr>
    <th>Vos cartes de paiements </th>
    <th class="td-table-mobiyo">Validité</th>
    <th class="td-table-mobiyo">Carte principale</th>
    <th class="td-table-mobiyo">Supprimer</th>
  </tr>
  </thead>
  <tbody *ngFor="let card of cardslist">
  <tr *ngIf="card.partial_number  && card.active" >
    <td >Carte de Paiement  {{card.partial_number}}</td>
    {{checkExpirationDate(card.exp_year)}}
    <td class="td-table-mobiyo" *ngIf="isCardExpired == false ; else elseBlock">{{card.exp_month}}/{{card.exp_year}}</td>
    <ng-template #elseBlock><td class="text-danger td-table-mobiyo" >Expirée {{card.exp_month}}/{{card.exp_year}}</td></ng-template>

    <td class="td-table-mobiyo">
      <input
        *ngIf="isCardExpired == false"
        id="chkToggle1"
        type="radio"
        name="isMainPayment"
        data-toggle="toggle"
        [checked]="card.main_payment"
        (click)="OnsubmitPrimary(card.merchant_id,card.customer_id,card.id)"
        [disabled]="availableCards == 1"
      >
    </td>
    <td class="td-table-mobiyo">
      <button *ngIf="isCardExpired == false"  type="button" class="btn btn-outline-danger" (click)="OnSubmitStatusCard(card.merchant_id,card.customer_id,card.id)">
        <svg width="1.5em" height="2em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
          <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </button>
    </td>
  </tr>


  </tbody>
</table>


<button type="button" class="btn btn-success-mobiyo  btn-lg" (click)="onSubmitSetCard()" >
  Ajouter une carte de paiement
</button>

</div>
<button id="openModalDeleteCard" [hidden]="true" type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalDeleteCard">
</button>

<!--   Logout Modal -->
<div class="modal fade" id="ModalDeleteCard" tabindex="-1" role="dialog" aria-labelledby="ModalessageLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalDeleteCardLabel">Supprimer la carte de paiement ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-sm-9">
            <p>
              Voulez-vous vraiment supprimer cette carte ?
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Non</button>
        <button type="button" class="btn btn-secondary text-center" data-dismiss="modal" id="deleteCard">Oui</button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { paidPack as PaidPackSchema } from "../_models/paidPack";
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: "root" })
export class ExtendConversation {
  private apiUrl = environment.apiUrlChat;
  private apiKey = environment.apiKey;
  private apiPayment = environment.apiUrlPayment;
  private isExtendedSource = new BehaviorSubject<Number>(0);

  isExtended = this.isExtendedSource.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  updateIsExtended(value: Number) {
    this.isExtendedSource.next(value);
  }
  async closeConversation(paidPack: PaidPackSchema = null) {
    const headers = new HttpHeaders().set("x-api-key", `${this.apiKey}`);

    const payload = {
      consultant_id: paidPack.consultant_id,
      customer_id: paidPack.customer_id,
      transaction_id: paidPack.transaction_id,
      amount: paidPack.total_amount,
      payment_status: "paid",
    };
    try {
      const response = await this.http.post(`${this.apiUrl}/close-conversation`, payload, { headers, }).toPromise();
      // Handle the response data
      console.log(response);
      return response;
    } catch (error) {
      // Handle any errors
      console.error('Error:', error);
      throw error;
    }
  }


  async getAmount(paidPack: PaidPackSchema = null) {
    const customerId = paidPack?.customer_id?.toString() || localStorage.getItem('customerId');
    const cardOneClickId = paidPack?.payment_one_click_id?.toString();
    const amount = paidPack?.total_amount?.toString();
    const authorizationId = paidPack?.authorization_id?.toString();
    const historyId = paidPack?.transaction_id?.toString();

    const headers = new HttpHeaders().set('x-api-key', this.apiKey);

    const params = new HttpParams({
      fromObject: {
        customerId,
        cardOneClickId,
        amount,
        authorizationId,
        historyId
      }
    });

    try {
      const response = await this.http.get(`${this.apiPayment}/get_money`, { params: params, headers: headers }).toPromise();
      // Handle the response data
      console.log(response);
      return response;
    } catch (error) {
      // Handle any errors
      console.error('Error:', error);
      throw error;
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PhoneService {

  apiUrl = environment.apiUrlAccountCustomer;
  private  apiKey = environment.apiKey;



  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }


  updatePhone(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-update-phone`, formData , {headers: header});
  }

  createCode(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-create-code`, formData , {headers: header});
  }

  checkCode(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-check-code`, formData , {headers: header});
  }

  sendSMSCode(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-sms-send`, formData , {headers: header});
  }

  ivrPlayCode(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-ivr-play-code`, formData , {headers: header});
  }

  deleteSecondaryPhone(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-delete-phone`, formData , {headers: header});
  }

  sendCheckCode(isWiredPhone = false) {
    let minutes = 0;
    let seconds = 0;
    let distance = 0;
    let countDownDate = 0;
    let now = 0;
    $('.requestSMS').hide();
    $('.updatePhone').hide();
    $('.sendSMSCountDown').show();

    // Set the date we're counting down to
    countDownDate = new Date().getTime() + 2 * 60000;

    // Update the count down every 1 second
    const x = setInterval(() => {

      // Get today's date and time
      now = new Date().getTime();

      // Find the distance between now and the count down date
      distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      if (isWiredPhone) {
        $('.sendSMSCountDown p').html('Votre code vient d\'être envoyé. ' + minutes + 'm ' + seconds + 's avant de demander l\'envoie d\'un autre code');
      } else {
        $('.sendSMSCountDown p').html('SMS envoyé. ' + minutes + 'm ' + seconds + 's avant de demander un autre SMS');
      }
      const elements = document.querySelectorAll('.modalUpdatePhone');
      elements.forEach(el => {
        el.addEventListener('click', (event) => {
          clearInterval(x);
          isWiredPhone = false;
        });
      });

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        $('.sendSMSCountDown').hide();
        $('.requestSMS').show();
        $('.updatePhone').show();

      }
    }, 1000);

  }


}

import { Component, OnInit ,Input} from '@angular/core';
@Component({
  selector: 'app-consultant-live-audiotel',
  templateUrl: './consultant-live-audiotel.component.html',
})
export class ConsultantLiveAudiotelComponent implements OnInit {
  private servicetype: string;
  private setfilter: boolean;
  @Input() timeZoneClient: string=null;

  constructor() { }

  ngOnInit(): void {
    this.servicetype = 'Audiotel';
    this.setfilter = true;
  }

}



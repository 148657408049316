const DateTimeHelper = {
  MinutesToTimeFormat: (value: number): any => {
    const date = new Date(1970, 0, 1);
    date.setMinutes(value);
    return date.toTimeString().substr(0, 8);
  }

};

export default DateTimeHelper;

import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Promotion} from "../_models/promotion";
import {DeviceDetectorService} from "ngx-device-detector";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {HomeService} from "../_services/home.service";
import {Store} from "@ngrx/store";
import {AppState} from "../app.state";
import {CookieService} from "ngx-cookie-service";
import {CryptoService} from "../_services/crypto.service";
import {element} from "protractor";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';
  // private detectorService: DeviceDetectorService;

  constructor(
    private detectorService: DeviceDetectorService
  ) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  two(x: number): string {
    return x > 9 ? `${x}` : `0${x}`;
  }

  format(date: NgbDateStruct | null): string {
    return date ? this.two(date.day) + this.DELIMITER + this.two(date.month) + this.DELIMITER + date.year : '';
  }


  public adaptDateArray(array, elements) {

    return array.map(obj => {
      switch (this.detectorService.browser) {
        case 'Chrome':
          break;
        case 'Firefox':
          break;
        case 'Safari':
          for (const [element, withTimeZone] of Object.entries(elements)) {
            if (obj[element]) {
              obj[element] = obj[element].replaceAll('-', '/');
            }
          }
          break;
        case 'IE':
          for (const [element, withTimeZone] of Object.entries(elements)) {
            if (obj[element]) {
              if(withTimeZone) {
                obj[element] = obj[element].replaceAll('-', '/').substring(0, obj[element].lastIndexOf(':00'));
              }
              else{
                obj[element] = obj[element].replaceAll('-', '/');
              }
            }
          }
          break;
      }
      return obj;
    });
  }


  public adaptDateObject(object, elements) {
    switch (this.detectorService.browser) {
      case 'Chrome':
        break;
      case 'Firefox':
        break;
      case 'Safari':
        for (const [element, withTimeZone] of Object.entries(elements)) {
          if(object[element]) {
            object[element] = object[element].replaceAll('-', '/');
          }
        }
        break;
      case 'IE':
        for (const [element, withTimeZone] of Object.entries(elements)) {
          if(object[element]) {
            if(withTimeZone) {
              object[element] = object[element].replaceAll('-', '/').substring(0, object[element].lastIndexOf(':00'));
            }
            else{
              object[element] = object[element].replaceAll('-', '/');
            }
          }
        }
        break;
    }
    return object;
  }


}

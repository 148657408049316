<div class="chat-modal-container">
  <div class="chat-modal">
    <!-- Modal header -->
    <div class="modal-header">
      <!-- Consultant name -->
      <div>
        <div class="consultant-info">
          <app-avatar [imageSrc]="consultant?.consultantpictureurl"></app-avatar>
          <div [class]="connection ? 'online' : 'offline'"></div>
          <p>{{consultant?.name}}</p>
        </div>
      </div>
      <!-- Remaining time -->
      <div class="timer">
        <p [ngStyle]="{'margin-top':'5px', 'background-color': timerBgColor, 'color': timerColor}">
          {{getRemainingTime()}}</p>
      </div>
      <button class="close-modal" (click)="closeChat()">X</button>
    </div>
    <!-- Modal body -->
    <div class="chat-modal-body">
      <div *ngIf="loadingMessages" class="center full">
        <app-spinner [height]="50"></app-spinner>
      </div>
      <!-- Loop over messages -->
      <p (click)="scrollConversationDown()"
        style="cursor:pointer; color:#546e7a; font-size:10px; width:100%; text-align:center"
        *ngIf="!canLoadMoreMessages">
        Ceci est le tout debut de votre conversation &darr;</p>
      <div *ngFor="let message of messages; let index = index"
        [ngClass]="message.sender_id == customerID ? 'message right' : 'message left' ">
        <p [title]="message.created_at">
          {{ message.body }}
          <span class="message-date">
            {{formatDate(message.created_at, 'time')}}
            <app-delivery [delivered]="!!message.read_at"
              *ngIf="!message.is_sending && message.sender_id == customerID"></app-delivery>
          </span>
        </p>
      </div>


      <div class="ticontainer">
        <div class="tiblock">
          <div class="tidot"></div>
          <div class="tidot"></div>
          <div class="tidot"></div>
        </div>
      </div>

    </div>


    <!-- Scroll down will be shown when a new message arrive while the user is not in the bottom of the conversation -->
    <button *ngIf="unreadMessages > 0" class="new-message-badge "
      (click)="scrollConversationDown()">{{unreadMessages}}</button>

    <form [formGroup]="chatForm" class="flex modal-submit-form" (ngSubmit)="sendMessage()">
      <textarea type="text" id="messageBody" [placeholder]="'Saisir votre question ici..'" formControlName="messageBody"
        name="messageBody" placeholder="Saisir votre question ici..."></textarea>


      <button [disabled]="remainingSeconds < 1 || !chatForm.valid || sendingMessage" type="submit">
        <!-- Loader -->
        <app-spinner [height]="20" [color]="'#fff'" *ngIf="sendingMessage"></app-spinner>

        <svg *ngIf="!sendingMessage" class="icon" viewBox="0 0 21 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Two-Tone" transform="translate(-374.000000, -1529.000000)">
              <g id="Content" transform="translate(100.000000, 1428.000000)">
                <g id="Two-Tone-/-Content-/-send" transform="translate(272.000000, 98.000000)">
                  <g>
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path d="M4,8.25 L11.51,9.25 L4.01,6.03 L4,8.25 Z M4.01,17.97 L11.51,14.75 L4,15.75 L4.01,17.97 Z"
                      id="🔹-Secondary-Color" fill="#fff"></path>
                    <path
                      d="M2.01,3 L2,10 L17,12 L2,14 L2.01,21 L23,12 L2.01,3 Z M4,8.25 L4,6.03 L11.51,9.25 L4,8.25 Z M4.01,17.97 L4.01,15.75 L11.52,14.75 L4.01,17.97 Z"
                      id="🔹-Primary-Color" fill="#fff"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </form>


    <p class="m-footer">Offert par Mobiyo</p>
  </div>
</div>

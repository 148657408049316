<div class="container mt-4 my-4" xmlns:svg="">
  <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-5 mt-5">
    <div class="flex_etapes d-flex justify-content-center w-100">
      <div class="first etape_out d-flex flex-column justify-content-center m-1 w-100">
        <div class="card card-dark bg-light card-steps-text ">
          <div class="card-body ">
            <div class="row font-weight-normal">
              <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                <p *ngIf="isLogging;else elseBlock1" class="card-text steps-text ">
                  Voyance immédiate en 3 étapes
                </p>
                <ng-template #elseBlock1>
                  <p class="card-text steps-text">Voyance immédiate en 2 étapes </p>
                </ng-template>

              </div>
              <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                <img [ngClass]="{'img-mavoyance-2':!isLogging,'img-mavoyance':isLogging}"
                  src="/assets/images/mavoyance-01.png">
                <p class="card-text steps-text ">1 Je choisis un voyant.
                </p>
              </div>

              <div *ngIf="isLogging" [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                <img class=" img-mavoyance" src="/assets/images/mavoyance-02.png">
                <p class="card-text steps-text ">2 Je crée ou je me connecte sur mon compte.
                </p>

              </div>
              <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                <img *ngIf="isLogging" class=" img-mavoyance" src="/assets/images/mavoyance-03.png">
                <p *ngIf="isLogging;else elseBlock2" class="card-text steps-text"> 3 Je commence la
                  consultation.</p>
                <ng-template #elseBlock2>
                  <img [ngClass]="{'img-mavoyance-2':!isLogging ,'img-mavoyance':isLogging}"
                    src="/assets/images/mavoyance-02.png">
                  <p class="card-text steps-text">2 Je commence la consultation.</p>
                </ng-template>
              </div>
              <div
                [ngClass]="{'col-sm-3':!((isLoggedIn$ | async) === false && !isValidtoken) ,'col-sm-2':(isLoggedIn$ | async) === false && !isValidtoken}">
                <p class="card-text steps-text">Fin de l'appel : je serai débité du temps passé. </p>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>

  </div>
  <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-2 mt-2">
    <div class="col-md-4 mb-3 search-expert">
      Rechercher un expert<br>
      <div class="mt-2">
        <input (keyup)="addFilter($event)" class="mr-2 form-control input-search-mobiyo" id="consultant" maxlength="30"
          placeholder="Nom du consultant" type="text">
      </div>
    </div>
    <div class="col-md-4 mb-3 text-center">
      Comment souhaitez-vous consulter ? <br>
      <div class="mt-2 ml-4">
        <a class="nav-link-mobiyo"><span (click)="addFilter($event)" [ngbTooltip]="consultationModesTitle['AUDIOTEL']"
            class="fa fa-phone fa-audiotel-unavailable consultation_modes icon-phone-search-mobiyo mr-4"
            data-placement="top" data-toggle="tooltip" id="Audiotel" style=""
            tooltipClass="tooltip-available tlp-top"></span></a>
        <a class="nav-link-mobiyo"><span (click)="addFilter($event)" [ngbTooltip]="consultationModesTitle['CB']"
            class="fa-conv-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" data-placement="top"
            data-toggle="tooltip" id="Conversation" tooltipClass="tooltip-available tlp-top"></span></a>
        <a class="nav-link-mobiyo"><span (click)="addFilter($event)" [ngbTooltip]="consultationModesTitle['CHAT']"
            class="fa-chat-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" data-placement="top"
            data-toggle="tooltip" id="Chat" tooltipClass="tooltip-available tlp-top"></span></a>
        <a class="nav-link-mobiyo"><span (click)="addFilter($event)" [ngbTooltip]="consultationModesTitle['MAIL']"
            class="fa-mail-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" data-placement="top"
            data-toggle="tooltip" id="Mail" tooltipClass="tooltip-available tlp-top"></span></a>
      </div>
    </div>
    <div class="col-md-4 mb-3 text-center">
      En quelle langue souhaitez-vous consulter ? <br>
      <div class="mt-2">
        <!--        <a class="nav-link-mobiyo"><i aria-hidden="true" class="fa-fr flag-consultant-details mr-2 search-flag-mobiyo flag-border languages_types" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="langTitle['FRLANG']"  id="FR"></i></a>-->
        <a class="nav-link-mobiyo"><i (click)="addFilter($event)" [ngbTooltip]="langTitle['EN']" aria-hidden="true"
            class="fa-en flag-consultant-details mr-5 search-flag-mobiyo languages_types" data-placement="top"
            data-toggle="tooltip" id="EN" tooltipClass="tooltip-available tlp-top"></i></a>
        <a class="nav-link-mobiyo"><i (click)="addFilter($event)" [ngbTooltip]="langTitle['ES']" aria-hidden="true"
            class="fa-es flag-consultant-details search-flag-mobiyo languages_types" data-placement="top"
            data-toggle="tooltip" id="ES" tooltipClass="tooltip-available tlp-top"></i></a>
      </div>
    </div>

  </div>

  <div *ngIf="servicetype === 'Audiotel'" class=" mt-5 ">
    <div class="row ">
      <div class="col-sm-8   mb-5">
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group datepickersStyle">
              <h2 class=" mx-5 text-center">{{ this.dayName.charAt(0).toUpperCase() + this.dayName.slice(1)}} le
                {{this.dayNumber}} {{this.monthName.charAt(0).toUpperCase() + this.monthName.slice(1)}} {{yearNumber}}
              </h2>
              <input class="form-control " placeholder="{{startDate}}" name="dp" [(ngModel)]="model" ngbDatepicker
                #d="ngbDatepicker" [minDate]="minDate" [maxDate]="maxDate" navigation="arrows" type="hidden"
                id="selectedDayId" [autoClose]="true" (ngModelChange)="getFilteredData()">
              <div class="input-group-append  mx-5">
                <button class="btn text-white bg-pink border-5" value="click" (click)="d.toggle();" type="button">
                  Sélectionner une autre journée</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div id="slider">

      <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" class="carousel">
        <div *ngFor="let item of timeFrames | slice:0; let i = index;" class="slide" ngxSlickItem
          style="width: 100% !important;">
          <div class="bg-pink text-white align-items-center slick-audiotel">
            <a class="text-white align-items-center" #timeFrames id="timeFrames" (click)="addFilter($event)"
              style="width: 100% !important; ">
              {{item}}
            </a>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-1 mt-1">

    <div class="col-md-12">
      <a (click)="showVoyanceType()" class="mr-2 nav-link nav-link-mobiyo active-link"
        style="font-size:18px;padding-left: 0px;">Recherche avancée <i class="fa fa-chevron-down"
          id="search-advanced-icon"></i></a>
    </div>
  </div>

  <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-2 mt-2 row-voyance-types"
    style="display: none">
    <div class="col-md-12 mb-4">
      Quel type de voyance souhaitez-vous obtenir ?
    </div>
  </div>

  <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-2 mt-2 row-voyance-types"
    id="row-voyance-types" style="display: none">
    <div class="col-md-12 text-center">
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo" id="tarologist_type"
        style=""><span>Tarot</span></a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="runes_type">Runes</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="astrologer_type">Astrologie</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="fortune_teller_type">Cartomancie</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="numerologist_type">Numérologie</a>
    </div>
  </div>

  <div class="row justify-content-center mb-5 mt-1 row-voyance-types" style="display: none">
    <div class="col-md-12 text-center">
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="psychic_seer_type">Voyant</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="pendulum_type">Pendule</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="medium_type">Medium</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="dowsing_type">Radiesthésiste</a>
      <a (click)="addFilter($event)"
        class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto"
        id="oracle_type">Oracles</a>
    </div>
  </div>


  <div class="row row-landscape ">
    <div *ngIf="listData?.length==0" class="col-md-12 text-center mt-5"><label class="label-title-mobiyo">Aucun
        consultant trouvé</label></div>

    <div *ngFor="let number of [1,2,3,4,5,6,7,8]" class="col-md-3">
      <ngx-content-loading *ngIf="loading" [height]="2000" [speed]="'1500ms'" [width]="1000">
        <svg:g height="1230" ngx-rect rx="10" ry="10" width="1000" x="70" y="70"></svg:g>
        <svg:g height="200" ngx-rect rx="100" ry="100" width="900" x="90" y="1350"></svg:g>
        <svg:g cx="100" cy="1650" ngx-circle r="50" ry="5"></svg:g>
        <svg:g height="100" ngx-rect rx="50" ry="50" width="800" x="170" y="1600"></svg:g>
        <svg:g cx="100" cy="1800" ngx-circle r="50" ry="5"></svg:g>
        <svg:g height="100" ngx-rect rx="50" ry="50" width="800" x="170" y="1750"></svg:g>
        <svg:g height="100" ngx-rect rx="50" ry="50" width="700" x="70" y="1900"></svg:g>
      </ngx-content-loading>
    </div>


    <div (mouseover)="mouseOverLang()" *ngFor="let consultant of listData "
      class="col-md-3 col-sm-3 col-xs-3 mt-3 col-sm-3-mobiyo">

      <div *ngIf="consultant.isvalidconsultant">
        <div class="card card-mobiyo card-Landscape">
          <div class="card-top card-top-mobiyo">
            <!-- statut-->
            <div *ngIf="consultant.statutCode === 0 || consultant.statutCode === 6 ; else elseBlock">
              <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status"  >
                <a class="text-white btn-details"
                  (click)="redirectToConsultant(consultant.consultantid)">{{consultant.statut}}</a>
              </div>
            </div>
            <ng-template #elseBlock>
              <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status " [ngStyle]="{'height':consultant.statutCode === -2 ? '33px' : 'auto'}">
                {{consultant.statut}}
              </div>
            </ng-template>
            <a (click)="redirectToConsultant(consultant.consultantid)" class="btn-details">
              <img alt="{{consultant.consultantlogin}}" class="card-img-top bg-light card-status"
                src="{{consultant.consultantpictureurl || '/assets/images/male.png'}}">
            </a>
          </div>

          <div class="bg-pink card-info">
            <div class="text-left card-top-lang">
              <span *ngFor="let lang of consultant.language" [ngbTooltip]="langTitle[lang]" data-placement="top"
                data-toggle="tooltip" tooltipClass="tooltip-available tlp-top">
                <i *ngIf="lang !== 'FR'" aria-hidden="true" class="fa-{{lang.toLocaleLowerCase()}}"></i>&nbsp;
              </span>
            </div>
            <div class="text-center card-top-pseudo">
              <a (click)="redirectToConsultant(consultant.consultantid)"
                class="text-white btn-details">{{consultant.consultantlogin}}</a>
            </div>
          </div>

          <div class="card-body">
            <audio class="audio-icon" controls="" controlsList="nodownload" src="{{consultant.consultantsoundurl}}"
              target=""></audio>
            <a   class="text-black btn-details">
              <p class="card-text mt-2 card-text-mobiyo" maxlength="0">
                {{consultant.consultantdescription?.length > 20 ? (consultant.consultantdescription | slice:0:70) +
                '...' : (consultant.presentation) }}
              </p>
            </a>
            <hr>
            <h4 class="card-title card-title-mobiyo">
              <span>
                <ngb-rating [starTemplate]="t" [readonly]="true" [max]="5" [rate]="consultant.reviewnote || 0"
                  class="text-warning"></ngb-rating>
                <span class="text-right card-review-number">
                  {{ consultant?.nbconsultation || 0 }} Consultation
                </span>
              </span>
              <a (click)="ModalConsultantReviews(consultant)" [ngClass]="{'disabled': !(consultant?.reviewnote > 0)}"
                [routerLink]="" class="rating-mobiyo review-link" queryParamsHandling="preserve"
                style="font-size: 11px;">Consulter les avis
                de {{consultant.consultantlogin}} ?</a>
            </h4>


          </div>
          <div class="card-footer p-0 card-footer-mobiyo">
            <h5 *ngIf="consultant.prestationSorted.length>0" class="text-center mt-2">Mes Prestations</h5>
            <div class="row row-cols-2  px-3 pb-2 align-items-stretch">
              <div *ngFor="let item of consultant.prestationSorted" class="col col-service px-2 mt-2">

                <!-- con-->
                <div (mouseover)="mouseOver(consultant.statutCode, item.active)" *ngIf="item.name ==='Conversation'"
                  [ngClass]="{
                  'service-available': item.active === 1 && consultant.statutCode === 0,
                  'service-busy': (consultant.statutCode === 1 ||  consultant.statutCode === 6) && item.active === 1,
                  'service-unavailable': item.active === 0
                }" class="rounded p-2" style="min-height: 52px;">
                  <ng-template #tootipConvContent>
                    <p *ngIf=" consultant.statutCode === 0 && item.active === 1 "> Je suis disponible en conversation
                      privée</p>
                    <p *ngIf="((consultant.statutCode === 1 || consultant.statutCode ===6 ) && item.active === 1) "> Je suis actuellement en
                      consultation </p>
                    <p *ngIf="item.active === 0"> Je ne suis pas disponible en conversation privée</p>


                  </ng-template>
                  <div *ngIf="activateLink; else elseBlock">
                      <div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center" placement="right"
                        tooltipClass="{{this.tooltipClass}}">
                        <span [ngClass]="{
                      'fa-conv-available': consultant.statutCode === 0,
                      'fa-conv-busy': consultant.statutCode === 1 ||  consultant.statutCode === 6,
                      'fa-conv-unavailable': item.active === 0
                    }" class="icon-service-type mr-1">
                        </span>
                        <span class="price-service-type text-nowrap">
                          {{consultant.conversationcost}}{{consultant.currency?.replace('EUR', '€')}}/min
                        </span>
                      </div>
                    </div>
                  <ng-template #elseBlock>
                    <div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center" placement="right"
                      tooltipClass="{{this.tooltipClass}}">
                      <span [ngClass]="{
                      'fa-conv-available': consultant.statutCode === 0,
                      'fa-conv-busy': consultant.statutCode === 1 ||  consultant.statutCode === 6 ,
                      'fa-conv-unavailable': item.active === 0
                    }" class="icon-service-type mr-1">
                      </span>
                      <span class="price-service-type text-nowrap">
                        {{consultant.conversationcost}}{{consultant.currency?.replace('EUR', '€')}}/min
                      </span>
                    </div>
                  </ng-template>

                </div>
                <!-- chat -->
                <div (mouseover)="mouseOver(consultant.statutCode , item.active )" *ngIf="item.name ==='Chat'"
                  [ngClass]="{
                 'service-available': item.active === 1  && ( consultant.statutCode === 0 ||  consultant.statutCode === 6) ,
                 'service-busy':(consultant.statutCode === 1 )&& item.active === 1,
                 'service-unavailable':item.active === 0
                }" class="rounded p-2" style="min-height: 52px;">
                  <ng-template #tootipChatContent>
                    <p *ngIf="(consultant.statutCode === 0 || consultant.statutCode === 6) && item.active === 1"> Je suis disponible en Chat</p>
                    <p *ngIf="consultant.statutCode === 1 && item.active === 1 "> Je suis actuellement en
                      consultation </p>
                    <p *ngIf="item.active === 0"> Je ne suis pas disponible en Chat</p>

                  </ng-template>
                  <div [ngbTooltip]="tootipChatContent" class="d-flex align-items-center" placement="right"
                    tooltipClass="{{this.tooltipClass}}">
                    <span [ngClass]="{
                      'fa-chat-available': consultant.statutCode === 0 ||  consultant.statutCode === 6,
                      'fa-chat-busy': consultant.statutCode === 1,
                      'fa-chat-unavailable': item.active === 0
                    }" class="icon-service-type mr-1"></span>
                    <span class="price-service-type ">{{consultant.chatcost}}{{consultant.currency?.replace('EUR',
                      '€')}}
                      /min</span>
                  </div>
                </div>
                <!---audiotel -->
                <div (mouseover)="mouseOver(consultant.statutCode , item.active , servicetype)"
                  *ngIf="item.name ==='Audiotel'" [ngClass]="{
                  'service-available': (item.active === 1  && consultant.statutCode === 0) || servicetype === 'Audiotel',
                  'service-busy':consultant.statutCode === 1 && item.active === 1,
                  'service-unavailable': (item.active === 0 && servicetype !== 'Audiotel' ) || (servicetype === 'Audiotel' && consultant.statutCode === -2 )
                }" class="rounded p-1 text-center h-100" style="min-height: 52px;">
                  <ng-template #tootipAudiotelContent>
                    <p *ngIf="(item.active === 1  && consultant.statutCode === 0) || (servicetype === 'Audiotel' && consultant.statutCode === 0)"> Je
                      suis disponible en Audiotel</p>
                    <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis actuellement en
                      consultation</p>
                    <p *ngIf="(item.active === 0 && servicetype !== 'Audiotel' ) || (servicetype === 'Audiotel' && consultant.statutCode === -2 ) "> Je ne suis pas disponible actuellement
                      en Audiotel</p>
                  </ng-template>

                  <div (click)="showSVAModal(activateLink)"
                    [ngClass]="{'btn': consultant.statutCode === 0 && item.active === 1  }"
                    [ngbTooltip]="tootipAudiotelContent" class="d-flex h-100 align-items-center" placement="right"
                    tooltipClass="{{this.tooltipClass}}">
                    <app-sva *ngIf="definitions | async" [definitions]="definitions | async" [full]="true"
                      [isLinkActive]="activateLink"
                      [ngClass]="{'ml-mobiyo': consultant.statutCode === 0 && item.active === 1 }" [zoom]="40"
                      class="mx-auto"></app-sva>
                  </div>


                </div>
                <!--mail-->
                <div (mouseover)="mouseOver(consultant.statutCode , item.active)" *ngIf="item.name === 'Mail'"
                  [ngClass]="{
                  'service-available': item.active === 1 && consultant.statutCode === 0,
                  'service-busy': consultant.statutCode === 1 && item.active === 1,
                  'service-unavailable': item.active === 0
                }" class="rounded p-2" style="min-height: 52px;">
                  <ng-template #tootipMailContent>
                    <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible en Mail</p>
                    <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis actuellement en
                      consultation </p>
                    <p *ngIf="item.active === 0 "> Je ne suis pas disponible en Mail</p>
                  </ng-template>
                  <div [ngbTooltip]="tootipMailContent" class="d-flex align-items-center" placement="right"
                    tooltipClass="{{this.tooltipClass}}">
                    <span [ngClass]="{
                      'fa-mail-available': item.active === 1 && consultant.statutCode === 0,
                      'fa-mail-busy':consultant.statutCode === 1 && item.active === 1,
                      'fa-mail-unavailable':item.active === 0
                    }" class="icon-service-type mr-1"></span>
                    <span class="price-service-type">{{consultant.mailcost}} {{consultant.currency?.replace('EUR',
                      '€')}}</span>
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>


      </div>
    </div>
  </div>
</div>

<!-- Modal consultant reviews -->

<div aria-hidden="false" aria-labelledby="ModalessageLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantReviews" role="dialog" tabindex="-1">
  <ng-template #t let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
    </span>
  </ng-template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{consultantInfo?.consultantlogin}}&nbsp;
          <ngb-rating [starTemplate]="t" [readonly]="true" [max]="5"  *ngIf="consultantInfo?.reviewnote>0" [rate]="consultantInfo?.reviewnote" class="text-warning">
          </ngb-rating>&nbsp;
          <span *ngIf="consultantInfo?.reviewcount>0">({{consultantInfo?.reviewcount}} avis)</span>
        </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="showAllNotice">
        <app-show-reviews *ngIf="consultantInfo" [consultantId]="consultantInfo.consultantid"
          [consultantImageUrl]="consultantInfo.consultantpictureurl" id="allNotices">
        </app-show-reviews>
      </div>
      <div *ngIf="showAddNotice" class="p-4">
        <app-add-review *ngIf="consultantInfo" [closeModal]=false [consultantId]="consultantInfo.consultantid"
          [consultantName]="consultantInfo.consultantlogin" [numberOfCall]=1 [redirect]=false id="addNotice">
        </app-add-review>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="false" aria-labelledby="modalConsultantReviewsErrorLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantReviewsErrorLabel">Les avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de visualiser
              les avis.</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalConsultantAddReviewsError  -->

<div aria-hidden="false" aria-labelledby="modalConsultantAddReviewsErrorLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantAddReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantAddReviewsErrorLabel">Donner son avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de prendre en
              compte votre avis !</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalShowAutioTel  -->
<div aria-hidden="false" aria-labelledby="modalShowAutioTelLabel" class="modal modalShowAutioTelDisplay fade"
  data-backdrop="static" id="modalShowAutioTel" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalShowAutioTelLabel">Conversations par Téléphone</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <p>Vous pouvez appeler votre consultant sur le {{(definitions | async)?.sva_number}}</p>
            <div class="text-center">
              <app-sva *ngIf="!!(definitions | async)" [definitions]="definitions | async" [zoom]="75"></app-sva>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalRefreshpage  -->
<div class="modal fade" id="refresh-page-modal" tabindex="-1" aria-labelledby="refresh-page-modal-label"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="refresh-page-modal-label">
          <span>Confirmation</span>
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row ml-5">
          <div class="col-2">
            <i class="fa fa-solid fa-bell faa-ring animated fa-3x text-warning"></i>
          </div>
          <div class="col-10 align-self-center">
            <p class="p-2 message-model-refresh"> Êtes-vous toujours là ? </p>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-accept" data-dismiss="modal"
          (click)="refreshPage()">Oui</button>
        <button type="button" class="btn btn-secondary btn-deny" data-dismiss="modal"
          (click)="hideRefreshModal()">Non</button>
      </div>
    </div>
  </div>
</div>

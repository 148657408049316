import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class PrivateConversationService {

    apiUrl = environment.apiUrlIvr;
    public selectedConvInfoSubject = new BehaviorSubject(null);
    sharedUser = this.selectedConvInfoSubject.asObservable();


  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

// launch call

  launchCall(data) {
    return this.http.post(`${this.apiUrl}/do-mer/?api-key=1e09ff763a52a9b464f521d4be2515fa`, data, {headers : new HttpHeaders({ 'Content-Type': 'text/plain' })});
  }

  getLiveStatus(dataSession) {

    return this.http.post(`${this.apiUrl}/get-session-status/?api-key=1e09ff763a52a9b464f521d4be2515fa`, dataSession, {headers : new HttpHeaders({ 'Content-Type': 'text/plain' })});

  }

  sendSMSAlert(merchantId, consultantId, absenceTime) {
    const params = new HttpParams({
      fromObject: {
        consultantId,
        merchantId,
        absenceTime
      }
    });
    return this.http.get(`${this.apiUrl}/alerte-absence-consultant/?api-key=1e09ff763a52a9b464f521d4be2515fa`, {params});
  }

  // extend call

  extendCall(data) {
    return this.http.post(`${this.apiUrl}/extend-call/?api-key=1e09ff763a52a9b464f521d4be2515fa`, data, {headers : new HttpHeaders({ 'Content-Type': 'text/plain' })});
  }

}

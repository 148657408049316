<br>
  <form [formGroup]="formPasswordResetRequest"  class="#" (submit)="onChangePassword()">
    <p>Nous allons vous envoyer un e-mail afin de réinitialiser votre mot de passe</p>
    <div class="row">
      <div class="col-sm-3">
    <label class="text-left">Veuillez saisir votre mail</label>
      </div>
      <div class="col-sm-1">
      </div>
      <div class="col-sm-5">
      <input
        type="email"
        formControlName="customerEmail"
        class="form-control"
        placeholder=""
        [ngClass]="{ 'is-invalid': f.customerEmail.errors  && !f.customerEmail.errors.required}"
        [ngClass]="{ 'is-valid': f.customerEmail.valid }"
        required>
      </div>
      <div class="col-sm-3">

        <div *ngIf="f.customerEmail.errors && !f.customerEmail.errors.required ">
          <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern " style="color: #ff0000">Votre
            mail est invalide
          </div>
        </div>

      </div>
    <div class="checkbox mb-3">
    </div>

    </div>
    <br>
    <div class="row">
      <div class="col-md-4">
      </div>
      <div class="col-md-5">
        <button class="btn  btn-success text-left btn-mobiyo" type="submit" [disabled]="!formPasswordResetRequest.valid" >
          Valider
          <span class="spinner-border spinner-border-sm align-self-center" role="status" aria-hidden="true" [hidden]="!submitting"></span>
        </button>
      </div>
    </div>
  </form>





import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ForfaitService } from '../../_services/forfait.service';
import { paidPack as PaidPackSchema } from '../../_models/paidPack'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { } from 'protractor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finish-payment',
  templateUrl: './finish-payment.component.html',
  styleUrls: ['./finish-payment.component.css']
})
export class FinishPaymentComponent implements OnInit {

  @Input() paidPack: PaidPackSchema = null;
  @Input() consultant: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  transactionDetails: any = {}
  private apiUrl = environment.apiUrlChat;
  private apiKey = environment.apiKey;
  message: String = null;
  paymentStatus: String = 'in_process'; // Possible values 'in_process', 'done' or 'failed'

  constructor(
    private forfaitService: ForfaitService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    // Mark conversation_status as paid once paid
    // Unpaid once created/reopened


    // Process the paiement
    this.getAmount()
  }


  closeConversation = () => {
    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);

    const payload = {
      'consultant_id': this.paidPack.consultant_id,
      'customer_id': this.paidPack.customer_id,
      'transaction_id': this.paidPack.transaction_id,
      'amount': this.paidPack.total_amount,
      'payment_status': 'paid',
    }
    this.message = 'Finalisation de traiement..'
    this.http.post(`${this.apiUrl}/close-conversation`, payload, {
      headers
    })
      .subscribe(
        ((response: any) => {
          if (response.status_code >= 400) {
            this.message = response.message
            this.paymentStatus = 'failed'
          } else {
            this.message = 'Resumé de votre conversation'
            this.paymentStatus = 'done'
            // Animation
            setTimeout(() => {
              /* [ Fade animation */
              // const pStatus: any = document.querySelector('.payment-status')
              // pStatus.classList.add('fadeIt')

              // const pStatuscAvatar: any = document.querySelector('.consultant-avatar')
              // pStatuscAvatar.classList.add('fadeIt')

              // const sMessage: any = document.querySelector('.status-message')
              // sMessage.classList.add('fadeIt')
              /* Fade animation ] */
            }, 100);
          }
        }),
        (error: any) => {
          console.error(error)
          this.message = 'Quelque chose s\'est mal passé lors de traitement de paiement!'
          this.paymentStatus = 'failed'
        }
      )
    this.transactionDetails = {}
  }

  getAmount = () => {
    const customerId = this.paidPack.customer_id
    const cardOneClickId = this.paidPack.payment_one_click_id
    const amount = this.paidPack.total_amount
    const authorizationId = this.paidPack.authorization_id
    const historyId = this.paidPack.transaction_id

    this.message = 'Traitement de paiement, Veulliez patientez..'
    this.forfaitService.getMoney(customerId, cardOneClickId, amount, authorizationId, historyId)
      .subscribe((response: any) => {
        if (response.statusCode < 0) {
          this.message = 'Une erreur s\'est produite lors de capture du montant!'
          this.paymentStatus = 'failed'
        } else {
          // Call close-conversation endpoint
          this.closeConversation()
        }
      })
  }

  getConversationSummary = (): { conversation_time: String, paid_amount: Number } => {
    return {
      conversation_time: this.formatConversationTime(Number(this.paidPack.service_duration)-Number(this.paidPack.remaining_time)),
      paid_amount: this.paidPack.total_amount,
    }
  }

  /**
   * Convert a huge number of seconds into hh:mm:ss
   * E.x: 7353 ==> 02:02:33
   * @returns String
   */
  formatConversationTime = (talkedTime: any) => {
    var carry: any = talkedTime;
    var h = 0;
    var m = 0;
    var s = 0;
    // Hours
    if (carry >= 3600) {
      carry = carry % 3600;
      h = (talkedTime - carry) / 3600;
    }

    // Minutes & Seconds
    if (carry >= 60) {
      s = carry % 60
      m = (carry - s) / 60;
    } else {
      s = carry
    }

    const hh = h > 9 ? h : `0${h}`;
    const mm = m > 9 ? m : `0${m}`;
    const ss = s > 9 ? s : `0${s}`;
    return hh > 0 ? `${hh}h ${mm}m ${ss}s` : `${mm}m ${ss}s`;
  }


  closeModal = () => {
    this.close.emit()
  }
}

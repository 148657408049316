<a href="#" data-toggle="modal" data-target="#cancel-account-modal">Résilier mon compte ?</a>
<div class="modal fade" id="cancel-account-modal" tabindex="-1" aria-labelledby="cancel-account-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cancel-account-modal-label">Résiliation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="step === 1">
        <p>Vous avez demandé à résilier votre compte {{infoDefinitions?.sms_brand}}.</p>
        <p>Voulez-vous vraiment le résilier ?</p>
      </div>
      <div class="modal-body" *ngIf="step === 2">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-check-circle fa-4x text-success"></i>
          </div>
          <div class="col-10 align-self-center">
            Votre demande de résiliation est prise en compte par {{infoDefinitions?.sms_brand}} !
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="step === 3">
        <p>Cette demande a déjà été effectuée le {{ cancelDate | date:'dd/MM/yyyy' }}</p>
      </div>
      <div class="modal-body" *ngIf="step === 4">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
          </div>
          <div class="col-10 align-self-center text-danger">
            Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
          </div>
        </div>
        <br>
        <p>
          Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}
        </p>
      </div>

      <div class="modal-footer" *ngIf="step === 1">
        <button type="button" class="btn btn-success col-5" data-dismiss="modal" [disabled]="submitting">Non</button>
        <button type="button" class="btn btn-danger" (click)="askDelete()" [disabled]="submitting">Oui</button>
      </div>
      <div class="modal-footer" *ngIf="step !== 1">
        <button type="button" class="btn btn-warning" data-dismiss="modal">Continuer</button>
      </div>
    </div>
  </div>
</div>

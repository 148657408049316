import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import {AddPaymentCardComponent} from './add-payment-card/add-payment-card.component';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    PaymentComponent,
    AddPaymentCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [PaymentComponent]
})
export class PaymentModule { }

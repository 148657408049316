import {Component, Input, OnInit} from '@angular/core';
import {PhoneService} from '../../../_services/phone.service';
import * as phoneUtil from 'google-libphonenumber';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DefinitionService} from '../../../_services/definition.service';
import {AccountService} from '../../../_services/account.service';
import {Definitions} from '../../../_models/definitions';
import {environment} from '../../../../environments/environment';
import {HomeService} from "../../../_services/home.service";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {ModalService} from "../../../_services/modal.service";
import { RegisterService } from '../../../_services/register.service';
declare var $: any;

@Component({
  selector: 'app-add-primary-phone',
  templateUrl: './add-primary-phone.component.html',
  styleUrls: ['./add-primary-phone.component.css']
})
export class AddPrimaryPhoneComponent implements OnInit {
  @Input() customerPrincipalCallNumber: any;
  @Input() customerPrincipalCallNumberValidated: any;
  @Input() customerPrincipalCallNumberType: any;
  @Input() infoDefinitions: Definitions;

  principalCallNumber: any;
  principalCallNumberLocalFormat: any;
  principalCallNumberCountry: any;
  principalCallNumberInterFormat: any;
  formAddPhone: FormGroup;
  formMobilePhoneCode: FormGroup;
  formWiredPhoneCode: FormGroup;

  isValidationSet = false;
  isPhoneRequireValidation = true;
  phoneNumber: string;
  openAddPhoneForm = true;

  openValidateFormMobile = false;
  openValidateFormFilaire = false;
  openModalSuccessAddPhone = false;
  openModalErrorMobilePhoneCodeNotFound = false;
  openModalErrorMobilePhone = false;
  openModalErrorAddPhone = false;
  openModalErrorWiredPhone = false;
  updatePhone = true;
  phoneValidationStep = false;
  usingSendethic: boolean = false;
  sendethicApiKey: string = '';
  customerId = JSON.parse(localStorage.getItem('customerId'));
  customerSessionId = localStorage.getItem('customerSessionId');
  data = {
    customerId: this.customerId,
    customerSessionId: this.customerSessionId,
    customerPhoneOrder: 'PRINCIPAL',
    deleteOlderCode: 1
  };
  selectedCountryCode: string;
  countryCodes: string[];
  countryCodesData: {};
  disabledUpdatePhoneBtn: boolean;
  submitting = false;
  SmsBrand: string;
  providerCallNumber: string;

  constructor(
    private phoneService: PhoneService,
    private fb: FormBuilder,
    private router: Router,
    private definitionService: DefinitionService,
    private accountService: AccountService,
    private homeService: HomeService,
    private customModal: ModalService,
    private registerservice: RegisterService

  ) {
    this.countryCodesData = {
      be: '+32',
      fr: '+33',
      lu: '+352',
      ch: '+41',
      ca: '+1',
    };
  }

  get f() {
    return this.formAddPhone.controls;
  }

  get fMobile() {
    return this.formMobilePhoneCode.controls;
  }

  get fWired() {
    return this.formWiredPhoneCode.controls;
  }

  ngOnInit(): void {
    this.outerRestForm();
    this.formAddPhone = this.fb.group({
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerPhoneOrder: 'PRINCIPAL',
      customerCallNumber: ['' , [Validators.required, Validators.pattern('^0[1-7,9][0-9]{8}$')]]
    });
    this.formMobilePhoneCode = this.fb.group({
      customerCallNumberCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]*')]]
    });
    this.formWiredPhoneCode = this.fb.group({
      customerCallNumberCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]*')]]
    });

    this.isPhoneRequireValidation = this.infoDefinitions.tel_validate;
    this.usingSendethic = this.infoDefinitions?.using_sendethic;
    this.sendethicApiKey = this.infoDefinitions?.sendethic_api_key;
    this.countryCodes = this.infoDefinitions.customer_countries.map(value => value.toLowerCase());
    this.SmsBrand = this.infoDefinitions?.sms_brand;
    this.providerCallNumber = this.infoDefinitions?.provider_call_number;
    this.selectedCountryCode = this.countryCodes[0] || 'fr';
  }

  formatedPrincipalCallNumber() {
    const phoneNumber = phoneUtil.PhoneNumberUtil.getInstance();

    if (this.customerPrincipalCallNumber) {
      const principalCallNumber = phoneNumber.parseAndKeepRawInput(this.customerPrincipalCallNumber, 'FR');
      this.principalCallNumberCountry = 'flag-icon-' + phoneNumber.getRegionCodeForNumber(principalCallNumber).toLowerCase();
      this.principalCallNumber = '0' + principalCallNumber.getNationalNumber();
      this.principalCallNumberLocalFormat = '0' + principalCallNumber.getNationalNumber();
      this.principalCallNumberInterFormat = '00' + principalCallNumber.getCountryCode() + '' + principalCallNumber.getNationalNumber();

    }
  }

  onSubmitAddPhone() {
    this.disabledUpdatePhoneBtn = true;
    const formData = this.formAddPhone.getRawValue();
    formData.customerCallNumber = this.countryCodesData[this.selectedCountryCode] + formData.customerCallNumber;
    if (this.formAddPhone.valid) {
      this.submitting = true;
      this.phoneService.updatePhone(formData).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.customerPrincipalCallNumberValidated = false;
            this.phoneNumber = result.principalCallNumber;
            this.accountService.update('principalCallNumber', result.principalCallNumber);
            this.accountService.update('isPrincipalCallNumberValidate', false);
            if (!this.isPhoneRequireValidation) {
              this.reset();
              this.openModalSuccessAddPhone = true;
              this.submitting = false;
              this.disabledUpdatePhoneBtn = false;
              this.reset();
              this.openModalSuccessAddPhone = true;

            } else {
              if (result.principalCallNumberType === 'MOBILE') {
                this.submitSMS();
                this.openValidateFormMobile = true;
                this.openAddPhoneForm = false;
                this.submitting = false;
                this.disabledUpdatePhoneBtn = false;
                this.customerPrincipalCallNumberType = 'MOBILE';
              }
              if (result.principalCallNumberType === 'FIXE') {
                this.ivrPlay();
                this.openValidateFormFilaire = true;
                this.openAddPhoneForm = false;
                this.submitting = false;
                this.disabledUpdatePhoneBtn = false;
                this.customerPrincipalCallNumberType = 'FIXE';

              }
              if (this.usingSendethic) {
                this.registerservice.addContactPhoneNumberInSendEthic(this.sendethicApiKey, localStorage.getItem('customerEmail'), this.phoneNumber.slice(2)).subscribe(
                  (result: any) => {
                    if (result.statusCode === undefined) {
                    } else {
                      console.log(result?.message);
                      console.log(result?.body);
                    }
                  }
                );
              }
            }
            this.customerPrincipalCallNumber = formData.customerCallNumber;

          } else if (result.statusCode === -22) {
            const title = 'Ajouter mon numéro de téléphone à appeler en priorité';
            const body = 'Ce numéro est déjà utilisé pour un autre compte, veuillez en renseigner un autre s\'il vous plaît.';
            this.open(title , body , [this.providerCallNumber , this.SmsBrand]);
            this.submitting = false;
            this.disabledUpdatePhoneBtn = false;
          } else {
            $('#ModalErrorAddPhone').modal('show');
            this.submitting = false;
            this.disabledUpdatePhoneBtn = false;
            this.reset();
            this.openModalErrorAddPhone = true;

          }
        },
        error => {
          $('#ModalErrorAddPhone').modal('show');
          this.submitting = false;
          this.disabledUpdatePhoneBtn = false;
          this.reset();
          this.openModalErrorAddPhone = true;
          console.log(error);
        }
      );
    }
  }

  onSubmitCheckCode() {
    const formData = this.formMobilePhoneCode.getRawValue();
    const data = {
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerCallNumberCode: JSON.parse(formData.customerCallNumberCode),
      deleteCode: 1
    };
    if (this.formMobilePhoneCode.valid) {
      this.phoneService.checkCode(data).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.accountService.update('isPrincipalCallNumberValidate', true);
            this.reset();
            this.openModalSuccessAddPhone = true;
            this.customerPrincipalCallNumberValidated = true;
            localStorage.setItem('customerCallNumber', this.phoneNumber);
          } else {
            if (result.statusCode === -17) {
              this.reset();
              this.openModalErrorMobilePhoneCodeNotFound = true;
            } else {
              this.reset();
              this.openModalErrorMobilePhone = true;

            }
          }
        },
        error => {
          this.reset();
          this.openModalErrorMobilePhone = true;
          console.log(error);
        }
      );
    } else {
      console.log('error');
    }
  }

  onSubmitWiredCheckCode() {
    const formData = this.formWiredPhoneCode.getRawValue();
    const data = {
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerCallNumberCode: JSON.parse(formData.customerCallNumberCode)
    };
    if (this.formWiredPhoneCode.valid) {
      this.phoneService.checkCode(data).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.accountService.update('isPrincipalCallNumberValidate', true);
            this.reset();
            this.openModalSuccessAddPhone = true;
            this.customerPrincipalCallNumberValidated = true;

          } else {
            if (result.description === 'Code not found for this customer') {
             // $('#ModalErrorMobilePhoneCodeNotFound').modal('show');
              this.reset();
              this.openModalErrorMobilePhoneCodeNotFound = true;


            } else {
              this.reset();
              this.openModalErrorWiredPhone = true;
              this.phoneValidationStep = true;

            }
          }
        },
        error => {
          this.reset();
          this.openModalErrorWiredPhone = true;
          this.phoneValidationStep = true;
          console.log(error);
        }
      );
    } else {
      console.log('error');
    }
  }

  submitSMS() {
    this.phoneService.createCode(this.data).subscribe(
      (res: any) => {
        const smsData = {
          merchantId: environment.merchantId,
          customerCallNumber: this.principalCallNumberInterFormat,
          customerCallNumberCode: res.customerCallNumberCode,
        };
        this.phoneService.sendSMSCode(smsData).subscribe(
          (smsres: any) => {
          },
          error => {
            this.reset();
            this.openModalErrorMobilePhone = true;
            this.phoneValidationStep = true;


          }
        );
      },
      error => {
        console.log(error);
        this.reset();
        this.openModalErrorMobilePhone = true;
        this.phoneValidationStep = true;

      }
    );
  }

  changeCodeNumber($event) {
    this.selectedCountryCode = $event;
    if (this.f.customerCallNumber.validator) {
      this.isValidationSet = true;
    }

    if (this.selectedCountryCode === 'be') {
      this.formAddPhone = this.fb.group({
          customerId: this.customerId,
          customerSessionId: this.customerSessionId,
          customerPhoneOrder: 'PRINCIPAL',
          customerCallNumber: ['' , [Validators.required, Validators.pattern('' +
            '(^01[0-6][0-9]{6}$)|' +
            '(^019[0-9]{6}$)|' +
            '(^02[2-8][0-9]{6}$)|' +
            '(^03[2-8][0-9]{6}$)|' +
            '(^04[2-3][0-9]{6}$)|' +
            '(^05[0-9]{7}$)|' +
            '(^06[0-1][0-9]{6}$)|' +
            '(^06[3-5][0-9]{6}$)|' +
            '(^06[7-9][0-9]{6}$)|' +
            '(^071[0-9]{6}$)|' +
            '(^08[1-7][0-9]{6}$)|' +
            '(^089[0-9]{6}$)|' +
            '(^09[2-4][0-9]{6}$)')]]
        });
    } else {
      this.formAddPhone = this.fb.group({
        customerId: this.customerId,
        customerSessionId: this.customerSessionId,
        customerPhoneOrder: 'PRINCIPAL',
        customerCallNumber: ['' , [Validators.required, Validators.pattern('^0[1-7,9][0-9]{8}$')]]
      });
    }

  }

  resetToProfiePage() {
    this.homeService.redirect('/');
  }

  outerRestForm() {
    document.addEventListener('click', e => {
      const target = e.target as HTMLTextAreaElement;
      if (target.className === 'modal fade') {
        this.resetToProfiePage();
      }
    }, false);
  }

  ivrPlay() {
    this.phoneService.createCode(this.data).subscribe(
      (res: any) => {
        const callData = {
          merchantId: environment.merchantId,
          merchantCallNumber: this.infoDefinitions.provider_call_number,
          customerCallNumber: this.principalCallNumberInterFormat,
          customerCallNumberCode: res.customerCallNumberCode,
        };
        this.phoneService.ivrPlayCode(callData).subscribe(
          (callRes: any) => {
          },
          error => {
            this.reset();
            this.openModalErrorWiredPhone = true;

          }
        );
      },
      error => {
        console.log(error);
        this.reset();
        this.openModalErrorWiredPhone = true;

      }
    );

  }



  changeNumber() {
    this.openValidateFormMobile = false;
    this.openAddPhoneForm = true;
  }

  reset() {
    this.openModalErrorMobilePhone = false;
    this.openValidateFormMobile = false;
    this.openAddPhoneForm = false;
    this.openModalErrorAddPhone = false;
    this.openModalErrorWiredPhone = false;
    this.openModalErrorMobilePhoneCodeNotFound = false;
    this.openModalSuccessAddPhone = false;
    this.openValidateFormFilaire = false;
    this.phoneValidationStep = false;
    this.updatePhone = true;
  }

  openModalPrincipalPhoneNumber() {
    this.reset();
    this.openAddPhoneForm = true;
  }

  openModalCheckCode() {
    this.reset();
    if (this.customerPrincipalCallNumberType === 'FIXE') {
      this.openValidateFormFilaire = true;
      this.formWiredPhoneCode.patchValue({customerCallNumberCode: ''});
    } else {
      this.openValidateFormMobile = true;
      this.formMobilePhoneCode.patchValue({customerCallNumberCode: ''});
    }
  }

  phoneValidation() {
    this.reset();
    this.updatePhone = false;
    this.phoneValidationStep = true;
    if (this.customerPrincipalCallNumberType === 'MOBILE') {
      this.submitSMS();
      this.openValidateFormMobile = true;
      this.openAddPhoneForm = false;
      this.submitting = false;
      this.disabledUpdatePhoneBtn = false;
      this.formMobilePhoneCode.patchValue({customerCallNumberCode: ''});
    }
    if (this.customerPrincipalCallNumberType === 'FIXE') {
      this.ivrPlay();
      this.openValidateFormFilaire = true;
      this.openAddPhoneForm = false;
      this.submitting = false;
      this.disabledUpdatePhoneBtn = false;
      this.formWiredPhoneCode.patchValue({customerCallNumberCode: ''});
    }
  }

  /**
   * @param isWiredPhone
   */
  checkcode(isWiredPhone = false) {
      this.onSubmitAddPhone();
      this.phoneService.sendCheckCode(isWiredPhone);
  }

  open(title: string , body: string , params: [string , string]) {
    this.customModal.showDefaultModalComponent(ModalComponent, title , body  , params  );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../_services/login.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionService } from '../../_services/session.service';
import { HomeService } from '../../_services/home.service';
import { PromotionService } from '../../_services/promotion.service';
import { Definitions } from '../../_models/definitions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { DefinitionService } from '../../_services/definition.service';
import { RegisterService } from '../../_services/register.service';

declare var $: any;

import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hasError = false;
  form: FormGroup;
  definitions: Observable<Definitions>;

  usingSendethic: boolean = false;
  sendethicApiKey: string = '';
  infoDefinitions: any;
  control: AbstractControl;
  @Input() IsValid: Observable<boolean>;

  fbLoggedIn: boolean = false;
  user: any;
  loginFailedMessage: string = null
  loginFailedErrorMessages: Object = {
    1: 'Nous n\'etions pas autorisé à récupérer vos données publiques tel que l\'adresse email et votre nom.'
  }

  autheticationActiveSection = 'signin'
  showPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private registerservice: RegisterService,
    private definitionService: DefinitionService,
    private loginservice: LoginService,
    private sessionService: SessionService,
    private homeService: HomeService,
    private store: Store<AppState>,
    private authService: SocialAuthService,
  ) {
    this.definitions = store.select('definitions');
    this.definitions.subscribe((definitions) => {
      this.usingSendethic = definitions?.using_sendethic;
      this.sendethicApiKey = definitions?.sendethic_api_key;
      this.infoDefinitions = definitions;
    });
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Set the user and proceed our internal sign in process
   * @param user SocialUser
   */
  async proceedFBLogin(user: SocialUser) {
    let checkaccount = await this.checkaccount(user.email);
    this.loginFailedMessage = null
    this.user = user;
    this.fbLoggedIn = true;

    // Login plateform provider
    this.form.addControl('loginProvider', new FormControl('facebook'));
    // Append client's info to the form which will be submitted
    Object.keys(user).map(key => {
      if (!['email', 'firstName', 'lastName', 'name', 'photoUrl', 'authToken'].includes(key)) return
      if (key === 'email') {
        this.form.get('customerEmail').setValue(user.email);
      } else
        this.form.addControl(key, new FormControl(user[key]));
    })

    const currentRoute = location.pathname;
    const lastItemPath = currentRoute.substring(currentRoute.lastIndexOf('/') + 1);
    console.log(user.email);
   
    this.loginservice.login(this.form.value, true).subscribe(
      (result: any) => {
       
        if (result.statusCode === undefined) {
        
         if (this.usingSendethic) {
        
           this.registerservice.createContactInSendethic(this.sendethicApiKey, user.email)
            .subscribe(
              (result: any) => {
                if (result.statusCode === undefined) {
                  this.registerservice.addContactFirstnameInSendEthic(this.sendethicApiKey, user.email, user.firstName).subscribe(
                    (result: any) => {
                      if (result.statusCode === undefined) {
                        this.registerservice.addContactLastnameInSendEthic(this.sendethicApiKey, user.email, user.lastName).subscribe(
                          (result: any) => {
                            if (result.statusCode === undefined) {
                            } else {
                            }
                          }
                        );
                      } else {
                        console.log(result?.message);
                        console.log(result?.body);
                      }
                    }
                  );
                  console.log('contact created');
                }
                else {
                  console.error(result?.body);
                }
              }
            );
        }
          localStorage.setItem('customerSessionId', result.customerSessionId);
          localStorage.setItem('customerId', JSON.stringify(result.customerId));
          this.loginservice.loggedIn.next(true);
          this.sessionService.login(result.customerSessionId, result.customerId);
          this.loginservice.getUserInfo(result.customerSessionId, result.customerId);
          if(!checkaccount){
            this.newAccountFacebookMerchantNotification(user.email);
          }
          if (['login', 'login.html','inscription'].includes(lastItemPath)) {
            this.homeService.redirect('/');
          }
        } else {
          this.hasError = true;
        }
      },
      (error: any) => {
        // console.log(error)
      });
  }

  signOut(): void {
    this.authService.signOut();
  }

  triggerFacebookLogin() {
    // Retrieve the user if he's still logged in
    (new FacebookLoginProvider('1218504692238124')).getLoginStatus().then(user => {
console.log('trigger facebook ');
      // Refresh the logged in user
      this.proceedFBLogin(user)
    })
      .catch(err => {
        console.log('err open ');
        // Proceed login with facebook
        this.openFacebookLogin()
      })
  }

  /**
   * Open up facebook's login interface
   * @returns void
   */
  openFacebookLogin(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(user => {
        console.log(' open ',user);
        // Proceed internal signin in
        this.proceedFBLogin(user)

      })
      .catch(err => {
        // Display errors
        location.href = '/?socialLoginFailed=fb&loginFailedErrorCode=1'
        console.log('FB returned error: ', err)
      })
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerEmail: ['', [Validators.required, Validators.email,
      Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}')]
      ],
      customerPassword: ['', [Validators.required]],
    });

    document.addEventListener("DOMContentLoaded", () => {
      const togglePassword = document.querySelector<HTMLElement>('#togglePassword');
      const password = document.querySelector('#inputPassword');
      if (togglePassword) {
        console.log('from toggle password')
        togglePassword.addEventListener('click', function (e) {
          // toggle the type attribute
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
          // toggle the eye / eye slash icon
          if (this.classList.contains('fa-eye')) {
            this.classList.remove('fa-eye');
            this.classList.add('fa-eye-slash');
          } else {
            this.classList.remove('fa-eye-slash');
            this.classList.add('fa-eye');
          }
        });
      }
    });


    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    this.loginFailedMessage = this.loginFailedErrorMessages[searchParams.get('loginFailedErrorCode')];
    if (searchParams.get('socialLoginFailed') == 'fb') {
      this.autheticationActiveSection = 'signin'
    }
  }

  onSubmit() {
    const currentRoute = window.location.pathname;
    const lastItemPath = currentRoute.substring(currentRoute.lastIndexOf('/') + 1);
    const location = ['login', 'login.html','inscription'];
    if (this.form.valid) {
      this.loginservice.login(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            localStorage.setItem('customerSessionId', result.customerSessionId);
            localStorage.setItem('customerId', JSON.stringify(result.customerId));
            this.loginservice.loggedIn.next(true);
            this.sessionService.login(result.customerSessionId, result.customerId);
            this.loginservice.getUserInfo(result.customerSessionId, result.customerId);
            if (location.includes(lastItemPath)) {
              this.homeService.redirect('/');
            }
          } else {
            this.hasError = true;
          }
        },
        (error: any) => {
          console.log('error');
        });
    } else {
      console.log('Form not Valid');
    }
  }

  closeLoginModal() {
    $('#loginModal').modal('hide');
    $('.modal-backdrop').remove();
    $('#resetPasswordModal').modal('show');
  }

  openRegisterModal() {
    $('#loginModal').modal('hide');
    $('.modal-backdrop').remove();
    $('#registerModal').modal('show');

  }

  clearError() {
    this.hasError = false;
  }


  /**
   * Switch between signin and signup forms
   * @param string value 
   */
  switchAuth(value: string) {
    this.autheticationActiveSection = value
  }

  showpasswordInput() {
    this.showPassword = !this.showPassword;

  }

 async checkaccount(email){
    let existaccount :boolean = true;
     const result:any = await this.registerservice.checkEmail(email).toPromise();
     existaccount = result.emailExists;
     console.log(result);
     console.log(existaccount);
    return existaccount;
  }

  newAccountFacebookMerchantNotification(customerEmail) {

    const newDate = new Date();

    const date = ('0' + Number(newDate.getDate())).slice(-2) + '/' + ('0' + (Number(newDate.getMonth()) + 1)).slice(-2) + '/' + newDate.getFullYear();
    const hour = (Number(newDate.getHours())) + ':' + (Number(newDate.getMinutes()));
    console.log('send mail , customer email : ', customerEmail);

    const dataMailSend = {
      merchantId: environment.merchantId,
      recipientMail: 'h.jaafari@pixper.net',
      templateParams:
      {
        creationDate: date,
        creationHour: hour,
        customerEmail: customerEmail
      },
      template: 'url_template_new_account_merchant_notification',
      subject: 'Création d\'un nouveau compte',
    };

    this.registerservice.sendEmail(dataMailSend).subscribe();

  }

}

import { Action } from '@ngrx/store';
import { Promotion } from '../_models/promotion';

export const SET_PROMOTIONS = '[PROMOTION] Set';
export const DELETE_PROMOTIONS = '[PROMOTION] Delete';

export class SetPromotions implements Action {
  readonly type = SET_PROMOTIONS;

  constructor(public payload: Promotion[]) {}
}

export class DeletePromotions implements Action {
  readonly type = DELETE_PROMOTIONS;

  constructor() {}
}

export type Actions = SetPromotions | DeletePromotions;

<div (click)="showSVModal()" *ngIf="svaNumber && svaTarif  && !isFixedNumber" [ngClass]="{'full': full}"
     class="sva-increased zoom{{zoom}}">
  <a class="text-pink" href="tel:{{ svaNumber }}">{{ svaNumber }}</a>
  <a [innerHTML]="svaTarif" class="text-sva" href="tel:{{ svaNumber }}"></a>
</div>


<a *ngIf="svaNumber && isFixedNumber" href="tel:{{ svaNumber }}" class="vcard numerovert">
  <span class="fn">Nom de la société</span>
  <span class="tel">
      <span class="type"><i aria-hidden="true" class="fa fa-phone"></i>
  </span> 
       <span class="value">{{svaNumber}}</span>
  </span>
</a>


<div class="">
  <div class="flex switch-auth-methods">
    <button [ngClass]="autheticationActiveSection=='signin' ? 'active' : ''" (click)="switchAuth('signin')">Déjà membre ?</button>
    <button [ngClass]="autheticationActiveSection=='signup' ? 'active' : ''" (click)="switchAuth('signup')">Nouveau client</button>
  </div>


  <!-- Signin -->
  <div *ngIf="autheticationActiveSection=='signin'" class="row">
    <!-- Regular auth -->
    <div class="col-7 col-7-mobiyo border-right py-4">
      <form class="form-horizontal" [formGroup]="form" (submit)="onSubmit()" id="formlogin"  >
        <div class="row">
          <label for="inputcustomerEmail" class="col-sm-4">Votre email </label>
          <div class="col-sm-8">
            <input
              type="email"
              id="inputcustomerEmail"
              formControlName="customerEmail"
              class="form-control"
              [class.is-invalid]="(f.customerEmail.errors && (f.customerEmail.errors.email || f.customerEmail.errors.pattern) ) || hasError == true "
              [ngClass]="{ 'is-valid': f.customerEmail.valid }"
              (click)="clearError()"
              aria-describedby="emailInvalidLogin"
            >
            <div
              *ngIf="f.customerEmail.errors?.email || f.customerEmail.errors?.pattern"
              id="emailInvalidLogin"
              class="invalid-email-mobiyo invalid-feedback"
            >
              Votre mail est invalide
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <label for="inputPassword" class="col-sm-4">Votre mot de passe</label>
          <div class="col-sm-8">
            <p>
              <input
              [type]="showPassword ? 'text' : 'password'"
                id="inputPassword"
                formControlName="customerPassword"
                class="form-control "
                [class.is-invalid]="hasError"
                [ngClass]="{ 'is-valid': f.customerPassword.valid }"
                (click)="clearError()"
                aria-describedby="unknownEmail"
              ><i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"  id="passwordIconEye" (click)="showpasswordInput()"></i>
  
            </p>
  
            <div *ngIf="hasError" id="unknownEmail" class="unkown-email-pwd-mobiyo invalid-feedback" style="display: block !important;">
              Utilisateur introuvable, adresse e-mail ou mot de passe incorrect
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-8 offset-sm-4">
            <button class="btn btn-success btn-block  btn-mobiyo " type="submit" [disabled]="!form.valid || hasError">Valider</button>
          </div>
        </div>
      </form>
  
      
      <div class="row mt-4">
        <a
          data-toggle="modal"
          data-target="#resetPasswordModal"
          class="text-center btn-link col nav-link-mobiyo"
          (click)="closeLoginModal()"
        >
          Ré-initialiser le mot de passe ici
        </a>
      </div>
      <div class="row justify-content-center">
        <label  style="font-size: 0.9rem;">Mot de passe oublié ?</label></div>
    </div>

    <!-- Social networks auth -->
    <div class="social-networks-auth-container col pl-3" style="padding-top: 1.3em;">
      <p style="font-size:16px;">Ou s'authentifier avec</p>
      <a class="fb-login-btn" href="javascript:;" (click)="triggerFacebookLogin()">
        <div class="rectangular btn-facebook-style">
          <svg height="35" width="35" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M512,256c0,-141.385 -114.615,-256 -256,-256c-141.385,0 -256,114.615 -256,256c0,127.777 93.616,233.685 216,252.89l0,-178.89l-65,0l0,-74l65,0l0,-56.4c0,-64.16 38.219,-99.6 96.695,-99.6c28.009,0 57.305,5 57.305,5l0,63l-32.281,0c-31.801,0 -41.719,19.733 -41.719,39.978l0,48.022l71,0l-11.35,74l-59.65,0l0,178.89c122.385,-19.205 216,-125.113 216,-252.89Z" style="fill:#1877f2;fill-rule:nonzero;"/><path d="M355.65,330l11.35,-74l-71,0l0,-48.022c0,-20.245 9.917,-39.978 41.719,-39.978l32.281,0l0,-63c0,0 -29.297,-5 -57.305,-5c-58.476,0 -96.695,35.44 -96.695,99.6l0,56.4l-65,0l0,74l65,0l0,178.89c13.033,2.045 26.392,3.11 40,3.11c13.608,0 26.966,-1.065 40,-3.11l0,-178.89l59.65,0Z" style="fill:#fff;fill-rule:nonzero;"/></g></svg>
        </div>
      </a>
      <p *ngIf="loginFailedMessage" class="social-networks-auth-failed">
        {{ loginFailedMessage }}
      </p>

      
    </div>
  </div>

  <!-- Signup -->
  <div *ngIf="autheticationActiveSection=='signup'" class="signup-container">
    <app-register></app-register>
  </div>

</div>  


import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ModalService {
  constructor(
    private ngbModal: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    config: NgbModalConfig,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  showDefaultModalComponent(theComponent: any, name: any, body: any, params: [string , string], size = 'xl') {
    const componenetFactory = this.componentFactoryResolver.resolveComponentFactory(
      theComponent
    );
    const modalRef = this.ngbModal.open(theComponent, {size: size});
    modalRef.componentInstance.title = name;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.params = params;
    return modalRef;
  }
}

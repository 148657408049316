import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import { element } from 'protractor';
import { LoginGuard } from './login.guard';
import { CryptoService } from "./crypto.service";
import { ChatService } from '../_services/chat.service';


declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  public consultantId = new BehaviorSubject(localStorage.getItem('selectedConsultantId'));
  public consultantCallNumber = new BehaviorSubject(localStorage.getItem('consultantCallNumber'));
  public consultantName = new BehaviorSubject(localStorage.getItem('consultantName'));
  public consultantCost = new BehaviorSubject(localStorage.getItem('cc'));

  private apiUrl = environment.apiUrlConsultant;
  private apiKey = environment.apiKey;
  private apiUrlChat = environment.apiUrlChat;

  constructor(
    private router: Router,
    private http: HttpClient,
    private chatService: ChatService,
    private cryptoService: CryptoService
  ) {
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
    dayjs.extend(objectSupport);
  }

  sendGetConsutalnt(merchantId, officeId, timeZoneClient) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        timeZoneClient
      }
    });
    return this.http.get(`${this.apiUrl}/get-consultant`, { params: params, headers: header });
  }

  sendGetConsutalntByID(merchantId, officeId, consultantId, timeZoneClient) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        consultantId,
        timeZoneClient
      }
    });
    return this.http.get(`${this.apiUrl}/get-consultant`, { params: params, headers: header });
  }

  getConsultant(consultantId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        consultantId,
      }
    });

    return this.http.get(`${this.apiUrl}/get-consultant-by-id`, { params: params, headers: header });
  }

  sendGetPlaning(merchantId, officeId, consultantId, planningDateStart, planningDateEnd, timeZoneClient) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        consultantId,
        planningDateStart,
        planningDateEnd,
        timeZoneClient
      }
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/text');
    return this.http.get(`${this.apiUrl}/get-consultant`, { params: params, headers: header });
  }

  formatTime(date) {
    if (date.format('HH:mm') === '00:00') {
      return 'minuit';
    }
    if (date.format('HH:mm') === '12:00') {
      return 'midi';
    }
    return date.format('mm') > 0 ? date.format('H:mm') : date.format('H') + 'H';
  }

    handle(data, isFilterSet = false, serviceType = 'all') {
    let date = null;
    let planning = null;
    let statut = 'ABSENT';
    let isPlannedForToday = false;
    let colorClass = 'bg-secondary';
    let statutCode = -1;
    let service = null;
    let startHour = [];
    let result;
    let framesDetails = [];
    let planningDays = [];
    let currentService = [];
    const costs = ([data.conversationcost, data.chatcost, data.mailcost]).filter(c => c !== null);
    const prestations = data.prestations.map(pres => ({
      name: pres,
      active: 0
    }));
    let days = Object.keys(data.planning);
    result = this.getServiceStartHour(data.planning);
    startHour = result.startHour;
    framesDetails = result.framesDetails;
    planningDays = result.formatedElements;
    if (days.length > 0) {

      // trier les jours
      days = days.sort((a, b) => dayjs(a) < dayjs(b) ? -1 : 1);
      const day = days[0];
      let plannings = data.planning[day];
      plannings = plannings.sort((a, b) => dayjs(day + ' ' + a.startHour) < dayjs(day + ' ' + b.startHour) ? -1 : 1);
      const filtredPlanning = plannings.filter(item => item.serviceType.indexOf('cb') >= 0);
      if (plannings.length > 0) {
        planning = plannings[0];
        date = day + ' ' + planning.startHour;
        if (planning.endHour === '00:00:00') {
          planning.endHour = '24:00:00';
        }
      }
      if (filtredPlanning.length > 0 && serviceType === 'Conversation') {
        planning = filtredPlanning[0];
        date = day + ' ' + planning.startHour;
        if (planning.endHour === '00:00:00') {
          planning.endHour = '24:00:00';
        }
      }

      plannings.find(item => {
        if (item.startHour === planning.startHour)
          currentService.push(item.serviceType[0]);

      } );
      planning.serviceType = currentService ;
      console.log(planning);

    } else if (data.returndate != null) {
      date = data.returndate;
    }

    if (date) {
      const now = dayjs();
      // vérifier s'il est planifié en ce moment
      if (planning &&
        dayjs(date).isBefore(now) &&
        dayjs(date.replace(planning.startHour, planning.endHour)).isAfter(now)
      ) {
          if ((((planning.serviceType.indexOf('audiotel') >= 0 || data.consultantstate === 1) && (data.consultantavailable)) || (planning.serviceType.indexOf('audiotel') >= 0 && data.consultantstate === 5) || (planning.serviceType.indexOf('audiotel') >= 0 && data.consultantstate === 2  && !data.consultantavailable) ) && (data.consultantstate !== 6)) {
            statut = 'JE CONSULTE IMMEDIATEMENT';
            colorClass = 'bg-success';
            statutCode = 0;
            isPlannedForToday = true;
          }
          else {
            statut = 'EN CONSULTATION';
            colorClass = 'bg-warning';
            statutCode = 1;
            isPlannedForToday = true;
          }
          console.log(serviceType);

          if(data.consultantstate === 6 &&  data.consultantavailable){
            statut = 'JE CONSULTE IMMEDIATEMENT';
            colorClass = 'bg-success';
            statutCode = 6;
            isPlannedForToday = true;
          }


          // active service type (prestation)
          if (serviceType !== 'Audiotel') {
            planning.serviceType.forEach(type => {
              const presta = prestations.find(p => p.name.toLowerCase() === (type.toLowerCase() === 'cb' ? 'conversation' : type.toLowerCase()));
              if (presta) {
                service = presta.name;
                presta.active = 1;
              }
            });
          }
          else {
            service = result.activeService;
          }
        } else if (dayjs(date) > dayjs().add(30, 'day')) {
          // absent si date de retour > 30 jours
          statut = 'ABSENT';
          date = null;
          isPlannedForToday = false;
          console.log('statut absent');

        }
        else if (dayjs(date).isAfter(now)) {

          statut = 'DE RETOUR ';
          statutCode = 2;

          // si aujourd'hui
          if (dayjs(date).format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
            isPlannedForToday = true;
            service = this.getServiceName(planning, startHour);
          } else if (dayjs(date).format('YYYY-MM-DD') === dayjs().add(1, 'day').format('YYYY-MM-DD')) {
            statut += 'demain à ' + this.formatTime(dayjs(date));
            isPlannedForToday = false;
            service = this.getServiceName(planning, startHour);
          } else {
            statut += 'Le ' + dayjs(date).format('DD MMMM');
            isPlannedForToday = false;
            service = this.getServiceName(planning, startHour);
          }
          if (isFilterSet && serviceType === 'Audiotel') {
            statut = 'JE CONSULTE IMMEDIATEMENT';
            colorClass = 'bg-success';
            statutCode = 0;
            service = result.activeService;
          } else {
            if (!statut.includes('à')) {
              statut += ' à ' + this.formatTime(dayjs(date));

            }
          }
        }
      }
      const sortServices = ['Conversation', 'Audiotel', 'Chat', 'Mail'];
      return {
        statut,
        colorClass,
        statutCode,
        date,
        service,
        isPlannedForToday,
        startHour,
        framesDetails,
        planningDays,
        cost: costs.length > 0 ? costs[0] + ' ' + data.currency.replace('EUR', '€') + ' / MIN' : '',
        prestations: prestations.sort((a, b) => sortServices.indexOf(a.name) > sortServices.indexOf(b.name) ? 1 : -1),
        currentService: currentService
      }
    }

    handleplaningDate(plannings) {
      const planning = plannings;
      for (const date in plannings) {
        let consulations = plannings[date];
        let firstStartHour = '';
        consulations.forEach((consultation, index) => {
          const startHour = parseInt(consultation.startHour.split(':')[0], 10);
          const endHour = parseInt(consultation.endHour.split(':')[0], 10);
          let starthourConsult = dayjs(new Date(0, 0, 0, startHour)).format('H');
          let endHourConsult = dayjs(new Date(0, 0, 0, endHour)).format('H');

          let startText = '';

          if (dayjs(date).isToday() && starthourConsult === dayjs().format('H')) {
            starthourConsult = 'Maintenant';
            startText = 'Maintenant';
          } else if (starthourConsult === '0') {
            starthourConsult = 'minuit';
            startText = 'De minuit';
          } else if (starthourConsult === '12') {
            starthourConsult = 'midi';
            startText = 'De midi';
          } else {
            starthourConsult += 'H';
            startText = 'De ' + starthourConsult;
          }
          if (endHourConsult === '0') {
            endHourConsult = 'minuit';
          } else if (endHourConsult === '12') {
            endHourConsult = 'midi';
          } else {
            endHourConsult += 'H';
          }

          planning[date][index].hourText = {
            from: startText,
            to: `à ${endHourConsult}`
          };

          if (index === 0) {
            firstStartHour = starthourConsult;
          }

        });
        if (dayjs(date).isTomorrow() && firstStartHour !== '') {
          const yesterdayDate = dayjs(date).add(-1, 'day').format('YYYY-MM-DD');
          if (planning[yesterdayDate] === undefined) {
            planning[yesterdayDate] = [{
              hourText: {
                from: 'Ce consultant est de retour demain à ' + firstStartHour
              }
            }];
          }
        }
        consulations = this.sortArrayOfObject(consulations);
      }
      return planning;
    }

    showSVAModalAction(activateLink) {
      if (activateLink) {
        const width = $(window).width();
        if (width < 768) {
          $('#modalShowAutioTel').modal('hide');
        } else {
          $('#modalShowAutioTel').modal('show');
        }
      }
    }

    /**
     *
     * @param planning
     */
    getServiceName(planning, startHour) {
      let service;
      if (planning) {
        planning.serviceType.forEach(name => {
          switch (name) {
            case 'chat':
              service = 'Chat';
              break;
            case 'audiotel':
              service = 'Audiotel';
              break;
            case 'cb':
              service = 'Conversation';
              break;
          }

        });
        return service;
      }
    }

    /**
     *
     * @param planning
     */
    getServiceStartHour(planning) {
      let startHour = [];
      let activeService;
      let days = Object.keys(planning);
      let framesDetails = [];
      days = days.sort((a, b) => dayjs(a) < dayjs(b) ? -1 : 1);
      const day = days[0];
      const elments = [];
      // tslint:disable-next-line:forin
      for (const property in planning) {
        elments[property] = [];
        elments[property].push(planning[property]);
      }

      for (const property in elments) {
        elments[property].forEach(els => {
          startHour = [];
          els.forEach(el => {
            if (el.serviceType.includes('audiotel')) {
              framesDetails.push(el.frames);
              el.frames.forEach(elementFrame => {
                startHour.push(elementFrame.startHourFrame.substring(0, 2) + 'h');
                elementFrame.day = property;
                activeService = el.serviceType[0];
                if (elementFrame.endHourFrame === '23:59:59.999999') {
                  elementFrame.endHourFrame = '24:00:00';
                }
                const intervalDiff = parseInt(elementFrame.endHourFrame.substring(0, 2), 10) - parseInt(elementFrame.startHourFrame.substring(0, 2), 10);
                if (intervalDiff > 1) {
                  for (let i = 0; i < intervalDiff; i++) {
                    if (parseInt(elementFrame.endHour.substring(0, 2), 10) > parseInt(elementFrame.startHourFrame.substring(0, 2), 10) + i) {
                      startHour.push(parseInt(elementFrame.startHourFrame.substring(0, 2), 10) + i + 'h');
                    }
                  }
                }
              });
            }
          });
          elments[property].startHour = startHour;
          elments[property].day = property;

        });
      }
      const formatedElements = Object.values(elments);
      framesDetails = this.formatData(framesDetails);
      return {
        startHour,
        formatedElements,
        activeService,
        framesDetails
      };



    }

  /**
   *
   * @param unordered
   */
  private sortArrayOfObject(unordered: any[]): any[] {
    return unordered.sort((a, b) => {
      const keyA = parseInt(a.startHour.split(':')[0], 10);
      const keyB = parseInt(b.startHour.split(':')[0], 10);
      // Compare the 2 dates
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });
  }

  /**
   *
   * @param data
   */
  formatData(data) {
    const framesDetailscombined = [];
    data.forEach(el => {
      framesDetailscombined.push(...el);
    });
    return framesDetailscombined;
  }

  getconversation(consultantId, customerID) {
    // Retrieve the paidPack if there is any
    // get-conversation endpoint

    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    // const url = `${this.apiUrl}/get-conversation?consultant_id=${consultantID}&customer_id=${this.customerID}`
    const url = `${this.apiUrlChat}/get-conversation`
    const payload = {
      'consultant_id': consultantId,
      'customer_id': customerID,
    }

    // Retrieve the paidPack if there is any
    // get-conversation endpoint
    return this.http.post(url, payload, {
      headers
    });
  }

  processPayment(transaction_id) {

    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const url = `${this.apiUrlChat}/process-payment`
    const payload = {
      'transaction_id': transaction_id,
    }
    // process-payment endpoint
    return this.http.post(url, payload, {
      headers
    })

  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private  apiUrl =  environment.apiUrlAccountCustomer;
  private  apiKey = environment.apiKey;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  // create token
  createToken(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-create-token`, formData , {headers: header});
  }

  // mail send
  mailSend(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-mail-send`, formData , {headers: header});
  }

  // check token
  checkToken(customerToken) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        customerToken
      }
    });
    return this.http.get(`${this.apiUrl}/account-check-token`, {params: params , headers: header});

  }
 // edit personal info name, first name and gender

  accountUpdatePersonal(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-update-personal`, formData , { headers: header});
  }

  // edit email
  editEmail(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-update-email`, formData , { headers: header});
  }

  // edit email
  editPassword(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-update-password`, formData , { headers: header});
  }

  askClose(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-ask-close`, formData , { headers: header});
  }

  editBirthDate(formData) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/account-update-birth`, formData , { headers: header});
  }
}

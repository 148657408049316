import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../_services/register.service';
import {ProfileService} from '../../_services/profile.service';
import * as $ from 'jquery';
import {debounceTime, delay, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.css']
})
export class EmailResetComponent implements OnInit {

  formEmailReset: FormGroup;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  emailToken: string;
  customerEmail: string;
  customerOldEmail: string;
  emailLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private profileService: ProfileService
  ) {
  }

  get f() {
    return this.formEmailReset.controls;
  }

  ngOnInit(): void {

    this.registerService.customerOldEmail.subscribe(oldEmail => this.customerOldEmail = oldEmail);

    this.formEmailReset = this.fb.group({
        merchantId: environment.merchantId,
        customerEmail: ['',
          [
            Validators.required,
            Validators.email,
            Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')],
          [this.emailValidations.bind(this)]
        ]
      }
    );
  }

  onChangePassword() {
    if (this.formEmailReset.valid) {
      this.onEmailReset();
    }
  }


  onEmailReset() {

    const dataTemporarySession = {
      merchantId: environment.merchantId,
      cabinetId: environment.officeId,
      customerEmail: this.customerOldEmail,
      customerTemporarySessionMaxTime: 100
    };

    this.registerService.createTemporarySession(dataTemporarySession).subscribe((result: any) => {

        this.customerEmail = this.formEmailReset.getRawValue().customerEmail;

        const passwordUpdate = {
          customerId: result.customerId,
          customerSessionId: result.customerTemporarySessionId,
          customerOldEmail: this.customerOldEmail,
          customerNewEmail: this.customerEmail
        };

        this.profileService.editEmail(passwordUpdate).subscribe((result2) => {

            // Send validation mail
            const data = {recipientMail: this.customerEmail, customerId: result.customerId};
            this.registerService.sendValidationMail(data);

            this.registerService.customerOldEmail.next(this.customerEmail);
            $('#bodyModalResetEmail').hide();
            $('#bodyModalValidationEmail').show();
          },
          error => {

            document.getElementById('closeRegisterModal').click();
            document.getElementById('openModalErrorEmailValidation').click();


          });

      },
      error => {

        document.getElementById('closeRegisterModal').click();
        document.getElementById('openModalErrorEmailValidation').click();


      });

  }

  emailValidations(ctr: AbstractControl) {
    this.emailLoading = true;
    return ctr
      .valueChanges.pipe(
        delay(10),
        debounceTime(100)
        , distinctUntilChanged()
        , switchMap(value => this.registerService
          .checkEmail(ctr.value).pipe(
            map((result: any)  =>  {
              this.emailLoading = false;
              if (result.emailExists === true) {
                ctr.setErrors({emailExist: true});

              } else {
                ctr.setErrors(null);

              }
            })
          ))
      );
  }

  returnResetMailModal() {
    $('#bodyModalResetEmail').hide();
    $('#bodyModalValidationEmail').show();

  }





}

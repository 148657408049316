import { Injectable } from '@angular/core';
import {Account} from '../_models/account';
import * as AccountActions from '../actions/account.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Router} from '@angular/router';
import {HomeService} from "./home.service";
import {CustomDateParserFormatter} from "../_helpers/CustomDateParserFormatter";
declare var $: any;

interface POSTPARAMS {
  checkPhone: boolean;
  checkEmail: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private defaultPostParams: POSTPARAMS;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private homeService: HomeService,
    private dateParserFormatter: CustomDateParserFormatter

  ) {
    this.defaultPostParams = {
      checkPhone: true,
      checkEmail: true
    };
  }

  init(account: Account, postParams = this.defaultPostParams): void {
    account = this.preInit(account);
    account = this.dateParserFormatter.adaptDateObject(account, {customerBirthday:false, customerDateAskClosed: true, customerDateBlacklist:true, customerDateWhitelist: true, customerDateClosed:true, customerDateCreate:true, customerDateUpdate:true});
    this.store.dispatch(new AccountActions.SetAccount(account));
    this.postInit(account, postParams);
  }

  private preInit(account: Account): Account {
    if (account.customerBirthday === 'None' ) {
      account.customerBirthday = '';
    } else if (account.customerBirthday) {
      const dateArray = account.customerBirthday.split('+');
      account.customerBirthday = dateArray[0];
    }
    return account;
  }

  private postInit(account: Account, postParams: POSTPARAMS): void {
    const isPhoneRoute = window.location.pathname;
   // const lastItemPath = isPhoneRoute.substring(isPhoneRoute.lastIndexOf('/') + 1);
    if (postParams?.checkPhone) {
      if (!account.principalCallNumber && isPhoneRoute !== '/phone') {
       this.homeService.redirect('/phone');
      } else if (isPhoneRoute !== '/phone' && account.principalCallNumber && account.isPrincipalCallNumberValidate === false) {
        this.showPhoneAlert();
      } else if (isPhoneRoute !== '/phone' && account.secondaryCallNumber && account.isSecondaryCallNumberValidate === false) {
        this.showPhoneAlert();
      } else if (postParams?.checkEmail && account.isCustomerEmailValidate === false) {
        this.showEmailAlert();
      }
    } else if (postParams?.checkEmail && account.isCustomerEmailValidate === false) {
      this.showEmailAlert();
    }
  }

  update(key: string, value: string|number|boolean): void {
    this.store.dispatch(new AccountActions.PutAccount({key, value}));
  }

  destroy(): void {
    this.store.dispatch(new AccountActions.DeleteAccount());
  }

  showEmailAlert(): void {
    $('#email-notif-modal').modal('show');
  }

  showPhoneAlert(): void {
    $('#phone-notif-modal').modal('show');
  }
}

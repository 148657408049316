import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  apiUrl = environment.apiUrlAccountCustomer;
  private apiKey = environment.apiKey;
  constructor(private http: HttpClient) { }

  sendEmail(data) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
  
    return this.http.post(`${this.apiUrl}/account-mail-send`, data, { headers: header });
  }
}

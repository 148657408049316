import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ForfaitService {
  apiServiceType = environment.apiUrlServiceType;
  apiPayment = environment.apiUrlPayment;
  apiHistory = environment.apiUrlHistory;

  private data: any;
  private apiKey = environment.apiKey;


  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  /**
   * This function is meant to retrieve packs for chat and cb services
   * @param merchantId 
   * @param officeId 
   * @param voyanceType default cb, type of forfaits (cb, chat)
   * @returns promise
   */
  getBuy(merchantId, officeId, voyanceType = null) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);

    const formObject: {} = {
      merchantId,
      officeId,
      prioritySort: 'SMALLER'
    }
    if (voyanceType) {
      formObject['voyanceType'] = voyanceType;
    }

    const p = new HttpParams({ fromObject: formObject });
    return this.http.get(`${this.apiServiceType}/get-buy`, { params: p, headers: header });
  }

  getAskMoney(customerId, cardOneClickId, currency, amount, historyId = null) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const p = new HttpParams({
      fromObject: {
        customerId: customerId || localStorage.getItem('customerId'),
        cardOneClickId,
        currency,
        amount,
        historyId
      }
    });
    return this.http.get<any>(`${this.apiPayment}/get_payment_authorization`, { params: p, headers: header });
  }

  setHistory(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = new HttpHeaders().set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiHistory}/set-history`, data, { headers });
  }

  getMoney(customerId, cardOneClickId, amount, authorizationId, historyId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        customerId: customerId || localStorage.getItem('customerId'),
        cardOneClickId,
        amount,
        authorizationId,
        historyId
      }
    });
    return this.http.get(`${this.apiPayment}/get_money`, { params: params, headers: header });
  }
}

import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { PrivateConversationComponent } from './private-conversation.component';
import { StartPrivateConversationComponent } from './start-private-conversation/start-private-conversation.component';
import {SelectPrivateConversationComponent} from './select-private-conversation/select-private-conversation.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReviewModule} from '../review/review.module';

@NgModule({
  declarations: [
    PrivateConversationComponent,
    StartPrivateConversationComponent,
    SelectPrivateConversationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ReviewModule,
  ],
  exports: [
    SelectPrivateConversationComponent
  ]
})
export class PrivateConversationModule { }

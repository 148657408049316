import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-secondary-phone',
  templateUrl: './update-secondary-phone.component.html',
  styleUrls: ['./update-secondary-phone.component.css']
})
export class UpdateSecondaryPhoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {CryptoService} from '../app/_services/crypto.service';


const cryptoService = new CryptoService();
export const environment = {

  production: true,
  version: 'v1.0',
  apiUrlPayment: 'https://vcb.api.mobiyo.com/payment',
  apiUrlAccountCustomer: 'https://vcb.api.mobiyo.com/customer-account',
  apiUrlConsultant: 'https://vcb.api.mobiyo.com/consultant-planning',
  apiUrlDefinition: 'https://common.api.mobiyo.com/definition',
  apiUrlNotice: 'https://vcb.api.mobiyo.com/notice',
  apiUrlIvr: 'https://mws.mediakiosque.com/rest/voyanceweb',
  cryptoKey: 'nhl9ZaUXRvuXRFYdaT5GNlAA7d1nxPg8',
  apiUrlServiceType: 'https://vcb.api.mobiyo.com/type-buy-cb',
  apiUrlHistory: 'https://vcb.api.mobiyo.com/history',
  apiUrlDomain : 'https://vcb.api.mobiyo.com/domain_client',
  apiUrlPromotion : 'https://vcb.api.mobiyo.com/promotion',
  apiUrlBanner : 'https://vcb.api.mobiyo.com/banner_audiotel/',
  apiKey: 'stBgwfvul3zipkzpq8mtzqkXz0Mkiheur$ynH14n',
  merchantId: parseInt(cryptoService.get(localStorage.getItem('m')), 10),
  officeId: parseInt(cryptoService.get(localStorage.getItem('o')), 10),
  apiUrlChat: 'https://vcb.api.mobiyo.com/chat',
  
  
  // production: false,
  // version: 'v1.0',
  // apiUrlPayment: 'https://vcb.staging.api.mobiyo.com/payment',
  // apiUrlAccountCustomer: 'https://vcb.staging.api.mobiyo.com/customer-account',
  // apiUrlConsultant: 'https://vcb.staging.api.mobiyo.com/consultant-planning',
  // apiUrlDefinition: 'https://common.staging.api.mobiyo.com/definition',
  // apiUrlNotice: 'https://vcb.staging.api.mobiyo.com/notice',
  // apiUrlIvr: 'https://mws.mediakiosque.ext.mobiyo.pw/rest/voyanceweb',
  // apiUrlServiceType: 'https://vcb.staging.api.mobiyo.com/type-buy-cb',
  // apiUrlHistory: 'https://vcb.staging.api.mobiyo.com/history',
  // apiUrlDomain : 'https://vcb.staging.api.mobiyo.com/domain_client',
  // apiUrlPromotion : 'https://vcb.staging.api.mobiyo.com/promotion',
  // apiUrlBanner : 'https://vcb.staging.api.mobiyo.com/banner_audiotel/',
  // apiKey: 'vzx5t3nOIraQWNhP3Dfky3dr1slIzg4haveFDCLI',
  // merchantId: parseInt(cryptoService.get(localStorage.getItem('m')), 10),
  // officeId: parseInt(cryptoService.get(localStorage.getItem('o')), 10),
  // apiUrlChat: 'https://vcb.staging.api.mobiyo.com/chat',
};



import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from '../../_services/profile.service';
declare var $: any;

@Component({
  selector: 'app-cancel-account',
  templateUrl: './cancel-account.component.html',
  styleUrls: ['./cancel-account.component.css']
})
export class CancelAccountComponent implements OnInit {
  @Input() infoDefinitions: any;
  @Input() cancelDate: string;
  step = 1;
  submitting = false;

  constructor(
    private profileservice: ProfileService
  ) { }

  ngOnInit(): void {
    $('#cancel-account-modal')
      .on('show.bs.modal', () => {
        if (this.cancelDate) {
          this.next(3);
        }
      })
      .on('hidden.bs.modal', () => {
        this.reset();
      });
  }

  askDelete(): void {
    this.submitting = true;
    const data = {
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId')
    };
    this.profileservice.askClose(data).subscribe(
      (result: any) => {
        if (result.statusCode === undefined) {
          this.next(2);
          this.cancelDate = new Date().toDateString();
        } else {
          this.cancelDate = result?.body?.errorData?.customerDateAskClose;
          if (this.cancelDate) {
            this.next(3);
          } else {
            this.next(4);
          }
        }
      },
      (error) => {
        this.next(4);
      }
    );
  }

  private next(step = 1) {
    this.step = step;
  }

  private reset() {
    this.submitting = false;
    this.next();
  }
}

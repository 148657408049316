import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Session } from '../_models/session';

export const SET_SESSION = '[SESSION] Set';
export const PUT_SESSION = '[SESSION] Put';
export const DELETE_SESSION = '[SESSION] Delete';

export class SetSession implements Action {
  readonly type = SET_SESSION;

  constructor(public payload: Session) {}
}

export class PutSession implements Action {
  readonly type = PUT_SESSION;

  constructor(public payload: {}) {}
}

export class DeleteSession implements Action {
  readonly type = DELETE_SESSION;

  constructor() {}
}

export type Actions = SetSession | PutSession | DeleteSession;

<div class="modal fade" id="email-notif-modal" tabindex="-1" aria-labelledby="email-notif-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="email-notif-modal-label">
          <span>VALIDER VOTRE COMPTE</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!error">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
          </div>
          <div class="col-10 align-self-center">
            Votre compte n'est toujours pas validé.<br/>
            Vous n'avez pas reçu de mail avec le lien pour valider votre compte ?
          </div>
        </div>
        <br/>
        <div class="text-center">
          <a href="javascript:void(0)" (click)="validateEmail()" *ngIf="!loader">
            <u>Recevoir un nouveau lien de validation !</u>
          </a>
          <div class="spinner-border spinner-border" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="error">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
          </div>
          <div class="col-10 align-self-center text-danger">
            Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
          </div>
        </div>
        <br>
        <p>
          Merci de contacter directement {{definitions.sms_brand}} au {{definitions.provider_call_number}}
        </p>
      </div>
      <div class="modal-footer" *ngIf="error">
        <button type="button" class="btn btn-warning" data-dismiss="modal">Continuer</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="phone-notif-modal" tabindex="-1" aria-labelledby="phone-notif-modal-label" aria-hidden="true" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="phone-notif-modal-label">
          <span>VALIDER VOTRE NUMERO DE TELEPHONE</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
          </div>
          <div class="col-10 align-self-center">
            <p *ngIf="!customerPhoneValidate">Tant que votre N° de téléphone <b *ngIf="customerPhone"> : <span *ngIf="customerPhone" [innerHTML]="formatPhone(customerPhone)"></span></b> n'est pas validé, vous ne pourrez pas effectuer de convertations privées.</p>
            <p *ngIf="customerPhoneValidate">Tant que votre N° de téléphone <b *ngIf="customerSecondPhone"> : <span *ngIf="customerSecondPhone" [innerHTML]="formatPhone(customerSecondPhone)"></span></b> n'est pas validé, vous ne pourrez pas effectuer de convertations privées.</p>
          </div>
        </div>
        <br/>
        <p class="text-center">
          <a (click)="checkRedirect()" data-dismiss="modal" class="btn-details"><u>Je le valide maintenant !</u></a>
        </p>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Domain} from '../_models/domain';

@Injectable({
  providedIn: 'root'
})

export class DomainService {
  private apiUrlDomaine = environment.apiUrlDomain;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
  }

  getMerchantParams() {
    const params = new HttpParams({
      fromObject: {
        domainName: 'allomegane.com'
      }
    });
    return this.http.get<Domain>(`${this.apiUrlDomaine}/get-merchant`, {params});

  }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../_services/register.service';
import {environment} from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.css']
})
export class PasswordResetRequestComponent implements OnInit {

  formPasswordResetRequest: FormGroup;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  emailToken: string;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
  ) {
  }

  get f() {
    return this.formPasswordResetRequest.controls;
  }

  ngOnInit(): void {
    this.formPasswordResetRequest = this.fb.group({
      merchantId: environment.merchantId,
      customerMerchantId: 25,
      customerEmail: ['',
          [
            Validators.required,
            Validators.email,
            Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')],
        ]
    });

    $('#resetPasswordModal')
      .on('hidden.bs.modal', () => {
        this.formPasswordResetRequest.reset();
        this.submitting = false;
      });
  }

  onChangePassword() {
    if (this.formPasswordResetRequest.valid) {
      this.sendEmailResetPassword(this.formPasswordResetRequest.getRawValue().customerEmail);
    }
  }

  sendEmailResetPassword(recipientMail) {
    const baseUrl = window.location.origin + location.pathname;

    const dataTemporarySession = {
      merchantId: environment.merchantId,
      cabinetId: environment.officeId,
      customerEmail: recipientMail,
      customerTemporarySessionMaxTime: 100
    };

    this.submitting = true;

    // create temporary session
    this.registerService.createTemporarySession(dataTemporarySession).subscribe(
      (result: any) => {
        if (result.customerTemporarySessionId) {
          const tmpSessionId = result.customerTemporarySessionId;
          const dataToken = {customerId: result.customerId, commandToken: 'RE-INIT-PASSWORD'};
          this.registerService.createToken(dataToken).subscribe(
            (result: any) => {
              this.emailToken = result.customerToken;
              if (result.customerToken) {
                const dataMailSend = {
                  merchantId: environment.merchantId,
                  recipientMail,
                  templateParams: {url: baseUrl + '?to=' + this.emailToken + '&ts=' + tmpSessionId},
                  template: 'url_template_re_init_password',
                  subject: 'Réinitialisation de votre mot de passe'
                };
                this.registerService.sendEmail(dataMailSend).subscribe(
                  (result: any) => {
                    $('#closeModalPasswordResetReqest').click();
                    $('#openModalSuccessResetPassword').click();
                    this.submitting = false;
                  },
                  error => {
                    $('#closeModalPasswordResetReqest').click();
                    $('#openModalErrorResetPassword').click();
                    this.submitting = false;
                  }
                );
              }
            },
            error => {
              $('#closeModalPasswordResetReqest').click();
              $('#openModalErrorResetPassword').click();
              this.submitting = false;
            }
          );

        } else if (result.statusCode === -20) {
          $('#openModalErrorResetPasswordMessage').html('ERREUR : Votre email est invalide! Votre mot de passe n\'a pas été réinitialisé.');
          $('#closeModalPasswordResetReqest').click();
          $('#openModalErrorResetPassword').click();
          this.submitting = false;
        } else {
          $('#closeModalPasswordResetReqest').click();
          $('#openModalErrorResetPassword').click();
          this.submitting = false;
        }

      },
      error => {
        // Can't connect to API account
        console.log(error);
        $('#closeModalPasswordResetReqest').click();
        $('#openModalErrorAccountApi').click();
      }
    );
  }
}

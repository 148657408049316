import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {LoginService} from './login.service';
import {HomeService} from './home.service';

@Injectable()
export class IsLoggedInGuard implements CanActivate {
  constructor(private authService: LoginService, private router: Router , private homeService: HomeService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn && !localStorage.getItem('customerSessionId')) {
          return  true;
        }
        this.homeService.redirect('/');
        return false;      })
    );
  }
}

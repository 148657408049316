import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators,ValidatorFn } from '@angular/forms';
import { DefinitionService } from '../_services/definition.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Observable } from 'rxjs';
import { Definitions } from '../_models/definitions';
import {environment} from "../../environments/environment";
import { NewsletterService } from '../_services/newsletter.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  formAddmember: FormGroup;
  selectedCountryCode: string;
  countryCodes: string[];
  countryCodesData: {};
  isValidationSet = false;
  submitted = false;
  choices=[];
  minDate: { month: number; year: number; day: number };
  maxDate: { month: number; year: number; day: number };
  two = (x: number): string => x > 9 ? `${x}` : `0${x}`;
  dateTimeToString = (d: Date) => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${this.two(d.getHours())}:${this.two(d.getMinutes())}:${this.two(d.getSeconds())}`;


  constructor(private fb: FormBuilder,
    private newsletterservice:NewsletterService) {

        const [minDate, maxDate] = [new Date(), new Date()];
        minDate.setFullYear(minDate.getFullYear() - 100);
        maxDate.setFullYear(maxDate.getFullYear() - 16);
        this.minDate = {year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate()};
        this.maxDate = {year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate()};
        this.countryCodesData = {
          be: '+32',
          fr: '+33',
          lu: '+352',
          ch: '+41',
          ca: '+1'
        };
        this.choices = [
          {
            id: "receiveSms",
            title: "J'accepte recevoir des PROMOTIONS par SMS"
          },
          {
            id: "receiveEmail",
            title: "J'accepte recevoir des PROMOTIONS par Email"
          }
        ];

  }

  ngOnInit(): void { 
  
    this.formAddmember = this.fb.group({
      customerEmail: ['', [Validators.required, Validators.email, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
      firstname : ['',[Validators.required]],
      lastname: ['',[Validators.required]],
      memberCallNumber: ['', [Validators.required, Validators.pattern('^0(6|4|5|7)[0-9]{8}$')]],
      acceptTerms: [false, Validators.requiredTrue]   ,
      customerBirthday:['', [
        Validators.required
      ]],
    });
    this.formAddmember.addControl("choicesFormGroup", this.buildchoicesFormGroup(this.choices));
    this.countryCodes=['fr', 'be', 'ch', 'lu', 'ca'];
    this.selectedCountryCode = this.countryCodes[0] || 'fr'; 
  }

  get f() {
    return this.formAddmember.controls;
  }

  get choicesFormGroup(): FormGroup {
    return this.f && <FormGroup>this.f.choicesFormGroup
  }

  buildchoicesFormGroup(choices, selectedchoiceIds: string[] = []): FormGroup {
    let group = this.fb.group({}, {
      validators: this.atLeastOneCheckboxCheckedValidator()
    });
    choices.forEach(category => {
      let isSelected = selectedchoiceIds.some(id => id === category.id);
      group.addControl(category.id, this.fb.control(isSelected));
    })
    return group;
  }

  changeCodeNumber($event) {
    this.selectedCountryCode = $event;
    if (this.f.memberCallNumber.validator) {
      this.isValidationSet = true;
    }
  }

  onSubmitAddNewMemberToNewsLetter(){
    this.submitted =true;
 
    if (this.formAddmember.valid) {
      const formAddmemberValue = this.formAddmember.value;
      const birthdayObj = formAddmemberValue.customerBirthday;
      const birthday = new Date(birthdayObj.year, birthdayObj.month - 1, birthdayObj.day);
  
      let memberInfo ={
        "acceptTerms":formAddmemberValue.acceptTerms,
        "memberBirthday": this.dateTimeToString(birthday),
        "memberEmail": formAddmemberValue.customerEmail,
        "firstname": formAddmemberValue.firstname,
        "lastname":formAddmemberValue.lastname,
        "memberCallNumber": this.countryCodesData[this.selectedCountryCode.toLowerCase()] + formAddmemberValue.memberCallNumber,
        "receiveEmail": formAddmemberValue.choicesFormGroup.receiveEmail,
        "receiveSms": formAddmemberValue.choicesFormGroup.receiveSms
      }
      this.newsletterservice.addMember(memberInfo).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {    
            
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Membre bien ajouté',
              showConfirmButton: false,
              timer: 1500,
            
            })
            this.formAddmember.reset();

          } else {
            console.error(result?.body);
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Nous avons rencontré un problème , Veuillez réssayer plus tard',
              showConfirmButton: false,
              timer: 1500    
            })
            this.formAddmember.reset();
          }

        },
        error => {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Nous avons rencontré un problème , Veuillez réssayer plus tard',
            showConfirmButton: false,
            timer: 1500    
          })
          this.formAddmember.reset();
        }
      );
    }
  }

  atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;
  
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
  
        if (control.value === true) {
          checked++;
        }
      });
  
      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        };
      }
  
      return null;
    };
  }

}

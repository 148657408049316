import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PromotionComponent} from './promotion.component';
import { CookieService } from 'ngx-cookie-service';



@NgModule({
  declarations: [
    PromotionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PromotionComponent
  ],
  providers: [ CookieService ],

})
export class PromotionModule { }

<nav class="navbar navbar-expand-lg navbar-light bg-shadow">
  <a class="navbar-brand" [routerLink]="['']">
    <img class=" img-logo" src="/assets/images/Mavoyance.png" >
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto font-weight-bold " >
      <div class="nav-item nav-item-mobiyo ">
        <a (click)="this.homeService.redirect('/')" class="nav-link nav-link-mobiyo"  [ngClass]="{'active-link' : router.url == '/'}" >Accueil</a>
      </div>
      <div class="nav-item nav-item-mobiyo">
        <a [routerLink]="['/']" class="nav-link nav-link-mobiyo" >Qui sommes-nous?</a>
      </div>
      <div class="nav-item dropdown" >
        <a class="nav-link nav-link-mobiyo dropdown-toggle" data-toggle="dropdown" >Voyance</a>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/la-voyance-privee-live')"> Voyance privée live</a></li>
          <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/la-voyance-privee')"> Voyance privée</a></li>
          <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/la-voyance-audiotel')"> Voyance audiotel</a></li>
          <div class="dropdown-divider"></div>
        </ul>
      </div>


      <div class="nav-item nav-item-mobiyo">
        <a [routerLink]="['/']" class="nav-link nav-link-mobiyo" >Nos Voyants</a>
      </div>
      <div class="nav-item nav-item-mobiyo">
        <a [routerLink]="['/']" class="nav-link nav-link-mobiyo" >Horoscope</a>
      </div>
      <div class="nav-item nav-item-mobiyo">
        <a [routerLink]="['/']" class="nav-link nav-link-mobiyo" >Nos promos</a>
      </div>
    </ul>
   <app-signin></app-signin>
  </div>
</nav>

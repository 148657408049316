import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PromotionService} from '../_services/promotion.service';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Promotion} from '../_models/promotion';
import * as PromotionsActions from '../actions/promotions.actions';
import {HomeService} from '../_services/home.service';
import {Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css'],
  changeDetection: ChangeDetectionStrategy.Default


})
export class PromotionComponent implements OnInit {
  form: FormGroup;
  public account: Observable<Account>;
  public promotions: Observable<Promotion[]>;
  public bonus = undefined;
  public position = [];
  bonusMessage = '  sur votre prochaine consultation CB';
  pictureUrl: any;
  constructor(
    public promotionService: PromotionService,
    public store: Store<AppState>,
    private homeService: HomeService,
    public router: Router
    ) {
    this.promotions = store.select('promotions');
  }

  ngOnInit() {
    this.restModalValues();
    this.promotions.subscribe(data => {
      data?.forEach(el => {
        // tslint:disable-next-line:no-non-null-assertion
        const classElement = document.querySelector<HTMLElement>('.popup-bg')!;
        classElement.style.backgroundImage = el?.pictureURL;
        this.pictureUrl = el?.pictureURL;
        });
    },
      error => {

      });
  }

  reset(): void {
    this.store.dispatch(new PromotionsActions.DeletePromotions());

  }

  restModalValues() {
    $('#promotionPopup')
      .on('show.bs.modal', () => {
        this.reset();
      })
      .on('hidden.bs.modal', () => {
        this.reset();
        if (this.router.url === '/start-conversation') {
          this.homeService.redirect('/');
        }
      });
  }

}

import { Action } from '@ngrx/store';
import { Account } from '../_models/account';
import * as AccountActions from '../actions/account.actions';

/*const initialState: Account = {
}*/

export function reducer(state: Account, action: AccountActions.Actions) {
  switch (action.type) {
    case AccountActions.SET_ACCOUNT:
      return action.payload;
    case AccountActions.PUT_ACCOUNT:
      state = {...state, [action.payload.key]: action.payload.value};
      return state;
    case AccountActions.DELETE_ACCOUNT:
      return undefined;
    default:
      return state;
  }
}

import { Promotion } from '../_models/promotion';
import * as PromotionActions from '../actions/promotions.actions';

// const initialState: Promotion[] = [];

export function reducer(state: Promotion[] = [], action: PromotionActions.Actions) {
  switch (action.type) {
    case PromotionActions.SET_PROMOTIONS:
      state = [...state, ...action.payload];
      state.map(item => {
        if (item.endDateTime) { item.endDateTime.replace('-', '/'); }
      });
      return [...new Map(state.map(item => [item.code, item])).values()];
    case PromotionActions.DELETE_PROMOTIONS:
      return undefined;
    default:
      return state;
  }
}

import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Peer, { DataConnection } from 'peerjs';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { paidPack as PaidPackSchema } from '../../_models/paidPack'
import { ChatService } from '../../_services/chat.service';
import { ExtendConversation } from '../../_services/extend-conversation.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.css']
})
export class ChatModalComponent implements OnInit, OnChanges {

  chatForm: FormGroup;
  @Input() customerID: any;
  @Input() consultant: any;
  @Output() closeConversationModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() extendConversation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() paidPack: PaidPackSchema = null;
  @Output() processGetMoney: EventEmitter<PaidPackSchema> = new EventEmitter<PaidPackSchema>();

  private apiUrl = environment.apiUrlChat;
  private apiKey = environment.apiKey;

  // ***** Chat feature *****
  /**
   *
   */
  interlocutorPeerID: string = null;
  myPeerID: string = null;
  connection: any;
  messageInputPressedKeys: Array<String> = [];
  // Conversation's messages
  messages: Array<{}> = [];
  loadingMessages: boolean = false;
  sendingMessage: boolean = false;
  timeoutId: NodeJS.Timeout | null = null;
  messagesPage: number = 1;
  messagesPerPage: number = 15;
  canLoadMoreMessages: boolean = true; // This will be false when we reach the very first message in an active conversation
  unreadMessages: number = 0;
  timerColor: String = '#fff';
  timerBgColor: String = 'transparent';
  remainingSeconds: any = 0;
  consultantInfo: number = null;
  paymentModalIsVisible: boolean = false;

  customerInfo: number = null;
  conversationStartedAt: any = null;
  counterID: any = null;
  idleTimeRemaining: any = 0; // In seconds

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private chatService: ChatService,
    private extendConversationService: ExtendConversation
  ) {
    this.messages = []
  }

  ngOnInit(): void {
    // Reset defaults values
    this.unreadMessages = 0;
    this.counterID = null;

    this.initializeChatForm()
    this.resetPeers()
    this.startConversation()
    this.remainingSeconds = this.paidPack.remaining_time
    console.log(this.paidPack);

    this.conversationStartedAt = this.paidPack.conversation_started_at

    this.extendConversationService.isExtended.subscribe((isExtended) => {
      if(isExtended>0){
        this.remainingSeconds = Number(isExtended)+ 30;
        this.updateTimer()
      }

    })
    // Start timer if necessary
    if (this.conversationStartedAt) {
      this.idleTimeRemaining = this.paidPack.watcher_value;
      this.updateTimer()
    }
  }

  /**
   * Start the conversation count down
   * @returns void
   */
  updateTimer =  () => {
    this.stopCounter();
    this.counterID = setInterval(async () => {
      if (this.idleTimeRemaining > 0 && this.remainingSeconds > 0) {
        this.remainingSeconds = parseInt(this.remainingSeconds) - 1;
        this.idleTimeRemaining = this.idleTimeRemaining - 1
      }
      else {
        // Pack finished
        this.stopCounter();
        console.log('timer stopped!')
      }

      // Trigger payment
      if (this.remainingSeconds === 30) {
        this.extendConvo();
      }

      if (this.remainingSeconds < 1) {
        console.log('Pack has been finished. Processing payment..');
        this.close();
        // Send get_money request
        this.paidPack.remaining_time = this.remainingSeconds
        console.log(this.paidPack);
        this.processGetMoney.emit(this.paidPack)
      }
    }, 1000);
  }

  stopCounter = () => {
    // Clear and reset counter
    clearInterval(this.counterID)
    this.counterID = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('consultant') && !changes.consultant.firstChange) {
      this.initializeChatForm()
      this.resetPeers()
      this.startConversation()
    }
  }

  startConversation = () => {
    this.initChat()
    this.fetchMessages(this.messagesPage, this.messagesPerPage)

    setTimeout(() => {
      this.easySendMessage() // Send message on Ctrl+Enter
      // Chat infinite scroll
      this.initChatInfiniteScroll()
    }, 200);
  }

  /**
   * @return void
   * close chat modal
   */
  close = () => {
    const chatContainer: HTMLElement = document.querySelector('.chat-modal-container')
    var width = chatContainer.clientWidth
    var height = chatContainer.clientHeight
    // Minimize the modal while closing it
    const step = this.messages.length ? this.messages.length : 20;

    const intervalID = setInterval(() => {
      if (chatContainer.clientHeight > 100) {
        height = height - step;
        width = width - step;
        chatContainer.style.height = `${height}px`;
        chatContainer.style.width = `${width}px`;
      } else {
        // Emit close conversation modal
        this.closeConversationModal.emit(true)
        clearInterval(intervalID)
        this.resetPeers()
      }
    }, 1)
  }

  closeChat = (isDisconnected = false) => {

    this.stopCounter();
    if(this.remainingSeconds > this.paidPack.remaining_time){
      this.remainingSeconds = this.paidPack.remaining_time
    }

    let finalAmount = ((Number(this.paidPack.service_duration) - Number(this.remainingSeconds)) * Number(this.paidPack.total_amount)) / Number(this.paidPack.service_duration);
    finalAmount = Math.round(finalAmount * 100) / 100
    console.log(finalAmount);
    if (isDisconnected) {
      console.log('Pack has been finished. Processing payment..');
      // Send get_money request
      this.paidPack.remaining_time = this.remainingSeconds;
      this.paidPack.total_amount = finalAmount;
      console.log(this.paidPack);
      this.close();
      this.processGetMoney.emit(this.paidPack)
      return;
    }


    Swal.fire({
      icon: 'info',
      title: 'Voulez-vous arrêter la conversation ',
      showDenyButton: true,
      focusConfirm: false,
      showCloseButton: true,
      confirmButtonText: 'Arrêter',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log('Pack has been finished. Processing payment..');
        // Send get_money request
        this.paidPack.remaining_time = this.remainingSeconds;
        this.paidPack.total_amount = finalAmount;
        console.log(this.paidPack);
        this.close();
        this.processGetMoney.emit(this.paidPack)
      }
    })

  }

  extendConvo = () => {
    clearInterval(this.counterID);
    Swal.fire({
      icon: 'info',
      title: 'voulez-vous prolonger la conversation',
      showDenyButton: true,
      focusConfirm: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: false,

      confirmButtonText: 'Prolonger',
      denyButtonText: `Annuler`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          this.extendConversationService.getAmount(this.paidPack).then((res) => {
            this.extendConversationService.closeConversation(this.paidPack).then((res) => {
              this.extendConversation.emit(true)
            }).then(()=>{
               this.setConversationStatus(true);
            })
            })
        } catch (error) {
          console.error("Error:", error);
          throw error;
        }
      }
      else if (result.isDenied) {
        console.log('denied');
        this.updateTimer();
      }
    })
  }
  resetPeers = () => {
    this.interlocutorPeerID = null;
    this.connection = null;
    this.canLoadMoreMessages = true
    this.messagesPage = 1
    this.messages = []
    this.messagesPerPage = 15
  }

  pingConsultant = (): Observable<Object> => {
    const payload = {
      consultant_id: this.consultant.consultantid,
      customer_id: this.customerID,
      customer_token: this.myPeerID,
    }

    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/ping-consultant`, payload, {
      headers
    })
  }


  /**
   *
   * @returns Observable
   */
  storeMessage = (): Observable<Object> => {
    const time = this.secondsToTime(this.remainingSeconds)
    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/send-message`, {
      consultant_id: this.consultant['consultantid'],
      customer_id: this.customerID,
      message_body: this.chatForm.get('messageBody').value,
      remaining_time: this.remainingSeconds,
    }, {
      headers
    })
  }


  secondsToTime(seconds) {
    let date = new Date(0); // create a new date object with a zero timestamp
    date.setSeconds(seconds); // set the seconds based on the input value
    let timeString = date.toISOString().substr(11, 8); // format the time string as "hh:mm:ss"
    return timeString;
  }


  /**
   * @Chat
   */
  initChat = (localPeerID = null) => {
    const config = {
      host: 'chat.elhajouji.com',
      port: 443,
      path: '/ping',
      secure: true,
      proxied: false,
    }
    const peer = new Peer(localPeerID)

    // Make myself online
    peer.on('open', (id) => {
      this.myPeerID = id


      // Send peer connection request
      // Fetch consultantToken
      this.pingConsultant()
        .subscribe(
          (result: any) => {
            this.interlocutorPeerID = result.data.consultant_token
            this.requestPeerConnection(peer)
            // this.acceptPeerConnection(peer)
          },
          (error) => {
            console.error(error)
          }
        )

      this.acceptPeerConnection(peer)
    });
  }

  /**
   * Request peer connection if his token is not null
   * Accept peer's connection requestion when a peer sends a connection request
   */
  requestPeerConnection = (peer: Peer): void => {
    // Request connection with peer
    if (this.interlocutorPeerID) {
      const connection = peer.connect(this.interlocutorPeerID)
      connection.on('open', () => {
        console.log('Connected to peer 2');

        /**
         * Assign the connection
         * Since the candidate has accepted our connection request
         */
        this.connection = connection

        // Data received
        connection.on('data', (data) => {
          this.processIncommingData(data)
        })
      })
    }
  }

  /**
   * Accept connection from remote peer (Consultant)
   * @returns void
   */
  acceptPeerConnection = (peer: Peer) => {
    const acceptConnection = (conn: DataConnection) => {
      this.connection = conn
      // Data received
      this.setConversationStatus(true);
      clearTimeout(this.timeoutId);
      conn.on('data', (data) => {
        this.processIncommingData(data)
      })

      // Handle connection errors
      conn.on('close', () => {
        console.log('Disconnected from peer')
        // set timeout to check if reconnection was successful
        /*this.timeoutId = setTimeout(() => {
          this.closeChat(true);
*/
      })
    }

    peer.on('connection', (conn) => {

      // Accept the incoming connection
      acceptConnection(conn)
    })
  }

  /**
   * @returns void
   */
  initializeChatForm(): void {
    this.chatForm = this.fb.group({
      messageBody: ['', Validators.required],
    });
  }

  /**
   *
   * @param data any
   */
  processIncommingData = (data: any) => {
    try {
      // Parse data which should be a JSON
      const message = JSON.parse(data)

      if (message.type === 'typing') {
        const container = document.querySelector('div.chat-modal-body')
        // Display a visual indicator that the other peer is typing
        const typingIndicator = document.querySelector('.ticontainer') as HTMLElement;
        typingIndicator.style.width = '55px';
        typingIndicator.style.height = '30px';
        typingIndicator.style.padding = '8px 18px';
        container.scrollTop = container.scrollHeight;
        // Set a timeout to hide the indicator after a certain amount of time
        setTimeout(() => {
          typingIndicator.style.width = '0px';
          typingIndicator.style.height = '0px';
          typingIndicator.style.padding = '0px 0px';
        }, 2000); // Change the timeout value as desired
      }
       else{
      if (message.hasOwnProperty('delivered') && !!message.delivered) {
        // Received delivery report
        this.setPriorMessagesAsRead(message)
      } else {
        // Push incomming message to messages array
        this.messages.push(message)

        // Start/Restart timer
        if (!this.conversationStartedAt) {
          this.conversationStartedAt = new Date();
        }

        this.idleTimeRemaining = this.remainingSeconds > 180 ? 180 : this.remainingSeconds
        this.updateTimer()
      }
    }
    } catch (err) {
      this.messages.push({
        id: Math.floor(Math.random() * 10) * 10,
        created_at: this.getCurrentDate(),
        sender_id: this.consultant.consultantid,
        body: data
      })

      // [ Start/Restart timer
      if (!this.conversationStartedAt) {
        this.conversationStartedAt = new Date();
      }

      this.idleTimeRemaining = 180
      this.updateTimer()
      // ]

      // Scroll down
      // this.scrollConversationDown()
      // Show new message notification
      this.showUnreadMessages()
    }
  }

  sendMessage = () => {
    console.log('send message' + this.remainingSeconds);
    if (this.remainingSeconds < 1) {
      return;
    }

    const messageBody = this.chatForm.get('messageBody').value
    if (!messageBody.length || this.remainingSeconds < 1) {
      alert('Vous devez saisir votre message!')
      return
    }

    // And if the conversation has been already started (conversation.started_at not null)
    if (
      (!this.connection)
      && !!this.conversationStartedAt
      && !confirm('Le consultant n\'est pas connecté! Si vous envoyer ce message il sera decrementé de votre pack, voulez vous continuer ?')) {
      return
    }

    this.appendMessage(messageBody)
    this.sendingMessage = true

    // Store the message into the database
    this.storeMessage().subscribe(
      (response: any) => {
        // Check response
        if (response.data?.status_code == 402) {
          const message = response.data.hasOwnProperty('message') ? response.data.message : 'Your pack has been finished'
          alert(message)
        }

        // Send a message to the receiver in real time
        this.sendingMessage = false
        if (response.hasOwnProperty('status_code') && response.hasOwnProperty('data') && response.status_code == 200) {
          this.sendMessageToPeer(response.data)
          // this.remainingSeconds = parseInt(response.data.remaining_time)
          // this.idleTimeRemaining = response.data.watcher_value
          // this.updateTimer()
        } else {
          console.log(response)
        }
      },
      (error: any) => {
        this.sendingMessage = false
        console.error(error)
      },
    )
  }

  sendMessageToPeer = (response: any) => {
    if (this.connection)
      this.connection.send(response.message_body);

    this.messages[this.messages.length - 1]['is_sending'] = false
    this.chatForm.get('messageBody').setValue('')
  }

  appendMessage = (messageBody: string) => {
    const message = {
      sender_id: this.customerID,
      body: messageBody,
      is_sending: true,
      created_at: this.getCurrentDate(),
    }

    this.messages.push(message)

    setTimeout(() => {
      this.scrollConversationDown()
    }, 200);
  }

  fetchMessages = (page: number, perPage: number): void => {

    this.loadingMessages = true
    const consultantID = this.consultant['consultantid']

    const url = `${this.apiUrl}/get-messages?consultant_id=${consultantID}&customer_id=${this.customerID}&page=${page}&per_page=${perPage}`;
    const headers = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);

    const options = {
      headers
    };
    this.http.get(url, options)
      .subscribe(
        ((response: any) => {
          // Will scroll back to it so that the user could continue reading at ease
          let lastElement = null
          if (page != 1) {
            // FIrst item suppose to be the loading spinner
            // Second child suppose to be the top message in the conversation list
            lastElement = document.querySelector('.chat-modal-body').children[1]
          }
          this.messages = [...response.data.reverse(), ...this.messages]
          this.loadingMessages = false
          // Scroll to bottom only on first page
          setTimeout(() => {
            this.scrollConversationDown(lastElement)
          }, 300);

          if (response.data.length == 0)
            this.canLoadMoreMessages = false;
        }),
        (error: any) => {
          console.error(error)
          this.loadingMessages = false
        }
      )
  }

  /**
   * @returns void
   * Scroll to the newest message on first fetching messages
   * to the last visible message on loading more messages
   */
  scrollConversationDown = (messageElementWhereToScroll: any = null): void => {
    const container: any = document.querySelector('.chat-modal-body')
    /**
     * @var boolean noElementProvided: if no element has been provided to this function
     * we'll scroll all the way down to the newest message
     */
    const noElementProvided = !messageElementWhereToScroll

    /**
     * if messageElementWhereToScroll is null, assign it the newest message in the conversation
     * there after scroll to it in a smooth animation
     */
    if (!messageElementWhereToScroll) {
      messageElementWhereToScroll = container.children.length ? container.children[container.children.length - 1] : null
    }

    if (messageElementWhereToScroll) {
      // Scroll to the message element provided or found
      messageElementWhereToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }

    // Make sure to scroll down to the newest message if no message
    if (noElementProvided) {
      setTimeout(() => {
        container.scrollTop = container.scrollHeight
      }, 700);
    }
  }

  /**
   * Trigger send message when Enter is being pressed
   * Insert new line when Shift+Enter are clicked if message textarea is focused
   * @returns void
   */
  easySendMessage = (): void => {
    const textarea: any = document.querySelector('#messageBody')
    if (!textarea) return

    textarea.addEventListener('keydown', (keyEvent: any) => {
      this.messageInputPressedKeys.push(keyEvent.key)

      // Return back to new line
      if (this.messageInputPressedKeys.includes('Enter') && this.messageInputPressedKeys.includes('Shift')) {
        return true
      }
      // Send
      if (this.messageInputPressedKeys.length == 1 && this.messageInputPressedKeys.includes('Enter')) {
        this.sendMessage()
        return false
      }

      // If the pressed key is between 'a' and 'z' (in lower case) empty the messageInputPressedKeys array
      // This prevents sending message when clicking Enter after having already pressed Ctrl+s and some particular and similar cases
      if (keyEvent.key.charCodeAt() >= 97 && keyEvent.key.charCodeAt() <= 122)
        this.messageInputPressedKeys = []
    })
    // Key up
    textarea.addEventListener('keyup', (keyEvent: any) => {
      this.messageInputPressedKeys = this.messageInputPressedKeys.filter(key => key != keyEvent.key)
    })
  }


  /**
   * Display and animate the badge containing unread messages count
   */
  showUnreadMessages = () => {
    // [ Don't show badge if there's no need to scroll down
    const messagesContainer: any = document.querySelector('.chat-modal-body')
    var height = parseInt(messagesContainer.clientHeight)

    for (var item of messagesContainer.children) {
      height -= - item.clientHeight
    }
    // Uncomment to hide the badge if not necessary
    // if (height > 0) return

    // Wait untill the new message has been inserted into the DOM
    // Show badge of unread messages
    const toID = setTimeout(() => {
      this.unreadMessages++;
      clearTimeout(toID)
    }, 200);

    // Shake the badge up and down
    const intervalID = setInterval(() => {
      const badge = document.querySelector('.new-message-badge')
      if (badge) {
        badge.classList.add('delayed-message-badge-animation')
        clearInterval(intervalID);
      }
    }, 3000);

  }

  /**
   * End of chat region
   */


  initChatInfiniteScroll = () => {
    const container = document.querySelector('div.chat-modal-body')
    container.addEventListener('scroll', () => {
      if (this.canLoadMoreMessages && container.scrollTop == 0) {
        this.messagesPage++;
        this.fetchMessages(this.messagesPage, this.messagesPerPage)
      }

      // Hide unread messages badge if the conversation reaches the bottom
      const badge = document.querySelector('button.new-message-badge')
      if (badge && container.scrollHeight - (container.clientHeight + container.scrollTop) <= 10) {
        // Animate hidding
        badge.classList.remove('delayed-message-badge-animation')
        badge.classList.add('closing-message-badge')
        setTimeout(() => {
          this.unreadMessages = 0;
        }, 1000);
      }
    })
  }


  /**
   * Set all prior messages as read
   * @param Objext data
   */
  setPriorMessagesAsRead = (messageObject: any) => {
    // this.messages.map((item: Object) => item.body == messageObject.data ? { ...item, read_at: this.getCurrentDate() } : item)
    const index = this.messages.findIndex((item: any) => item.body == messageObject.data)
    if (index >= 0 && this.messages.length > 0) {
      for (var i = 0; i <= index; i++) {
        this.messages[i]['read_at'] = this.messages[i]['read_at'] ? this.messages[i]['read_at'] : this.getCurrentDate()
      }
    }
  }


  /**
   *
   * @param date string
   * @param format string
   * @returns string
   */
  formatDate = (strDate: string, strFormat: string = 'time'): string => {
    console.log(strDate);

    const date = this.parseDate(strDate);
    if (isNaN(date.valueOf())) {
      throw new Error("Invalid date string");
    }
    const isoDate = date.toISOString();
    var options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };
    if (strFormat == 'date') {
      options['second'] = "numeric"
      options['year'] = 'numeric'
      options['month'] = 'numeric'
      options['day'] = 'numeric'
    }
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(isoDate));
  }

   parseDate(dateStr: string): Date {
    const [datePart, timePart] = dateStr.split(' ');
    const [year, month, day] = datePart.split('-').map(val => parseInt(val));
    const [hour, minute] = timePart.split(':').map(val => parseInt(val));
    // log all the values
    console.log(year, month, day, hour, minute);
    return new Date(year, month-1, day, hour, minute, 0);
  }

  /**
   *
   * @returns string
   */
  getCurrentDate = () => {
    const d = new Date()
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
      + ' ' + d.getHours() + ":" + d.getMinutes();
  }

  /**
   * Convert a huge number of seconds into hh:mm:ss
   * E.x: 7353 ==> 02:02:33
   * @returns String
   */
  getRemainingTime = () => {
    var carry = this.remainingSeconds;
    var h = 0;
    var m = 0;
    var s = 0;
    // Hours
    if (carry >= 3600) {
      carry = carry % 3600;
      h = (this.remainingSeconds - carry) / 3600;
    }

    // Minutes & Seconds
    if (carry >= 60) {
      s = carry % 60
      m = (carry - s) / 60;
    } else {
      s = carry
    }

    const hh = h > 9 ? h : `0${h}`;
    const mm = m > 9 ? m : `0${m}`;
    const ss = s > 9 ? s : `0${s}`;
    return `${hh}:${mm}:${ss}`;
  }


  setConversationStatus = (status: boolean) => {
    console.log('setConversationStatus');
    this.chatService.setConversationStatus(this.customerID, this.consultant.consultantid, status).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('Conversation status updated');
      }
      (err: any) => console.log(err)
    })
  }
}



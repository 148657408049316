import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Account } from '../_models/account';

export const SET_ACCOUNT = '[ACCOUNT] Set';
export const PUT_ACCOUNT = '[ACCOUNT] Put';
export const DELETE_ACCOUNT = '[ACCOUNT] Delete';

export class SetAccount implements Action {
  readonly type = SET_ACCOUNT;

  constructor(public payload: Account) {}
}

export class PutAccount implements Action {
  readonly type = PUT_ACCOUNT;

  constructor(public payload: {key: string, value: string|number|boolean}) {}
}

export class DeleteAccount implements Action {
  readonly type = DELETE_ACCOUNT;

  constructor() {}
}

export type Actions = SetAccount | PutAccount | DeleteAccount;

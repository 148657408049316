import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustDifferent} from '../../_helpers/must-different.validator';
import {MustMatch} from '../../_helpers/must-match.validator';
import {ProfileService} from '../../_services/profile.service';
import {Definitions} from '../../_models/definitions';
declare var $: any;

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.css']
})
export class PasswordUpdateComponent implements OnInit {
  @Input() infoDefinitions: Definitions;
  form: FormGroup;
  success = false;
  error = false;
  incorrect = false;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
    ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerOldPassword: ['', [
        Validators.required,
        Validators.pattern(this.infoDefinitions?.regex_password)
      ]],
      customerNewPassword: ['', [
        Validators.required,
        Validators.pattern(this.infoDefinitions?.regex_password)
      ]],
      customerNewPassword2: ['', [
        Validators.required
      ]],
    }, {
      validator: [
        MustDifferent('customerOldPassword', 'customerNewPassword'),
        MustMatch('customerNewPassword', 'customerNewPassword2')
      ]
    });
    $('#change-password-modal').on('hidden.bs.modal', () => {
      this.reset();
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitting = true;
      const formValue = this.form.value;
      delete formValue.customerNewPassword2;
      this.profileService.editPassword(formValue).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.next();
          } else if (result.statusCode === -4) {
            this.next(true, true);
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true, incorrect = false): void {
    this.success = state;
    this.error = !state;
    this.incorrect = incorrect;
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.incorrect = false;
    this.submitting = false;
    this.form.patchValue({
      customerOldPassword: '',
      customerNewPassword: '',
      customerNewPassword2: ''
    });
  }

}

import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustDifferent} from '../../_helpers/must-different.validator';
import {ProfileService} from '../../_services/profile.service';
import {CryptoService} from '../../_services/crypto.service';
import {Router} from '@angular/router';
import {RegisterService} from '../../_services/register.service';
import {AccountService} from '../../_services/account.service';
import {Definitions} from '../../_models/definitions';
import {environment} from '../../../environments/environment';
import {HomeService} from '../../_services/home.service';
declare var $: any;

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.css']
})
export class EmailUpdateComponent implements OnInit, AfterViewInit {
  @Input() customerEmail: string;
  @Input() infoDefinitions: Definitions;
  @Input() isCustomerEmailValidate: any;
  senEmail: boolean;
  form: FormGroup;
  success = false;
  error = false;
  private baseUrl = window.location.origin + location.pathname;
  successCheck = false;
  errorCheck = false;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private registerService: RegisterService,
    private crypto: CryptoService,
    private router: Router,
    private accountService: AccountService,
    public homeService: HomeService
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerOldEmail: [{value: '', disabled: true}],
      customerNewEmail: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}')
      ]],
    }, {
      validator: MustDifferent('customerOldEmail', 'customerNewEmail')
    });
    $('#change-email-modal')
      .on('hidden.bs.modal', () => {
        this.reset();
      })
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerOldEmail: this.customerEmail
        });
      });
  }

  ngAfterViewInit(): void {
    this.checkEmailVerification();
  }


  onSubmit() {
    this.form.value.customerOldEmail = this.customerEmail;
    if (this.form.valid) {
      this.submitting = true;
      this.sendEmail();
    } else {
      console.log('Form not Valid', this.form.errors);
      this.next(false);
    }
  }

  next(state = true): void {
    this.success = state;
    this.error = !state;
  }

  end(state= true, openModal = false): void {
    this.successCheck = state;
    this.errorCheck = !state;
    if (openModal) { $('#check-email-modal').modal('show'); }
  }

  sendEmail(): void {
    const tokenData = {
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      commandToken: 'VALIDATE-MAIL'
    };
    this.profileService.createToken(tokenData).subscribe(
      (result: any) => {
        if (result.statusCode === undefined) {
          const email = this.crypto.set(this.form.value.customerNewEmail);
          const url = this.baseUrl + '/?t=m&em=' + btoa(email) + '&tk=' + result.customerToken;
          const mailData = {
            merchantId: environment.merchantId,
            recipientMail: this.form.value.customerNewEmail,
            templateParams: {url, token: ''},
            template: 'url_template_update_mail',
            subject: 'Validation de votre nouveau email'
          };
          this.registerService.sendEmail(mailData).subscribe(
            (result2: any) => {
              if (result2.statusCode === undefined) {
                this.next();
              } else {
                console.log('error: ', result2);
                this.next(false);
              }
            },
            (error2) => {
              console.error('error :', error2);
              this.next(false);
            }
          );
        } else {
          console.log('error: ', result);
          this.next(false);
        }
      },
      (error) => {
        console.error('error :', error);
        this.next(false);
      }
    );
  }

  checkEmailVerification(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('t') && urlParams.has('tk') && urlParams.has('em') && urlParams.get('t') === 'm') {
      const customerToken = urlParams.get('tk');
      const newCustomerEmail = this.crypto.get(atob(urlParams.get('em')));
      if (customerToken && newCustomerEmail) {
        this.updateEmail(this.customerEmail, newCustomerEmail, customerToken);
      }
    }

  }

  updateEmail(customerOldEmail, customerEmail, customerToken): void {
    this.registerService.checkToken(customerToken, '1')
      .subscribe(
        (result: any) => {
          if (result.statusCode === undefined && result.isValid && result.commandToken === 'VALIDATE-MAIL') {
            const data = {
              customerId: parseInt(localStorage.getItem('customerId'), 10),
              customerSessionId: localStorage.getItem('customerSessionId'),
              customerOldEmail,
              customerNewEmail: customerEmail
            };
            this.profileService.editEmail(data).subscribe(
              (result2: any) => {
                if (result2.statusCode === undefined) {
                  this.end(true, true);
                  this.accountService.update('customerEmail', customerEmail);
                } else {
                  this.end(false, true);
                }
              },
              (error2) => {
                console.error('error: ', error2);
                this.end(false, true);
              }
            );
          } else {
            this.end(false, true);
          }
        },
        (error) => {
          console.error('error: ', error);
          this.end(false, true);
        }
      );
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.submitting = false;
    this.form.patchValue({
      customerNewEmail: ''
    });
  }

  openModalValidationEmail() {
    const data = {recipientMail: this.customerEmail, customerId: parseInt(localStorage.getItem('customerId'), 10),
      senderMail: undefined
    };
    this.senEmail = true;
    data.senderMail = this.infoDefinitions.provider_mail;
    const baseUrl = window.location.origin + location.pathname;
    const dataToken = {customerId: data.customerId, commandToken: 'VALIDATE-MAIL'};
    const recipientMail = data.recipientMail;
    this.registerService.createToken(dataToken).subscribe(
      (result: any) => {
        const emailToken = result.customerToken;
        if (result.customerToken) {
          const dataMailSend = {
            merchantId: environment.merchantId,
            recipientMail,
            templateParams: {url: baseUrl + '?to=' + emailToken},
            template: 'url_template_account_activation',
            subject: 'Activation de votre compte',
          };
          this.registerService.sendEmail(dataMailSend).subscribe(
            (res: any) => {

              if (res.statusCode === undefined) {
                $('#validation-email-modal').modal('show');
                this.senEmail = false;

              } else {
                $('#validation-email-modal').modal('hide');
                $('#modalErrorEmailValidation').modal('show');
                this.senEmail = false;
              }

            },
            error => {
              $('#validation-email-modal').modal('hide');
              $('#modalErrorEmailValidation').modal('show');
              this.senEmail = false;
            }
          );
        }
      },
      error => {
        $('#validation-email-modal').modal('hide');
        $('#modalErrorEmailValidation').modal('show');
        this.senEmail = false;
      }
    );
  }
}

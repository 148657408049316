<div class="spinner-border spinner-border-sm d-inline-block ml-2 mb-1" role="status" *ngIf="plannings.length==0">
  <span class="sr-only">Loading...</span>
</div>
<div id="slider">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div ngxSlickItem *ngFor="let planning of plannings" class="slide">
        <div class="row">
          <div class="col font-weight-bold" style="min-height: 24px;">
            {{ planning.dayText }}
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col">
            <div
              class="w-100 p-3 planning-days"
              [ngStyle]="{
                   'background-color': planning.dayColor
                  }"
            >
              <div class="row align-items-center" style="height: 50px;">
                <div class="col-3">
                  <i class="bi bi-calendar-week text-light" style="font-size: 2.2rem;"></i>
                </div>
                <div class="col text-black font-weight-bold">
                  {{ planning.day | titlecase }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-1" >
          <div class="col">
            <div class="w-100 m-0 planning-days" style="border: 2px solid #dadada ; min-height: 50px;">
              <div
                *ngFor="let consultation of planning.consultations; let i = index"
                class="row align-items-center"
                style="min-height: 50px;"
              >
                <div class="col px-4">
                  <div *ngIf="i > 0" class="dropdown-divider border-dark m-0"></div>
                  <div class="row align-items-center"

                  >
                    <div
                      [ngClass]="{
                            'col-4': consultation.service,
                            'col': !consultation.service
                          }"
                    >{{ consultation.hour.from }} <br> {{ consultation.hour.to }}</div>
                    <div *ngIf="consultation.service" class="col py-2">
                      <p *ngIf="consultation.service !== 'audiotel'" class="m-0" style="font-size: 16px;">{{ consultation.type }}</p>
                      <app-sva
                        *ngIf="!!(definitions | async) && consultation.service === 'audiotel'"
                        [definitions]="definitions | async"
                        [zoom]="40"
                        (click)="showSVAModal(consultation.hour.from, consultation.service)"
                        [ngClass]="{
                            'btn btn-planning-sva': consultation.hour.from === 'Maintenant' && consultation.service === 'audiotel'
                          }"
                      ></app-sva>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="planning.consultations.length === 0"
                class="row align-items-center"
                style="min-height: 50px;"
              >
                <div class="col px-4" style="line-height: normal; font-size: 16px;">
                  Ce consultant n'a pas défini de planning
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>

import {AfterViewInit, Component, ElementRef, Injectable, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HistoryService} from '../_services/history.service';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {FormBuilder, Validators} from '@angular/forms';
import {RegisterService} from '../_services/register.service';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Definitions} from '../_models/definitions';
import {Account} from '../_models/account';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {NoticeService} from '../_services/notice.service';
import {Table} from 'primeng/table';
import * as dayjs from 'dayjs';
import {Router} from '@angular/router';
import {jsPDF} from 'jspdf';
import {HomeService} from '../_services/home.service';
declare var $: any;

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {


  purchases: any;
  dtOptions: any;
  public data: any;
  formClaim: any;
  stepClaim = 1;
  dtTrigger: any;
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  userInfo: Account;
  definitionsInfo: Definitions;
  purchase: any;
  merchantId = environment.merchantId;
  customerId = localStorage.getItem('customerId');
  showAddNotice = false;
  showReadNotice = false;
  consultantId: any;
  consultantFirstName: any;
  customerRemark: any;
  consultantAnwser: any;
  customerNnoticeNotation: any;
  formClaimInvalid = false;
  loading = false;
  multiSortMeta: any;
  downloadPaymentTitle: any;

  constructor(
    config: NgbRatingConfig,
    private historyService: HistoryService,
    private registerService: RegisterService,
    private noticeService: NoticeService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    public homeService: HomeService
  ) {

    this.account = store.select('account');
    this.definitions = store.select('definitions');
    config.max = 5;
    config.readonly = true;
  }

  get f() {
    return this.formClaim.controls;
  }

  ngOnInit(): void {

    $('#modalConsultantReviews')
      .on('hidden.bs.modal', () => {
        this.showAddNotice = false;
        this.showReadNotice = false;
        this.reloadData();

      } );

    this.formClaim = this.fb.group({
      customerClaim: ['', [
        Validators.required]],
    });

    this.account.subscribe(account => {

      this.userInfo = account;

    });


    this.definitions.subscribe(definitions => {

      this.definitionsInfo = definitions;

    });

    this.downloadPaymentTitle = 'Télécharger le ticket';
    this.multiSortMeta = [{field: 'transaction_datetime', order: 1}];
    this.getHistory();

  }


  getHistory() {
    const startDay = new Date();
    const endDay = new Date();
    endDay.setDate(startDay.getDate() + 30);

    const startDayStr = startDay.getFullYear() + '-' + ('0' + (Number(startDay.getMonth()) + 1)).slice(-2) + '-' + ('0' + Number(startDay.getDate())).slice(-2) + ' 00:00';
    const endDayStr = endDay.getFullYear() + '-' + ('0' + (Number(endDay.getMonth()) + 1)).slice(-2) + '-' + ('0' + Number(endDay.getDate())).slice(-2) + ' 23:59';

    const startDate  = startDayStr;
    const endDate = endDayStr;

    const params = new  HttpParams({
      fromObject : {
        merchantId: String(this.merchantId),
        customerId: this.customerId
      }
    });

    this.historyService.getHistory(params).subscribe(
      (res: any) => {

        this.purchases =  res.data.my_purchases_history;

      }, error => {
        $('#modalClaim').modal('hide');
        $('#modalConsultantReviews').modal('hide');
        $('#modalClaimError').modal('show');

      });
  }


  reloadData() {

    const params = new  HttpParams({
      fromObject : {
        merchantId: String(this.merchantId),
        customerId: this.customerId
      }
    });

    this.historyService.getHistory(params).subscribe(
      (res: any) => {


        this.purchases =  res.data.my_purchases_history;



      }, error => {
        $('#modalClaim').modal('hide');
        $('#modalConsultantReviews').modal('hide');
        $('#modalClaimError').modal('show');

      });
  }


  requestClaim(purchase) {
    this.stepClaim = 1;
    this.formClaim.reset();
    $('#modalClaim').modal('show');
    this.purchase = purchase;
    this.customerRemark = purchase.customer_remark;
    this.consultantAnwser = purchase.consultant_anwser;
    this.customerNnoticeNotation = purchase.customer_notice_notation;

    if (purchase.complaint_description) {
      this.stepClaim = 0;
    }

  }

  onSubmit() {

    this.formClaimInvalid = true;
    const newDate = new Date(this.purchase.datetime);

    const date =  ('0' + Number(newDate.getDate())).slice(-2) + '/' + ('0' + (Number(newDate.getMonth()) + 1)).slice(-2) + '/' + newDate.getFullYear();
    const hour =  (Number(newDate.getHours())) + ':' + (Number(newDate.getMinutes()));

    // Save claim in db

    // Send mail to merchant
    const baseUrl = window.location.origin;
    const dataMailSend = {
      merchantId: environment.merchantId,
      recipientMail: this.definitionsInfo?.provider_mail,
      templateParams: {
        customerName: this.userInfo.customerName || '',
        customerFirstName: this.userInfo.customerFirstName || '',
        customerEmail: this.userInfo.customerEmail || '',
        principalCallNumber: this.userInfo.principalCallNumber || '',
        transactionId: this.purchase.id || '',
        transactionDate: date || '',
        transactionHour: hour || '',
        prestationType: '',
        prestationAmount: this.purchase.amount + ' €' || '',
        prestationDuration: this.purchase.service_duration || '',
        complaintMessage: this.formClaim.value.customerClaim || ''
      },
      template: 'url_template_customer_complaint',
      subject: 'Nouvelle réclamation',
    };


    this.registerService.sendEmail(dataMailSend).subscribe(
      (res: any) => {
        if (res.statusCode === undefined) {
          // ...
          console.log('sendemail');
        } else {
          $('#modalClaim').modal('hide');
          $('#modalClaimError').modal('hide');
          $('#modalConsultantReviews').modal('hide');
          console.log('erreur sendemail');

        }
      },
      error => {
        $('#modalClaim').modal('hide');
        $('#modalConsultantReviews').modal('hide');
        $('#modalClaimError').modal('show');
      }
    );

    const dataComplaint = {
      merchantId: this.merchantId,
      customerId: parseInt(this.customerId),
      historyId: this.purchase.transaction_id || '',
      complaintDescription: this.formClaim.value.customerClaim || ''
    };

    // Save complaint

    this.historyService.setComplaint(dataComplaint).subscribe(
      (res: any) => {
        if (res.statusCode === undefined) {
          // ...
          console.log('sendComplaint');
          this.formClaimInvalid = false;
          $('#modalClaim').modal('hide');
          $('#modalConsultantReviews').modal('hide');

          $('#modalClaimError').modal('hide');
          this.reloadData();


        } else {
          $('#modalClaim').modal('hide');
          $('#modalConsultantReviews').modal('hide');
          $('#modalClaimError').modal('show');
          console.log('erreur send complaint');

        }
      },
      error => {
        $('#modalClaim').modal('hide');
        $('#modalConsultantReviews').modal('hide');
        $('#modalClaimError').modal('show');
      }
    );



  }

  claimSecondStep() {
    this.stepClaim = 2;
  }

  openReview(purchase) {


    this.purchase = purchase;
    console.log('purchase transaction_id', purchase.transaction_id);

    if (purchase.consultant_answer_status === 'CONSULTANT_ANSWER_VALIDATED_FOR_CUSTOMER') {

      const updateNotice = {
        sessionId: localStorage.getItem('customerSessionId'),
        transactionId: String(this.purchase.transaction_id)
      };

      this.noticeService.customerNoticeCustomerUpdate(updateNotice).subscribe(
        (res: any) => {
        },
        error => {
          $('#modalClaim').modal('hide');
          $('#modalConsultantReviews').modal('hide');
          $('#modalClaimError').modal('show');
        }
      );
    }

    console.log('purchase', purchase);
    if (purchase.is_notice_authorized) {
      this.showAddNotice = true;
    } else  {
      this.showReadNotice = true;
    }


    $('#modalConsultantReviews').modal('show');
  }


  downloadPayment(purchase) {

    const transactionDate = dayjs(purchase.transaction_datetime.slice(0, 16).replaceAll('-', '/'));
    const dateNow = dayjs();
    let typePrestation = purchase.consultation_mode;
    const link = window.location.origin + '' + this.router.url;

    if (purchase.consultation_mode = 'CB') {
      typePrestation = 'Conversation Privée';
    }
    if (purchase.consultant_pseudo == null) {
      purchase.consultant_pseudo = '-';
    }
    if (purchase.service_duration == null) {
      purchase.consultant_pseudo = '--:--:--';
    }

    if (purchase.cost == null) {
      purchase.cost = '-';
    }

    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text('E-mail envoyé sur votre adresse le ' + transactionDate.format('DD/MM/YYYY') + ' à ' + transactionDate.format('HH:mm'), 10, 40);
    doc.text('Expéditeur : ' + this.definitionsInfo?.provider_mail, 10, 50);
    doc.text('Objet : Votre paiement a été effectué', 10, 70);
    doc.text('Bonjour,', 10, 80);
    doc.text('Nous avons débité la somme de ' + purchase?.amount + ' € de votre moyen de paiement référence ' + purchase.partial_number + ' à ' + transactionDate.format('HH:mm') + ', ce jour.', 10, 90);
    doc.text('Prestataire : ' + this.definitionsInfo?.sms_brand + '.', 10, 100);
    doc.text('Prestation : ' + typePrestation + ', effectuée avec ' + purchase?.consultant_pseudo + ', de ' + purchase?.service_duration + ' min au tarif de ' + purchase?.cost + ' €/min.', 10, 110);
    doc.text('Lien pour accéder à l\'historique des paiements : ', 10, 120);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink('lien', 88, 120, {url: link});
    doc.setTextColor(0, 0, 0);
    doc.text('Vous avez des questions ? ' + this.definitionsInfo?.sms_brand + ' répond au ' + this.definitionsInfo?.provider_call_number, 10, 130);
    doc.text('Document généré  le ' + dateNow.format('DD/MM/YYYY') + ', à ' + dateNow.format('HH:mm'), 10, 10);
    doc.save('paiement_' + transactionDate.year() + '_' + transactionDate.month() + '_' + transactionDate.day() + '_' + transactionDate.hour() + '_' + transactionDate.minute() + '.pdf');


  }



}

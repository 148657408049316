import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ChatService {

  private apiKey = environment.apiKey;
  private apiUrlChat = environment.apiUrlChat;
  constructor(
    private http: HttpClient,
  ) { }


  // this function sets the status of the conversation
  // between a customer and a consultant
  setConversationStatus(customer_id: number, consultant_id: number, status: boolean) {
    const headers = new HttpHeaders().set('x-api-key', `${this.apiKey}`);
    const url = `${this.apiUrlChat}/set-conversation-status`
    const payload = {
      customer_id: customer_id,
      consultant_id: consultant_id,
      status: status
    }
    return this.http.post(url, payload, {
      headers
    })
  }


  /**
   * Returns the availability of a consultant given an id
   * @param consultantId The id of the consultant
   * @returns The availability of the consultant
   */
  getConsultantAvailability(consultantId: number): Promise<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.apiKey}`);
    const url = `${this.apiUrlChat}/get-consultant-availability`
    const payload = {
      consultant_id: consultantId
    };
    return this.http.post(url, payload, { headers }).toPromise();
  }

}


<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6">
      <div class="card text-white bg-primary mb-3">
        <div class="card-body">
          <a  [routerLink]="['/purchases']"  class="btn btn-primary btn-lg">Mes Achats
          </a>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card text-white bg-primary mb-3">
        <div class="card-body">
          <a    class="btn btn-primary btn-lg" (click)="this.paiementService.checkCard()">Mes Cartes de paiements</a>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-6">
      <div class="card text-white bg-primary mb-3">
        <div class="card-body">
          <a [routerLink]="['/mes-infos']" class="btn btn-primary btn-lg">Mes Infos</a>
        </div>
      </div>
    </div>
  </div>
</div>






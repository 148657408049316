import {Component, Input, OnInit} from '@angular/core';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {NoticeService} from '../../_services/notice.service';
import {LoginService} from '../../_services/login.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

declare var $: any;
import * as dayjs from 'dayjs';
import {split} from "ts-node";


@Component({
  selector: 'app-show-reviews',
  templateUrl: './show-reviews.component.html',
  styleUrls: ['./show-reviews.component.css']
})
export class ShowReviewsComponent implements OnInit {
  consultantNotice: any;
  consultantReviews: any;
  transactions: any;
  @Input() consultantId: string;
  @Input() consultantImageUrl: string;
  isLoggedIn$: Observable<boolean>;
  isValidtoken: boolean;
  error: boolean;
  transactionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    config: NgbRatingConfig,
    public noticeService: NoticeService,
    public loginservice: LoginService
  ) {
    config.max = 5;
    config.readonly = true;
  }


  ngOnInit(): void {
    this.isLoggedIn$ = this.loginservice.isLoggedIn;
    this.error = false;

    this.consultantNotice = [];
    this.consultantReviews = [];
    this.transactions = [];
    this.isValidtoken = localStorage.getItem('customerSessionId') !== null;
    this.getCustomerTransactions();
    this.getConsultantNotice();
    this.getConsultantReviews();

    $('#modalConsultantReviews').on('shown.bs.modal', () => {

      this.noticeService.showAllNotice.next(true);
      this.getCustomerTransactions();

    });

    $('#modalConsultantReviews').on('hidden.bs.modal', () => {

      $('.hiddenNotice').hide();
      $('#addNotice').hide();
      $('#btnShowAllNotices').show();
      $('#allNotices').show();
      $('#reviews').css('height', '100%');

    });


  }

  getConsultantNotice() {
    const merchantId = environment.merchantId;
    this.noticeService.consultantNotice(merchantId, this.consultantId).subscribe(
      (result: any) => {
        this.consultantNotice = result.body;
        this.consultantNotice.forEach(notice => {
          const res = this.formatDate(notice);
          notice.NoticeDateYear = res?.year;
          notice.NoticeDateHour = res?.hourMin;
        });
        this.error = false;
        if (this.consultantNotice.length === 0) {
          $('#aucunAvis').html('Aucun avis');
        }

      },
      (error: any) => {
        this.error = true;
        $('#modalConsultantReviews dev').removeClass('modal-lg');
        $('#exampleModalLabel').html('Les avis');

      }
    );

  }

  getConsultantReviews() {
    const merchantId = environment.merchantId;
    this.noticeService.consultantReviews(merchantId, this.consultantId).subscribe(
      (result: any) => {
        this.consultantReviews = result.body;
        this.error = false;

      },
      (error: any) => {
        this.error = true;
        $('#modalConsultantReviews dev').removeClass('modal-lg');
        $('#exampleModalLabel').html('Les avis');

      }
    );

  }


  getCustomerTransactions() {
    const merchantId = environment.merchantId;
    this.noticeService.customerTransactions(merchantId, localStorage.getItem('customerId'), localStorage.getItem('customerSessionId'), this.consultantId).subscribe(
      (result: any) => {
        if (result.body[0]) {
          this.transactions = result.body[0];
          this.transactionStatus.next(true);
          this.error = false;

        }
      },
      (error: any) => {

        this.error = true;
        $('#modalConsultantReviews div').removeClass('modal-lg');
        $('#exampleModalLabel').html('Les avis');
      }
    );

  }

  showAllNotices() {
    $('#reviews').css('height', '100%');
    $('.hiddenNotice').show();
    $('#btnShowAllNotices').hide();
  }

  addNotice() {
    $('#allNotices').hide();
    $('#addNotice').show();
    this.noticeService.showAddNotice.next(true);
    this.noticeService.showAllNotice.next(false);

  }

  formatDate(elm) {
    const year = dayjs(elm.notice_date_time.toString().slice(0, 10)).format('DD/MM/YYYY');
    const  hourMin = dayjs(elm.notice_date_time.toString().slice(0, 19)).format('HH:mm');
    return  {
      year,
      hourMin
    };

  }
}

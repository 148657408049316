import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-conversation',
  templateUrl: './private-conversation.component.html'})
export class PrivateConversationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="col-md-8">
  Mon N° de tél. principal :
  {{formatedPrincipalCallNumber()}}
  <span class="flag-icon  {{principalCallNumberCountry}}"></span>
  {{principalCallNumber}}
  <span *ngIf="principalCallNumber">
  <ng-container *ngIf="customerPrincipalCallNumberValidated;else second">
    <i class="fa fa-check-circle text-success" title="Le N° est validé" ></i>
  </ng-container>
  <ng-template #second>
    <i class="fa fa-check-circle text-danger" title="Le N° n'est pas validé !" ></i>
  </ng-template>
  </span>
</div>
<div class="col-md-2 text-center">
  <ng-container *ngIf="(!customerPrincipalCallNumberValidated) && principalCallNumber">
    <button type="button"  class="btn btn-warning info-delete-btn-mobiyo" (click)="phoneValidation()" data-toggle="modal" data-target="#modalChangePrimaryPhone">Valider</button>
  </ng-container>
</div>
<div class="col-md-2">
  <ng-container *ngIf="principalCallNumber;else second2">
    <button type="button" class="btn btn-warning info-add-update-btn-mobiyo" (click)="openModalPrincipalPhoneNumber()" data-toggle="modal" data-target="#modalChangePrimaryPhone">
      Modifier
    </button>
  </ng-container>
  <ng-template #second2>
    <button type="button" class="btn btn-warning info-add-update-btn-mobiyo"  (click)="openModalPrincipalPhoneNumber()" data-toggle="modal" data-target="#modalChangePrimaryPhone">
      Ajouter
    </button>
  </ng-template>
</div>
<div class="modal fade modalUpdatePhone" id="modalChangePrimaryPhone" tabindex="-1" aria-labelledby="" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="change-email-modal-label">
          <ng-container *ngIf="phoneValidationStep;else second">
            Valider mon numéro de téléphone à appeler en priorité
          </ng-container>
          <ng-template #second>
            <ng-container *ngIf="principalCallNumber;else third">
              Enregistrer mon numéro de téléphone à appeler en priorité
            </ng-container>
          </ng-template>
          <ng-template #third>
            Ajouter mon numéro de téléphone à appeler en priorité
          </ng-template>
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body">
          <div class="container" *ngIf="openAddPhoneForm == true">
            <form [formGroup]="formAddPhone" (ngSubmit)="onSubmitAddPhone()">
              <ng-container *ngIf="principalCallNumber;else second4">
                <p>
                 Vous pouvez modifier ci-dessous le numéro de téléphone que {{infoDefinitions?.sms_brand}} appelera en priorité pour vous passer un consultant.
                </p>
              </ng-container>
              <ng-template #second4>
                <p>
                  Pour recevoir les appels des consultants d'{{infoDefinitions?.sms_brand}}, merci de bien vouloir saisir votre de numéro de téléphone à appeler en priorité.
                </p>
              </ng-template>
                  <p>Numéro de téléphone à appeler en priorité</p>
                  <div class="row align-items-center">
                    <div class="col-sm-2" style="font-size: 30px">
                      <ngx-select-flags
                        [selectedCountryCode]="selectedCountryCode"
                        [countryCodes]="countryCodes"
                        [showLabels]="false"
                        (changedCountryCode)="changeCodeNumber($event)"
                      ></ngx-select-flags>
                    </div>
                    <div class="col-sm-10">
                      <input
                        value="{{principalCallNumberLocalFormat}}"
                        type="text"
                        formControlName="customerCallNumber"
                        class="form-control"
                        [ngClass]="{'is-invalid':f.customerCallNumber.errors && !f.customerCallNumber.errors.required}"
                        [class.is-valid]=" f.customerCallNumber.valid"
                        required>
                    </div>
                  </div>

                  <div *ngIf="f.customerCallNumber.errors" class="row mt-3" >
                    <div *ngIf="f.customerCallNumber.errors.pattern" class="col-sm-1">
                      <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
                    </div>
                    <div *ngIf="f.customerCallNumber.errors.pattern" class="col-sm-10 align-content-center">
                      <p class="col-sm-12 text-danger">Ce numéro de téléphone n’est pas valide</p>
                    </div>
                  </div>
                <div class="row mt-3">
                  <div class="col-sm-12 text-right">
                    <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="formAddPhone.invalid || disabledUpdatePhoneBtn" >Enregistrer mon numéro
                      <span class="spinner-border spinner-border-sm align-self-center" role="status" aria-hidden="true" [hidden]="!submitting"></span>
                    </button>
                  </div>
                </div>

            </form>
          </div>


          <div class="container" *ngIf="openValidateFormMobile == true" >
            <form [formGroup]="formMobilePhoneCode" (ngSubmit)="onSubmitCheckCode()">
                <p>Valider mon numéro de téléphone mobile</p>
                <p>{{infoDefinitions?.sms_brand}} vient de vous envoyer un SMS au <br> <span class="flag-icon  {{principalCallNumberCountry}}"></span> {{principalCallNumber}} avec un code à 4 chiffres. Merci de le saisir ci-dessus.</p>
                  <p>Code</p>
                  <div class="row">
                    <div class="col-sm-4">
                      <input
                        [class.is-invalid]=" fMobile.customerCallNumberCode.errors && !fMobile.customerCallNumberCode.errors.required"
                        [ngClass]="{ 'is-valid': fMobile.customerCallNumberCode.valid }" class="form-control"
                        type="text"
                        formControlName="customerCallNumberCode"
                        class="form-control"
                      >
                    </div>
                    <div class="col-sm-8" *ngIf="fMobile.customerCallNumberCode.errors && !fMobile.customerCallNumberCode.errors.required">
                      <div class="row">
                        <div class="col-sm-2" *ngIf="fMobile.customerCallNumberCode.errors">
                          <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
                        </div>
                        <div class="col-sm-10 my-auto" *ngIf="fMobile.customerCallNumberCode.errors">
                          <p class="text-danger">
                            Ce code n'est pas valide
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="row mt-3">
                  <div class="col-sm-10">
                    <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="!formMobilePhoneCode.valid" >Valider le code que j'ai reçu</button>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-sm-9 sendSMSCountDown" style="display: none">
                    <p></p>
                  </div>
                  <div class="col-sm-9 requestSMS ">
                    <p>Je n'ai pas reçu de sms ? &nbsp;&nbsp; <a class="nav-link-mobiyo" (click)="checkcode()">Recevoir un nouveau code ici !</a></p>
                  </div>
                </div>

              <div class="row mt-3">
                <div class="col-sm-12">
                  <a class="nav-link-mobiyo updatePhone" (click)="changeNumber()">Modifier mon numéro de téléphone</a>
                </div>
              </div>
            </form>
          </div>


          <div class="container" *ngIf="openValidateFormFilaire == true">
            <form [formGroup]="formWiredPhoneCode" (ngSubmit)="onSubmitWiredCheckCode()">
                <p  class="w-auto">Valider mon numéro de téléphone Filaire</p>
                <p>{{infoDefinitions?.sms_brand}} est en train de vous appeler au <br><span class="flag-icon  {{principalCallNumberCountry}}"></span> {{principalCallNumber}} à partir du {{infoDefinitions?.provider_call_number}}  pour vous donner un code à 4 chiffres.</p>
                <p>Merci de le saisir ci-dessous</p>
                  <legend class="w-auto">Code</legend>
                  <div class="row mt-3">
                    <div class="col-sm-4">
                      <input
                        [class.is-invalid]=" fWired.customerCallNumberCode.errors && !fWired.customerCallNumberCode.errors.required "
                        [ngClass]="{ 'is-valid': fWired.customerCallNumberCode.valid }" class="form-control"
                        formControlName="customerCallNumberCode"
                        class="form-control"

                      >
                    </div>
                    <div class="col-sm-8" *ngIf="fWired.customerCallNumberCode.errors && !fWired.customerCallNumberCode.errors.required">
                      <div class="row">
                        <div class="col-sm-2" *ngIf="fWired.customerCallNumberCode.errors">
                          <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
                        </div>
                        <div class="col-sm-10 my-auto" *ngIf="fWired.customerCallNumberCode.errors">
                          <p class="text-danger">
                            Ce code n'est pas valide
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="row  mt-3">
                  <div class="col-sm-10">
                    <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="formWiredPhoneCode.invalid" >Valider le code </button>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="updatePhone">
                  <div class="col-sm-9 sendSMSCountDown" style="display: none">
                    <p></p>
                  </div>
                  <div class="col-sm-9 requestSMS">
                    <p>Je n'ai pas reçu d'appel d'{{infoDefinitions?.sms_brand}}?  &nbsp;&nbsp;  <a class="nav-link-mobiyo" (click)="checkcode(true)">Appelez-moi de nouveau !</a></p>
                  </div>
                </div>
              <div class="row mt-3">
                <div class="col-sm-12" *ngIf="updatePhone">
                  <a class="nav-link-mobiyo updatePhone" (click)="changeNumber()">Modifier le numéro</a>
                </div>
              </div>
            </form>
          </div>

        <div class="container" *ngIf="openModalSuccessAddPhone == true">
          <div class="row">
            <div class="col-md-12">
              <p >
                Félicitations ! Votre N° de téléphone <span class="flag-icon  {{principalCallNumberCountry}}"></span>
                {{principalCallNumber}} est validé !
              </p>
            </div>

          </div>
        </div>

          <div class="container" *ngIf="openModalErrorMobilePhoneCodeNotFound == true">
            <div class="row">
              <div class="col-md-2">
                <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-md-9">
                <p >
                  Le code que vous avez saisit est erroné, merci de saisir le code que vous avez reçu ou recevez un nouveau code en cliquant sur « en recevoir un nouveau ici ! »
                </p>
              </div>
            </div>
          </div>

          <div class="container" *ngIf="openModalErrorMobilePhone == true">
            <div class="row">
              <div class="col-md-2">
                <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-md-9">
                <p >
                  Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer un SMS avec le code d'activation.
                </p>
              </div>
            </div>
            <p>Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}</p>
          </div>


          <div class="container" *ngIf="openModalErrorAddPhone == true">

            <div class="row">
              <div class="col-md-2">
                <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-md-9">
                <p >
                  Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandé.
                </p>
              </div>
            </div>
            <p>Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}</p>
          </div>


          <div class="container" *ngIf="openModalErrorWiredPhone == true">

          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
            </div>
            <div class="col-md-9">
              <p >
                Une erreur vient de survenir et nous ne sommes pas en mesure de vous appeler pour vous communiquer le code d'activation.
              </p>
            </div>
          </div>
            <p>Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}</p>
          </div>


          </div>

      <div class="modal-footer" *ngIf="openModalErrorAddPhone == true || openModalErrorWiredPhone==true || openModalErrorMobilePhone==true || openModalErrorMobilePhoneCodeNotFound == true">

        <button *ngIf="openModalErrorMobilePhoneCodeNotFound == true" type="button" class="btn btn-success"  (click)="openModalCheckCode()">Recevoir un nouveau ici !</button>
        <button *ngIf="openModalErrorAddPhone == true || openModalErrorWiredPhone==true || openModalErrorMobilePhone==true" type="button" class="btn btn-success text-center" data-dismiss="modal" >Continuer</button>

      </div>

    </div>
  </div>
</div>


<div class="finish-chat-payment-backdrop">
</div>

<div class="finish-chat-payment-container">
    <div class="finish-chat-payment-content">
        <p *ngIf="paymentStatus != 'in_process'" class="close-modal" (click)="closeModal()">X</p>
        <!-- Processing payment loader -->
        <app-spinner [height]="80" [color]="'#92278E'" *ngIf="paymentStatus == 'in_process'"></app-spinner>

        <!-- Payment succeeded -->
        <div class="payment-status" *ngIf="paymentStatus == 'done'">
            <svg class="p-result" viewBox="0 0 32 32">
                <g data-name="Layer 28" id="Layer_28">
                    <path fill="#66bb6a"
                        d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" />
                    <path fill="#66bb6a"
                        d="M13.67,22a1,1,0,0,1-.73-.32l-4.67-5a1,1,0,0,1,1.46-1.36l3.94,4.21,8.6-9.21a1,1,0,1,1,1.46,1.36l-9.33,10A1,1,0,0,1,13.67,22Z" />
                </g>
            </svg>
        </div>

        <!-- Consultant image will only be shown if the payment is done -->
        <div class="consultant-avatar" *ngIf="paymentStatus == 'done'">
            <img [src]="consultant?.consultantpictureurl" />
        </div>

        <!-- Payment failed -->
        <div *ngIf="paymentStatus == 'failed'">
            <svg class="p-result" viewBox="0 0 16 16">
                <path fill="#e57373" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path fill="#e57373"
                    d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
        </div>

        <h3 class="status-message">{{message}}</h3>

        <!-- Conversation summary -->
        <div *ngIf="paymentStatus == 'done'" class="">
            <div class="divider py-1"></div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <tr>
                            <td>Consultant </td>
                            <td>{{consultant?.name}}</td>
                        </tr>
                        <tr>
                            <td>Durée </td>
                            <td>{{getConversationSummary()['conversation_time']}}</td>
                        </tr>
                        <tr>
                            <td>Montant deduit </td>
                            <td>{{getConversationSummary()['paid_amount']}} €</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-md-10">
  Mon mot de passe : *********
</div>
<div class="col-md-2">
  <button type="button" class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#change-password-modal">Modifier</button>
</div>
<div class="modal fade" id="change-password-modal" tabindex="-1" aria-labelledby="change-password-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="change-password-modal-label">Modifier mon mot de passe</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body" *ngIf="!success && !error">
          <p>
            Pour modifier le mot de passe de votre compte {{infoDefinitions?.sms_brand}}, vous devez saisir votre mot de passe actuel, puis le nouveau. N'oubliez pas de cliquer sur le bouton "Enregitrer les modifications" quand vous avez terminé.
          </p>
          <div class="form-group">
            <label for="current-password">Mot de passe actuel</label>
            <input
              type="password"
              formControlName="customerOldPassword"
              class="form-control"
              id="current-password"
              autocomplete="off"
              [class.is-valid]="f.customerOldPassword.valid"
              [class.is-invalid]="f.customerOldPassword.invalid"
            />
          </div>
          <div class="form-group">
            <label for="new-password">Nouveau mot de passe</label>
            <input
              type="password"
              formControlName="customerNewPassword"
              class="form-control"
              id="new-password"
              autocomplete="off"
              [class.is-valid]="f.customerNewPassword.valid"
              [class.is-invalid]="f.customerNewPassword.invalid"
            />
          </div>
          <div class="form-group">
            <label for="new-password">Nouveau mot de passe</label>
            <input
              type="password"
              formControlName="customerNewPassword2"
              class="form-control"
              id="new-password2"
              autocomplete="off"
              [class.is-valid]="f.customerNewPassword2.valid"
              [class.is-invalid]="f.customerNewPassword2.invalid"
            />
          </div>
          <div class="row" *ngIf="
            f.customerOldPassword.errors?.pattern ||
            f.customerOldPassword.errors?.mustDifferent ||
            f.customerNewPassword.errors?.pattern ||
            f.customerNewPassword.errors?.mustDifferent ||
            f.customerNewPassword.errors?.mustMatch ||
            f.customerNewPassword2.errors?.pattern ||
            f.customerNewPassword2.errors?.mustMatch"
          >
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10" style="padding-right: 0px">
              <p *ngIf="f.customerOldPassword.errors?.pattern">
                Votre mot de passe actuel est erroné !
              </p>
              <p *ngIf="f.customerNewPassword.errors?.pattern">
                Votre nouveau mot de passe est erroné !
              </p>
              <p *ngIf="f.customerOldPassword.errors?.mustDifferent || f.customerNewPassword.errors?.mustDifferent">
                Votre nouveau mot de passe est identique à l'actuel !
              </p>
              <p *ngIf="f.customerNewPassword.errors?.mustMatch || f.customerNewPassword2.errors?.mustMatch">
                Votre deuxième mot de passe est différent du premier !
              </p>
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="success && !incorrect">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-check-circle fa-4x text-success"></i>
            </div>
            <div class="col-10 align-self-center">
                Votre mot de passe est modifié.
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="success && incorrect">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10 align-self-center">
              Votre ancien mot de passe est incorrect.
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="error">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10 align-self-center text-danger">
              Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
            </div>
          </div>
          <br>
          <p>
            Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" type="submit" [disabled]="!form.valid || this.submitting" *ngIf="!success && !error">Enregistrer les modifications</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="success">J'ai compris</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="error">Continuer</button>
        </div>
      </form>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {PaiementService} from '../../_services/paiement.service';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.component.html',
  styleUrls: ['./account-home.component.css']
})
export class AccountHomeComponent implements OnInit {

  constructor(
    public paiementService: PaiementService,
  ) {
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {LoginService} from './login.service';

@Injectable()
export class CheckSessionGuard implements CanActivate {
  constructor(private authService: LoginService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    let isSessionActive;
    if (localStorage.getItem('customerSessionId')) {
    this.authService.checkSession(localStorage.getItem('customerSessionId')).subscribe(
      (result: any) => {
        isSessionActive = result.isSessionActive;
        if (isSessionActive === false)  {
          this.authService.logout();
          return false;
        }
      },
      (error: any) => {
        console.error('error : ', error);
      }
    );
    }
    return true;
  }
}

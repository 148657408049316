import { Component, OnInit ,ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConsultantService } from '../../_services/consultant.service';
import { Observable } from 'rxjs';
import { LoginService } from '../../_services/login.service';
import { environment } from '../../../environments/environment';
import { NoticeService } from '../../_services/notice.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Account } from '../../_models/account';
import { AccountService } from '../../_services/account.service';
import { Definitions } from '../../_models/definitions';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/fr';
import { filter } from 'rxjs/operators';
import { PaiementService } from '../../_services/paiement.service';
import { CryptoService } from '../../_services/crypto.service';
import { HomeService } from '../../_services/home.service';
import { paidPack as PaidPackSchema } from '../../_models/paidPack';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExtendConversation } from 'src/app/_services/extend-conversation.service';
import Swal from 'sweetalert2'
declare var $: any;


@Component({
  selector: 'app-consultant-profile',
  templateUrl: './consultant-profile.component.html',
  styleUrls: ['./consultant-profile.component.css']
})
export class ConsultantProfileComponent implements OnInit {

  private account: Observable<Account>;
  public definitions: Observable<Definitions>;
  private isPrincipalCallNumberValidate: boolean;
  data: any;
  isLoggedIn$: Observable<boolean>;
  isValidtoken: boolean;
  private consultantId$ = this.consultantservice.consultantId;
  private consultantCallNumber$ = this.consultantservice.consultantCallNumber;
  private consultantName$ = this.consultantservice.consultantName;
  public showAddNotice: any;
  public showAllNotice: any;
  consultantInfo: any;
  listConsu = [];
  consultantID: any;
  serviceTypeCrypto: any;
  plannings = [];
  public languagesText: { code: string, text: string }[];
  public benefits: any[];
  public slideConfig: {};
  public status: string;
  public statusColor: string;
  public statusCode: number;
  private isLoggedIn: boolean;
  previousUrl: string;
  customerId: string;
  isConversationExtended: boolean = false;
  emptyCard: boolean;
  merchandId = environment.merchantId;
  activateLink = false;
  serviceType: any;
  voyanceTypes: any;
  timeZoneClient: string;
  customerInfo: {} = null;
    /**
   * Chat modal
   */
    selectedConsultant: any = null;
    chatModalIsVisible: Boolean = false;

    // Payment

    paymentModalIsVisible: boolean = false;
    paidPack: PaidPackSchema = null;
    finishPaymentModalIsVisible: Boolean = false;


  public findLanguage = (lan: string) => this.languagesText.find(({ code }) => code === lan);

  constructor(
    private route: ActivatedRoute,
    private loginservice: LoginService,
    private consultantservice: ConsultantService,
    private noticeService: NoticeService,
    private paiementService: PaiementService,
    private loginService: LoginService,
    private router: Router,
    private store: Store<AppState>,
    private accountService: AccountService,
    private config: NgbRatingConfig,
    private cryptoService: CryptoService,
    public homeService: HomeService,
    private http: HttpClient,
    private extendConversation: ExtendConversation
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.account.subscribe((account) => {
      this.isPrincipalCallNumberValidate = account?.isPrincipalCallNumberValidate;
      this.customerInfo = account
    });
    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false
          }
        },
        {
          breakpoint: 600,
          settings: 'unslick',
        },
        {
          breakpoint: 480,
          settings: 'unslick'
        }
      ]
    };
    config.max = 5;
    config.readonly = true;
    this.languagesText = [
      {
        code: 'EN',
        text: 'I also speak english!'

      },
      {
        code: 'ES',
        text: '¡Yo también hablo español!'
      },
      {
        code: 'IT',
        text: 'Parlo anche italiano'
      }
    ];

    this.voyanceTypes = {
      tarologist_type: 'Tarot',
      runes_type: 'Runes',
      astrologer_type: 'Astrologie',
      fortune_teller_type: 'Cartomancie',
      oracle_type: 'Oracles',
      numerologist_type: 'Numérologie',
      psychic_seer_type: 'Voyant',
      pendulum_type: 'Pendule',
      dowsing_type: 'Radiesthésiste',
      medium_type: 'Medium'
    }

    this.benefits = [];
    dayjs.locale('fr');
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
    this.customerId = localStorage.getItem('customerId');
  }

  ngOnInit(): void {
    this.timeZoneClient = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dayjs.locale('fr');
    this.isLoggedIn$ = this.loginservice.isLoggedIn;
    this.isLoggedIn$.subscribe((value) => {
      this.isLoggedIn = value;
    });
    this.isValidtoken = localStorage.getItem('customerSessionId') !== null;
    this.consultantID = parseInt(this.cryptoService.get(localStorage.getItem('ctcx')));
    this.serviceTypeCrypto = this.cryptoService.get(localStorage.getItem('serviceType'));
    this.getConsutlantData(this.consultantID , this.serviceTypeCrypto);
    this.noticeService.showAllNotice.subscribe(res => {
      this.showAllNotice = res;
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

   getConsutlantData(consultantID , serviceTypeCrypto) {

    console.log(serviceTypeCrypto);

    if (serviceTypeCrypto == undefined ){
      serviceTypeCrypto = 'all';
    }

    this.consultantservice.sendGetConsutalntByID(environment.merchantId, environment.officeId, consultantID, this.timeZoneClient).subscribe(
      async (res: any) => {
        this.consultantInfo = res.body.data[0];
        const tooltipContents = {
          available: 'Je suis disponible en',
          busy: 'Je suis actuellement en consultation',
          unavailable: 'Je ne suis pas disponible actuellement en'
        };

        localStorage.setItem('cc', this.consultantInfo.conversationcost);

        const result =  this.consultantservice.handle(this.consultantInfo , false ,serviceTypeCrypto);
        this.status = result.statut;
        this.statusColor = result.colorClass;
        this.statusCode = result.statutCode;
        this.serviceType = result.service;
        console.log(this.consultantInfo);

        result.prestations.forEach(prestation => {
          const benefit = {
            type: prestation.name.toLowerCase(),
            text: undefined,
            status: prestation.active === 0 ? 'unavailable' : (result.statutCode === 0 ? 'available' : 'busy'),
            cost: undefined,
            className: '',
            tooltipContent: undefined,
            data: undefined
          };
          switch (prestation.name) {
            case 'Conversation': {
              benefit.text = 'Conversations Privées par CB';
              benefit.cost = this.consultantInfo.conversationcost;
              benefit.className = `fa-conv-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} conversation privée`;
              break;
            }
            case 'Audiotel': {
              benefit.text = 'Conversations par Téléphone';
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Audiotel`;
              break;
            }
            case 'Chat': {
              if( (result.statutCode === 0 || result.statutCode === 6) && prestation.active === 1)
              benefit.status = 'available';
              benefit.text = 'Voyance par CHAT';
              benefit.cost = this.consultantInfo.chatcost;
              benefit.className = `fa-chat-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Chat`;
              break;
            }
            case 'Mail': {
              benefit.text = 'Voyance par MAIL';
              benefit.cost = this.consultantInfo.mailcost;
              benefit.className = `fa-mail-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Mail`;
              break;
            }
            default:
              break;
          }
          if (benefit.status === 'busy') {
            benefit.tooltipContent = tooltipContents[benefit.status];
          }
          this.benefits.push(benefit);
        });
      },
      error => {
        console.log(error);
      }


    );

  }

  getClickedConsultantId(consultandId, consultantName, status, serviceType) {
    const statusList = ['available', 'je consulte immediatement'];
    // if (this.consultantInfo.prestations.length == 1 && this.consultantInfo.prestations[0] == 'Chat') {
    //   alert('Ce consultant ne fait que des consultations par chat!')
    //   return;
    // }

    if (statusList.includes(status.toLowerCase())) {
        console.log(this.benefits);

      const conditionAudiotel = this.benefits.find(({ type, status }) => type === 'audiotel' && status === 'available');
      if (conditionAudiotel) {
        this.activateLink = true;

        this.consultantservice.showSVAModalAction(this.activateLink);

      } else if (!this.isLoggedIn && !this.isValidtoken) {
        $('#loginModal').modal('show');
      } else {
        if (this.isPrincipalCallNumberValidate === true) {
          this.consultantId$.next(consultandId);
          this.consultantName$.next(consultantName);
          localStorage.setItem('selectedConsultantId', consultandId);
          localStorage.setItem('consultantName', consultantName);

          this.checkCard();

        } else {
          this.accountService.showPhoneAlert();
        }
      }
    }

  }

  ModalConsultantReviews() {
    this.noticeService.showAddNotice.subscribe(res => {
      this.showAddNotice = res;
    });

    $('#modalConsultantReviews').modal('show');
  }

  convertCurrency(currency: string) {
    switch (currency.toLowerCase()) {
      case 'eur': return '€';
      case 'usd': return '$';
    }
    return currency.toLowerCase();
  }

//
  // check if there is any registred card
  checkCard() {
    const availableChat = this.benefits.find(({ type, status }) => type === 'chat' && status === 'available');
    const availableCb = this.benefits.find(({ type, status }) => type === 'conversation' && status === 'available');
    this.loginService.sharedUser.subscribe(
      (customerId: any) => {
        this.customerId = customerId;
      },
      error => {
        console.log(error);

      }
    );
    this.paiementService.sendGetcards(this.customerId, this.merchandId).subscribe(
      (res: any) => {

        if (res.statusCode === undefined) {

          this.emptyCard = true;
          for (const card of res.data.cards) {
            if (card.active === true) {
              this.emptyCard = false;
            }
          }

          if (this.emptyCard !== true) {
            console.log(availableChat, availableCb);

            if(availableChat && availableCb){
              this.openSelectOptions();
            }
            else if(availableChat && !availableCb){
             this.openConversation(this.consultantID);
             console.log(availableChat);

            }
            else if(availableCb && !availableChat){
              console.log(availableCb);
              $('#converationSelect').modal('show');
            }
          } else {
            $('#addPaymentCardNotice').modal('show');
          }

        } else {
          $('#converationError').modal('show');
        }

      },
      err => {
        console.log(err);
        $('#converationError').modal('show');
      }
    );
  }

  showSVAModal(time = null, service = null) {
    if (time === 'Maintenant' && service === 'audiotel') {
      this.activateLink = true;
    }
    this.consultantservice.showSVAModalAction(this.activateLink);
  }

  /**
   * - Close if there's any prior open modal
   * - Retrieve if there's any old conversation
   *    - There's and the remaining_time is greater than zero: Open chat modal
   *    - Else: Process the pack payment
   * @param Number consultantID
   * @returns void
   */
  public openConversation (consultantID , isExtended = false) {
    /**
     * Make sure to close if there's any open modal (payment and chat)
     */
    this.isConversationExtended = isExtended;
    if(!isExtended){
    this.closeConversation()
  }
    console.log('openConversation 1')

   this.consultantservice.getconversation(this.consultantID,this.customerId).subscribe(
        ((response: any) => {
          console.log('openConversation 2')
          // Check if there's any actif conversation
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('remaining_time') && parseInt(response.data.remaining_time) > 0) {
            console.log('openConversation 2')
            this.conversationPaymentSucceded(response.data)
          } else {
            console.log('openConversation 3')
            // Trigger payment process
            this.processPackPayment(consultantID)
          }
        }),
        (error: any) => {
          console.log('openConversation 4')
          // Trigger payment process
          this.processPackPayment(consultantID)
        }
      )
  }

  processPackPayment(consultantID) {
    console.log('processPackPayment')

    // this.activateLink = true;
    //this.consultantservice.showSVAModalAction(this.activateLink);
    if(this.statusCode == 0 || this.statusCode == 6){
      const paimentModal: any = $('#selectChatPackModal')
      paimentModal.modal('show');
      this.paymentModalIsVisible = true
    }
    else {
      console.warn('Consultant unavailable')
      alert('Le consultant est absent!')
      return
    }

  }

  /**
   * If the pack has just been paid and not enabled yet, process the payment to enable
   * this recent paid pack
   * otherwise open the chat modal
   * @param data any
   */
  conversationPaymentSucceded = (data: any) => {
    // Hide payment modal if already open
    const paimentModal: any = $('#selectChatPackModal')
    paimentModal ? paimentModal.modal('hide') : null;
    this.paymentModalIsVisible = false
    console.log('conversationPaymentSucceded 1')

    // if data has the property `transaction_id` it means, the payment has just processed
    // Then we must activate the pack by creating or appending the conversation with the according remaining_time
    if (data.hasOwnProperty('enabled') && !data.enabled) {
      // Retrieve the paidPack if there is any

      // process-payment endpoint
     this.consultantservice.processPayment(data.transaction_id)
        .subscribe(
          ((response: any) => {
            console.log(response.data);
            console.log('conversationPaymentSucceded 2')
            let paidPack: PaidPackSchema = {
              consultant_id: response.data.consultant_id,
              remaining_time: response.data.remaining_time,
              transaction_id: response.data.last_transaction_id,
              authorization_id: response.data.authorization_id,
              customer_id: this.customerId,
              consultation_mode: 'chat',
              enabled: true,
              conversation_started_at: response.data.conversation_started_at,
              watcher_value: null,
              service_duration: response.data.service_duration,
              total_amount: response.data.total_amount,
              conversation_status: response.data.conversation_status,
              payment_one_click_id: response.data.payment_one_click_id,
            }
            this.paidPack = paidPack

            // Open the conversation model when the payment is done
            if(!this.isConversationExtended){
              this.chatModalIsVisible = true
            }
            else{
              console.log(this.paidPack);

              this.extendConversation.updateIsExtended(this.paidPack.remaining_time);
            }
          }),
          (error: any) => {
            console.log('conversationPaymentSucceded 3')
            console.warn('error', error)
          }
        )
    } else {
      console.log('conversationPaymentSucceded 4')
      let paidPack: PaidPackSchema = {
        consultant_id: data.consultant_id,
        remaining_time: data.remaining_time,
        transaction_id: data.last_transaction_id,
        authorization_id: data.authorization_id,
        customer_id: this.customerId,
        consultation_mode: 'chat',
        enabled: true,
        conversation_started_at: data.hasOwnProperty('conversation_started_at') ? data.conversation_started_at : null,
        watcher_value: data?.watcher_value,
        service_duration: data?.service_duration,
        total_amount: data?.total_amount,
        conversation_status: data.conversation_status,
        payment_one_click_id: data.payment_one_click_id,
      }
      this.paidPack = paidPack

      // Open the conversation model when the payment is done
      this.chatModalIsVisible = true
    }
  }


  /**
   *
   * @param amount Number, the amount to get from a given transaction
   * if null, it means get the full amount of the paidPack
   */
   sendGetMoneyRequest = async (paidPack: PaidPackSchema = null) => {
    // Only process if there's a pack that has been selected
    if (!paidPack) {
      return;
    }
    console.log('sendGetMoneyRequest 1')
    // Open payment modal
    this.finishPaymentModalIsVisible = true;
  }

  closeFinishPaymentModal = () => {
    this.finishPaymentModalIsVisible = false;
  }

  /**
   * @return void
   * close chat modal
   */
  closeConversation = () => {
    this.chatModalIsVisible = false
    this.selectedConsultant = null
    this.paymentModalIsVisible = false
  }

  openSelectOptions(){
    Swal.fire({
      title: 'Choisissez un mode de consultation',
      input: 'select',
      inputOptions: {
        'cb': 'CB',
        'chat': 'CHAT'
      },
      showCancelButton: true,
      confirmButtonColor: '#186429',
      confirmButtonText: 'Valider',
      cancelButtonText: 'Annuler',
      customClass: {
        container: 'container-swal-options-consultant-profile',
      },
      inputValidator:  (value) =>{
        return new Promise((resolve, reject) => {
          if (value !== '') {

            if(value == 'chat'){
                this.openConversation(this.consultantID);
                Swal.close();

              }else if (value == 'cb'){
                $('#converationSelect').modal('show');
                Swal.close();
              }
          } else {
            resolve('Veuillez renseigner ce champs');
          }
        });
      }
    }).then(function (result) {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          html: 'You selected: ' + result.value
        });
      }
    });
  }
}

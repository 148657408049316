import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Account} from './_models/account';
import {Definitions} from './_models/definitions';
import {AppState} from './app.state';
import {Session} from './_models/session';

declare var $: any;

function initResize() {
  $( window ).resize(() => {
    $('.sva-increased.full').each((index, e) => {
      const svaWith = $(e).width();
      const blockWidth = $(e).parent().parent().width();
      if (svaWith > blockWidth) {
        $(e).removeClass('zoom40').addClass('zoom32');
      } else {
        $(e).removeClass('zoom32').addClass('zoom40');
      }
    });
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  isLoggedIn: boolean;
  customerId: number;
  customerSessionId: string;
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  session: Observable<Session>;
  merchantId: number;
  officeId: number;
  redirect: {id: number, url: string};

  constructor(
    private store: Store<AppState>
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.session = store.select('session');
  }

  ngOnInit(): void {
    initResize();
    this.checkFailedSocialNetworksAuth()
  }

  /**
   * Show login modal
   * @returns null
   */
  openLoginModal() {
    // 
    setTimeout(() => {
      $('#loginModal').modal('show')
    }, 100)
  }

  /**
   * Check if the url query string contains the param 'socialLoginFailed' with value 'fb' or 'google
   * open the loginModal
   * @returns null
   */
  checkFailedSocialNetworksAuth(): any {
    // Retrieve condition from the queryString
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)

    // Test if the socialLoginFailed value is equal to 'true'
    if(!['fb', 'google'].includes(searchParams.get('socialLoginFailed'))) return

    this.openLoginModal()
  }
}

<br>
<form [formGroup]="formEmailReset"  class="#" (submit)="onEmailReset()">
  <div class="row">
  <a (click)="returnResetMailModal()" class="btn-link col-sm-4 nav-link-mobiyo">< Retour</a>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-3">
      <label class="text-left">Votre email</label>
    </div>
    <div class="col-sm-1">
    </div>
    <div class="col-sm-5">
      <input
        type="email"
        formControlName="customerEmail"
        class="form-control"
        placeholder=""
        [ngClass]="{ 'is-invalid': f.customerEmail.errors  && !f.customerEmail.errors.required}"
        [ngClass]="{ 'is-valid': f.customerEmail.valid }"
        required>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-4">
    </div>
    <div class="col-md-5">
      <button class="btn  btn-success text-left btn-mobiyo" type="submit" [disabled]="!formEmailReset.valid" >Valider </button>
    </div>
  </div>

  <!-- Invalid  Email-->
  <div *ngIf="f.customerEmail.errors" class="row" id="invalidEmail-mobiyo">
    <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern" class="col-sm-1">
      <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
    </div>
    <div *ngIf="f.customerEmail.errors.email || f.customerEmail.errors.pattern" class="col-sm-10">
      <p class="col-sm-4">
        Votre email est incorrect
      </p>
    </div>
  </div>

  <!-- Email Already used-->
  <div *ngIf="f.customerEmail.hasError('emailExist') && !f.customerEmail.errors.required" class="row"
       id="usedEmail-mobiyo">
    <div *ngIf="f.customerEmail.errors" class="col-sm-1">
      <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
    </div>
    <div *ngIf="f.customerEmail.errors " class="col-sm-10">
      <label class="col-sm-4">
        Cet email est déja utilisé !
      </label>
    </div>
  </div>

</form>





import { Action } from '@ngrx/store';
import { Definitions } from '../_models/definitions';

export const SET_DEFINITIONS = '[DEFINITIONS] Set';
export const DELETE_DEFINITIONS = '[DEFINITIONS] Delete';

export class SetDefinitions implements Action {
  readonly type = SET_DEFINITIONS;

  constructor(public payload: Definitions) {}
}

export class DeleteDefinitions implements Action {
  readonly type = DELETE_DEFINITIONS;

  constructor() {}
}

export type Actions = SetDefinitions | DeleteDefinitions;

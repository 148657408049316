import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit {

  @Input() delivered: boolean = false
  @Input() fillColor: string = 'transparent'
  @Input() strokeColor: string = '#2196f3'

  constructor() { }

  ngOnInit(): void {
  }

}

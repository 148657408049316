import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {LoginService} from './login.service';
import { environment } from '../../environments/environment';
import {HomeService} from './home.service';


@Injectable({
  providedIn: 'root'
})
export class PaiementService {

  htmlcontent: any;
  emptyCard: boolean;
  urlReturnToMerchant = window.location.origin;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  merchandId = environment.merchantId;
  paymentId: number;
  private apiUrl = environment.apiUrlPayment;
  private data: any;
  private  apiKey = environment.apiKey;


  constructor(
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService,
    private homeService: HomeService
  ) {
  }

  // check if there is any registred card
  checkCard() {
    this.loginService.sharedUser.subscribe(
      (customerId: any) => {
        this.customerId = customerId;
      },
      error => {
        console.log(error);

      }
        );
    this.sendGetcards(this.customerId, this.merchandId).subscribe(
      (res: any) => {

        if (res.statusCode === undefined) {


        this.emptyCard = true;
        for (const card of res.data.cards) {
          if (card.active === true) {
            this.emptyCard = false;
          }
        }

        if (this.emptyCard !== true) {
          this.homeService.redirect('/add-payment-card');
        } else {
          document.getElementById('openModalAddPaymentCardNotice').click();
        }

      } else {
          this.homeService.redirect('/add-payment-card');

      }

      },
      err => {
        console.log(err);
      }
    );
  }

  // add new card
  addCard() {

    this.data = {
      merchantId: this.merchandId,
      customerId: parseInt(this.customerId, 10) || parseInt(localStorage.getItem('customerId'), 10),
      urlReturnToMerchant: this.urlReturnToMerchant + '' + location.pathname

    };
    this.setCard(this.data).subscribe(
      (res: any) => {
        this.htmlcontent = $(res);
        $(document.body).append(this.htmlcontent);
        this.htmlcontent.submit();
      },
      error => {
        document.getElementById('openModalErrorAddcard').click();
        console.log(error);

      }
    );
  }

  // make a request to get list of cards
  sendGetcards(customerId, merchantId) {
    const header = new HttpHeaders()
      .set('x-api-key', `${this.apiKey}`);
    const params = new HttpParams({
      fromObject: {
        customerId: customerId || localStorage.getItem('customerId'),
        merchantId
      }
    });

    return this.http.get(`${this.apiUrl}/get_cards`, {params: params , headers: header});
  }

  // make a request to add the card
  setCard(data) {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/set_card`, data, {headers: headers, responseType: 'text'});
  }

  // make a request to set status of the card
  setStatusCard(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('x-api-key', `${this.apiKey}`);

    return this.http.post(`${this.apiUrl}/set_status_card`, data, {headers: headers});
  }

  // make a request to make card primary
  makeCardPrimary(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('x-api-key', `${this.apiKey}`);
    return this.http.post(`${this.apiUrl}/set_main_card`, data, {headers: headers});
  }


}

<!-- Page content -->
<div class="container mt-4 my-4" xmlns:svg="">
    <!-- Steps -->
    <div *ngIf="servicetype !== 'Audiotel'" class="row justify-content-center mb-5 mt-5">
        <div class="flex_etapes d-flex justify-content-center w-100">
            <div class="first etape_out d-flex flex-column justify-content-center m-1 w-100">
                <div class="card card-dark bg-light card-steps-text ">
                    <div class="card-body ">
                        <div class="row font-weight-normal">
                            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                                <p *ngIf="isLogging;else elseBlock1" class="card-text steps-text ">
                                    Voyance immédiate en 3 étapes
                                </p>
                                <ng-template #elseBlock1>
                                    <p class="card-text steps-text">Voyance immédiate en 2 étapes </p>
                                </ng-template>
                            </div>
                            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                                <img [ngClass]="{'img-mavoyance-2':!isLogging,'img-mavoyance':isLogging}"
                                    src="/assets/images/mavoyance-01.png">
                                <p class="card-text steps-text ">1 Je choisis un voyant.
                                </p>
                            </div>

                            <div *ngIf="isLogging" [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                                <img class=" img-mavoyance" src="/assets/images/mavoyance-02.png">
                                <p class="card-text steps-text ">2 Je crée ou je me connecte sur mon compte.
                                </p>

                            </div>
                            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
                                <img *ngIf="isLogging" class=" img-mavoyance" src="/assets/images/mavoyance-03.png">
                                <p *ngIf="isLogging;else elseBlock2" class="card-text steps-text"> 3 Je choisi et pay la
                                    durée que je souhaite.</p>
                                <ng-template #elseBlock2>
                                    <img [ngClass]="{'img-mavoyance-2':!isLogging ,'img-mavoyance':isLogging}"
                                        src="/assets/images/mavoyance-02.png">
                                    <p class="card-text steps-text">2 Je choisi et paie la durée que je souhaite.</p>
                                </ng-template>
                            </div>
                            <div
                                [ngClass]="{'col-sm-3':!((isLoggedIn$ | async) === false && !isValidtoken) ,'col-sm-2':(isLoggedIn$ | async) === false && !isValidtoken}">
                                <p class="card-text steps-text">Je commence le chat instantanémment. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Consultants -->
    <div class="row row-landscape ">

        <!-- Loading -->
        <div *ngFor="let number of [1,2,3,4,5,6,7,8]" class="col-md-3">
            <ngx-content-loading *ngIf="loading" [height]="2000" [speed]="'1500ms'" [width]="1000">
                <svg:g height="1230" ngx-rect rx="10" ry="10" width="1000" x="70" y="70"></svg:g>
                <svg:g height="200" ngx-rect rx="100" ry="100" width="900" x="90" y="1350"></svg:g>
                <svg:g cx="100" cy="1650" ngx-circle r="50" ry="5"></svg:g>
                <svg:g height="100" ngx-rect rx="50" ry="50" width="800" x="170" y="1600"></svg:g>
                <svg:g cx="100" cy="1800" ngx-circle r="50" ry="5"></svg:g>
                <svg:g height="100" ngx-rect rx="50" ry="50" width="800" x="170" y="1750"></svg:g>
                <svg:g height="100" ngx-rect rx="50" ry="50" width="700" x="70" y="1900"></svg:g>
            </ngx-content-loading>
        </div>


        <!-- List of consultants -->
        <div *ngFor="let consultant of listData " class="col-md-3 col-sm-3 col-xs-3 mt-3 col-sm-3-mobiyo">

            <div *ngIf="consultant.isvalidconsultant">
                <div class="card card-mobiyo card-Landscape">
                    <div class="card-top card-top-mobiyo">
                        <!-- statut-->
                        <div *ngIf="consultant.statutCode === 0; else elseBlock">
                            <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status">
                                <a class="text-white btn-details"
                                
                                (click)="redirectToConsultant(consultant.consultantid)">{{consultant.statut}}</a>
                                <!-- (click)="openConversation(consultant.consultantid)">{{consultant.statut}}</a> -->
                            </div>
                        </div>
                        <ng-template #elseBlock>
                            <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status ">
                                {{consultant.statut}}
                            </div>
                        </ng-template>
                        <a (click)="redirectToConsultant(consultant.consultantid)" class="btn-details">
                            <!-- <a (click)="openConversation(consultant.consultantid)" class="btn-details"> -->

                            <img alt="{{consultant.consultantlogin}}" class="card-img-top bg-light card-status"
                                src="{{consultant.consultantpictureurl || '/assets/images/male.png'}}">
                        </a>
                    </div>

                    <div class="bg-pink card-info">
                        <div class="text-left card-top-lang">
                            <span *ngFor="let lang of consultant.language" [ngbTooltip]="langTitle[lang]"
                                data-placement="top" data-toggle="tooltip" tooltipClass="tooltip-available tlp-top">
                                <i *ngIf="lang !== 'FR'" aria-hidden="true"
                                    class="fa-{{lang.toLocaleLowerCase()}}"></i>&nbsp;
                            </span>
                        </div>
                        <div class="text-center card-top-pseudo">
                            
                           <!--  <a (click)="openConversation(consultant.consultantid)"> -->
                            <a (click)="redirectToConsultant(consultant.consultantid)"
                                class="text-white btn-details">{{consultant.consultantlogin}}</a>
                        </div>
                    </div>


                    <div class="card-body">
                        <audio class="audio-icon" controls="" controlsList="nodownload"
                            src="{{consultant.consultantsoundurl}}" target=""></audio>
                            <!-- <a (click)="openConversation(consultant.consultantid)" class="text-black btn-details"> -->
                            <a (click)="redirectToConsultant(consultant.consultantid)" class="text-black btn-details">
                            <p class="card-text mt-2 card-text-mobiyo" maxlength="0">
                                {{consultant.consultantdescription?.length > 20 ? (consultant.consultantdescription |
                                slice:0:70) +
                                '...' : (consultant.presentation) }}
                            </p>
                        </a>
                        <hr>
                        <h4 class="card-title card-title-mobiyo">
                            <span>
                                <ngb-rating [rate]="consultant.reviewnote || 0" class="text-warning"></ngb-rating>
                                <span class="text-right card-review-number">
                                    {{ consultant?.nbconsultation || 0 }} Consultation
                                </span>
                            </span>
                            <a  (click)="ModalConsultantReviews(consultant)" [ngClass]="{'disabled': !(consultant?.reviewnote > 0)}" [routerLink]=""
                                class="rating-mobiyo review-link" queryParamsHandling="preserve"
                                style="font-size: 11px;">Consulter les avis
                                de {{consultant.consultantlogin}} ?</a>
                        </h4>


                    </div>
                    <div class="card-footer p-0 card-footer-mobiyo">
                        <h5 *ngIf="consultant.prestationSorted.length>0" class="text-center mt-2">Mes Prestations</h5>
                        <div class="row row-cols-2  px-3 pb-2 align-items-stretch">
                            <div *ngFor="let item of consultant.prestationSorted" class="col col-service px-2 mt-2">

                                <!-- con-->
                                <div *ngIf="item.name ==='Conversation'" [ngClass]="{
                      'service-available': item.active === 1 && consultant.statutCode === 0,
                      'service-busy': consultant.statutCode === 1 && item.active === 1,
                      'service-unavailable': item.active === 0
                    }" class="rounded p-2" style="min-height: 52px;">
                                    <ng-template #tootipConvContent>
                                        <p *ngIf=" consultant.statutCode === 0 && item.active === 1 "> Je suis
                                            disponible en conversation
                                            privée</p>
                                        <p *ngIf="consultant.statutCode === 1 && item.active === 1 "> Je suis
                                            actuellement en
                                            consultation</p>
                                        <p *ngIf="item.active === 0"> Je ne suis pas disponible en conversation privée
                                        </p>


                                    </ng-template>
                                     <!-- (click)="openConversation(consultant.consultantid)" -->
                                    <div *ngIf="activateLink; else elseBlock"><a
                                            (click)="redirectToConsultant(consultant.consultantid)"
                                            class="text-black btn-details">
                                            <div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center"
                                                placement="right" tooltipClass="{{this.tooltipClass}}">
                                                <span [ngClass]="{
                          'fa-conv-available': consultant.statutCode === 0,
                          'fa-conv-busy': consultant.statutCode === 1,
                          'fa-conv-unavailable': item.active === 0
                        }" class="icon-service-type mr-1">
                                                </span>
                                                <span class="price-service-type text-nowrap">
                                                    {{consultant.conversationcost}}{{consultant.currency?.replace('EUR',
                                                    '€')}}/min
                                                </span>
                                            </div>
                                        </a></div>
                                    <ng-template #elseBlock>
                                        <div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center"
                                            placement="right" tooltipClass="{{this.tooltipClass}}">
                                            <span [ngClass]="{
                          'fa-conv-available': consultant.statutCode === 0,
                          'fa-conv-busy': consultant.statutCode === 1,
                          'fa-conv-unavailable': item.active === 0
                        }" class="icon-service-type mr-1">
                                            </span>
                                            <span class="price-service-type text-nowrap">
                                                {{consultant.conversationcost}}{{consultant.currency?.replace('EUR',
                                                '€')}}/min
                                            </span>
                                        </div>
                                    </ng-template>

                                </div>
                                <!-- chat -->
                                <div *ngIf="item.name ==='Chat'" [ngClass]="{
                     'service-available': item.active === 1  && consultant.statutCode === 0 ,
                     'service-busy':consultant.statutCode === 1 && item.active === 1,
                     'service-unavailable':item.active === 0
                    }" class="rounded p-2" style="min-height: 52px;">
                                    <ng-template #tootipChatContent>
                                        <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible
                                            en Chat</p>
                                        <p *ngIf="consultant.statutCode === 1 && item.active === 1 "> Je suis
                                            actuellement en
                                            consultation </p>
                                        <p *ngIf="item.active === 0"> Je ne suis pas disponible en Chat</p>

                                    </ng-template>
                                    <div [ngbTooltip]="tootipChatContent" class="d-flex align-items-center"
                                        placement="right" tooltipClass="{{this.tooltipClass}}">
                                        <span [ngClass]="{
                          'fa-chat-available': consultant.statutCode === 0,
                          'fa-chat-busy': consultant.statutCode === 1,
                          'fa-chat-unavailable': item.active === 0
                        }" class="icon-service-type mr-1"></span>
                                        <span
                                            class="price-service-type ">{{consultant.chatcost}}{{consultant.currency?.replace('EUR',
                                            '€')}}
                                            /min</span>
                                    </div>
                                </div>
                                <!---audiotel -->
                                <div *ngIf="item.name ==='Audiotel'" [ngClass]="{
                      'service-available': (item.active === 1  && consultant.statutCode === 0) || servicetype === 'Audiotel',
                      'service-busy':consultant.statutCode === 1 && item.active === 1,
                      'service-unavailable':item.active === 0 && servicetype !== 'Audiotel'
                    }" class="rounded p-1 text-center h-100" style="min-height: 52px;">
                                    <ng-template #tootipAudiotelContent>
                                        <p
                                            *ngIf="(item.active === 1  && consultant.statutCode === 0) || servicetype === 'Audiotel'">
                                            Je
                                            suis disponible en Audiotel</p>
                                        <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis
                                            actuellement en
                                            consultation</p>
                                        <p *ngIf="item.active === 0 && servicetype !== 'Audiotel'"> Je ne suis pas
                                            disponible actuellement
                                            en Audiotel</p>
                                    </ng-template>

                                    <div [ngClass]="{'btn': consultant.statutCode === 0 && item.active === 1  }"
                                        [ngbTooltip]="tootipAudiotelContent" class="d-flex h-100 align-items-center"
                                        placement="right" tooltipClass="{{this.tooltipClass}}">
                                        <app-sva *ngIf="definitions | async" [definitions]="definitions | async"
                                            [full]="true" [isLinkActive]="activateLink"
                                            [ngClass]="{'ml-mobiyo': consultant.statutCode === 0 && item.active === 1 }"
                                            [zoom]="40" class="mx-auto"></app-sva>
                                    </div>


                                </div>
                                <!--mail-->
                                <div *ngIf="item.name === 'Mail'" [ngClass]="{
                      'service-available': item.active === 1 && consultant.statutCode === 0,
                      'service-busy': consultant.statutCode === 1 && item.active === 1,
                      'service-unavailable': item.active === 0
                    }" class="rounded p-2" style="min-height: 52px;">
                                    <ng-template #tootipMailContent>
                                        <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible
                                            en Mail</p>
                                        <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis
                                            actuellement en
                                            consultation </p>
                                        <p *ngIf="item.active === 0 "> Je ne suis pas disponible en Mail</p>
                                    </ng-template>
                                    <div [ngbTooltip]="tootipMailContent" class="d-flex align-items-center"
                                        placement="right" tooltipClass="{{this.tooltipClass}}">
                                        <span [ngClass]="{
                          'fa-mail-available': item.active === 1 && consultant.statutCode === 0,
                          'fa-mail-busy':consultant.statutCode === 1 && item.active === 1,
                          'fa-mail-unavailable':item.active === 0
                        }" class="icon-service-type mr-1"></span>
                                        <span class="price-service-type">{{consultant.mailcost}}
                                            {{consultant.currency?.replace('EUR',
                                            '€')}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>


            </div>
        </div>
    </div>
</div>

<!-- <app-chat-modal *ngIf="chatModalIsVisible" [consultant]="selectedConsultant" [paidPack]="paidPack"
    [customerID]="customerID" (processGetMoney)="sendGetMoneyRequest($event)"
    (closeConversationModal)="closeConversation()"></app-chat-modal>

<app-finish-payment *ngIf="finishPaymentModalIsVisible" (close)="closeFinishPaymentModal()" [paidPack]="paidPack" [consultant]="selectedConsultant">
</app-finish-payment>

<div class="modal fade" id="selectChatPackModal" tabindex="-1" role="dialog" aria-labelledby="converationSelectLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Sélection du tarif</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <app-select-chat-pack *ngIf="selectedConsultant && paymentModalIsVisible" [customerInfo]="customerInfo"
                [consultant]="selectedConsultant" [isExtendCall]=false
                (paymentSuccess)="conversationPaymentSucceded($event)"></app-select-chat-pack>
        </div>
    </div>
</div> -->

<!-- Modal consultant reviews -->

<div aria-hidden="false" aria-labelledby="ModalessageLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantReviews" role="dialog" tabindex="-1">
  <ng-template #t let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
    </span>
  </ng-template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{consultantInfo?.consultantlogin}}&nbsp;
          <ngb-rating [starTemplate]="t" [readonly]="true" [max]="5"  *ngIf="consultantInfo?.reviewnote>0" [rate]="consultantInfo?.reviewnote" class="text-warning">
          </ngb-rating>&nbsp;
          <span *ngIf="consultantInfo?.reviewcount>0">({{consultantInfo?.reviewcount}} avis)</span>
        </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="showAllNotice">
        <app-show-reviews *ngIf="consultantInfo" [consultantId]="consultantInfo.consultantid"
          [consultantImageUrl]="consultantInfo.consultantpictureurl" id="allNotices">
        </app-show-reviews>
      </div>
      <div *ngIf="showAddNotice" class="p-4">
        <app-add-review *ngIf="consultantInfo" [closeModal]=false [consultantId]="consultantInfo.consultantid"
          [consultantName]="consultantInfo.consultantlogin" [numberOfCall]=1 [redirect]=false id="addNotice">
        </app-add-review>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="false" aria-labelledby="modalConsultantReviewsErrorLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantReviewsErrorLabel">Les avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de visualiser
              les avis.</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalConsultantAddReviewsError  -->

<div aria-hidden="false" aria-labelledby="modalConsultantAddReviewsErrorLabel" class="modal fade" data-backdrop="static"
  id="modalConsultantAddReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantAddReviewsErrorLabel">Donner son avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de prendre en
              compte votre avis !</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>
import { Definitions } from '../_models/definitions';
import * as DefinitionsActions from '../actions/definitions.actions';

/*const initialState: Definitions = {
}*/

export function reducer(state: Definitions, action: DefinitionsActions.Actions) {
  switch (action.type) {
    case DefinitionsActions.SET_DEFINITIONS:
      return action.payload;
    case DefinitionsActions.DELETE_DEFINITIONS:
      return undefined;
    default:
      return state;
  }
}

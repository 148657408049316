<div class="modal-header">
  <h4 class="modal-title">{{ title }}!</h4>
  <button (click)="activeModal.close('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-2">
      <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
    </div>
    <div class="col-md-10">
      <p >
        {{ body }}
      </p>
    </div>
  </div>

  <ng-template quickView></ng-template>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-success text-center"    type="button">
    Continuer
  </button>

</div>



import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '../_services/register.service';
import { environment } from '../../environments/environment';
import { ContactService } from '../_services/contact.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  formContact: FormGroup;

 
  constructor(private fb: FormBuilder,
    private contactservice: ContactService){}
    //, private contact: ContactService) { }
  ngOnInit(): void {
    this.formContact = this.fb.group({
      customerEmail: ['', [Validators.required, Validators.email, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
      comment: ['', [Validators.required]],
      fullName : ['',[Validators.required]]
       
    });
  }
  get f() {
    return this.formContact.controls;
  }
  onSubmitContact() {

      const mailData = {
        merchantId: environment.merchantId,
        senderMail : 'direction@allomegane.com',
        recipientMail: 'direction@allomegane.com',
        templateParams: {message:this.formContact.value.comment ,email : this.formContact.value.customerEmail,fullname:this.formContact.value.fullName},
        template: 'url_template_contact_us',
        subject: 'Nouveau message de Contacter nous'
      };
        this.contactservice.sendEmail(mailData).subscribe(
          (result2: any) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Message Envoyé',
              showConfirmButton: false,
              timer: 1500,
            
            })
            this.formContact.reset();
          },
          (error) => {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Message non envoyé ',
              showConfirmButton: false,
              timer: 1500    
            })
            this.formContact.reset();
          
          }
        );

  }

}

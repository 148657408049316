import { Component, Input, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../_services/login.service';
import { ForfaitService } from '../../_services/forfait.service';
import { PaiementService } from '../../_services/paiement.service';
import { DatePipe } from '@angular/common';
import { ConsultantService } from '../../_services/consultant.service';
import { PrivateConversationService } from '../../_services/private-conversation.service';
import DateTimeHelper from '../../_helpers/date.time.helper';

declare const $: any;
import { environment } from '../../../environments/environment';
import { interval, Observable } from 'rxjs';
import { Definitions } from '../../_models/definitions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { HomeService } from '../../_services/home.service';
import { CryptoService } from '../../_services/crypto.service';
import { PromotionService } from '../../_services/promotion.service';
import * as dayjs from 'dayjs';
import { log } from "util";
import { paidPack as PaidPackSchema } from '../../_models/paidPack'
import { exit } from 'process';
import { Console } from 'console';

@Component({
  selector: 'app-select-chat-pack',
  templateUrl: './select-chat-pack.component.html',
  styleUrls: ['./select-chat-pack.component.css']
})
export class SelectChatPackComponent implements OnInit {
  @Output() paymentSuccess: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() isExtendCall: boolean;
  @Input() customerInfo: any = null;
  @Input() isConversationExtended: boolean = false;

  customerID: Number;
  form: FormGroup;
  options: any;
  minutes: number;
  time = 10;
  merchantId: any;
  officeId: any;
  @Input() consultant: any;
  user = null;
  userEmail = null;
  cardPartialNumber = null;
  oneClickId = null;
  customerSessionId: any;
  historyId: number;
  authorizationId: any;
  consultationMode: any;
  selectedAmount: any;
  selectedProduct: any;
  step: number;
  error: boolean;
  closeInterval: any;
  public definitions: Observable<Definitions>;
  continue: boolean;
  public bonus: string;
  public finalTotal: number;
  public preTotal: number;
  public validCode = false;
  erroMessage: string;
  messageMerchant: string;
  minForfait: any;
  isMinForfaitValid = true;
  typeBuyPromDiff: any;
  public isSelected = false;
  isNotValidCodePromoMaxDuration = false;
  duration: number;
  bonusInTime = 0;
  price: number;
  codepromoMaxDuration: number;
  public promotionValue: string;
  isNotValidCodePromo = false;
  codePromo: string;
  erroCodePromo: boolean;
  isPromoAlreadyApplied = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private forfaitService: ForfaitService,
    private paiementService: PaiementService,
    private datePipe: DatePipe,
    private consultantService: ConsultantService,
    private privateConversationService: PrivateConversationService,
    private store: Store<AppState>,
    private homeService: HomeService,
    private crypto: CryptoService,
    private promotionService: PromotionService,
  ) {
    this.definitions = store.select('definitions');
    this.step = 1;
    this.error = false;
    this.continue = true;
  }

  ngOnInit(): void {
    document.addEventListener('keypress', this.submitForm);

    this.customerSessionId = null;
    this.userEmail = this.customerInfo?.customerEmail;
    this.customerID = this.customerInfo?.customerId;
    this.merchantId = environment.merchantId;
    this.officeId = environment.officeId;

    this.price = this.consultant.chatcost;
    this.consultationMode = 'CHAT';

    this.form = this.fb.group({
      conversationDuration: ['', [Validators.required]],
    });

    this.getOptions();

    this.modalSelectConversation();
  }

  /**
   * recupere la liste des forfaits

   * rajoute un champ paidAmount = au tarif horaire * duree du forfait
   */
  getOptions() {
    this.forfaitService.getBuy(this.merchantId, this.officeId, 'chat').subscribe(
      (res: any) => {
        this.options = res.body.filter(el => el.isValidate === 'True');
        this.options.forEach((el, index) => {
          this.options[index].paidAmount = Math.round(this.consultant.chatcost * el.numberPaidMin * 100) / 100;
        });
        if (this.isExtendCall) {
          const selectedElement = this.options.find(element => element.buyId === parseInt(localStorage.getItem('buyId')));
          this.minutes = selectedElement.numberPaidMin;
        } else {
          this.minutes = this.options[0].numberPaidMin;
        }
      },
      error => {
        this.fail();
      }
    );
  }

  /**
 *  add validation to the code promo input
 *
 */
  customValidator() {
    const el = $('#promoCodeChat').val();
    this.isNotValidCodePromo = false;
    this.isNotValidCodePromoMaxDuration = false;
    this.promotionValue = null;
    this.finalTotal = this.preTotal;
    this.validCode = el?.length === 4;
  }

    /*  delete promotion  */
    deletePromotion() {
      this.isNotValidCodePromo = false;
      this.isNotValidCodePromoMaxDuration = false ;
      this.codePromo = null;
      this.promotionValue = null;
      this.finalTotal = this.preTotal;
    }

      /**
   * apply promotion code
    * @param code
   * @param history
   * @param command
   */
  useCodePromotion(code , history , command) {
    const customerId = this.getCustomerID();
    const codePromo = this.getPromoCOde();
    if (code || codePromo) {
      this.promotionService.usePromotion(code, history , customerId , command).subscribe(
        (res: any) => {
          if (res.statusCode === undefined) {
          } else {
            this.erroCodePromo = true;
            this.error = false;
            this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
            this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
            return;
          }
        }, error => {
          this.erroCodePromo = true;
          this.error = false;
          this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
          this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
          return;

        });
    }
  }

  isNaN(price: any): boolean {
    return isNaN(price);
  }

  redirect() {
    // Pack has been paid
    console.log('[select-chat-pack] Paid')
  }

  spin(status) {
    const btn = document.getElementById('btnPaiementChat');
    if (btn) {
      if (status === 'on') {
        btn.setAttribute('disabled', 'disabled');
        btn.innerHTML = '' +
          '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i> Recherche en cours...';
      } else {
        btn.innerHTML = 'Valider';
        btn.removeAttribute('disabled');
      }
    }
  }

  askMoney() {
    if (this.form.valid) {
      console.log('askMoney 1')
      this.isPromoAlreadyApplied = true;
      this.continue = true;
      const duration = Number(this.form.value.conversationDuration);
      this.duration = duration;
      const price = Number(this.consultant.chatcost);
      const total = Math.round(duration * price * 100) / 100;
      /*const selectedProduct: PaidPackSchema = {
        customer_id: 817,
        consultant_id: 31294,
        consultation_mode: "chat",
        remaining_time: 60,
        transaction_id: 11409,
        authorization_id: 1,
        enabled: true,
        conversation_started_at: "2022-12-27 11:46:40",
        watcher_value: null,
        service_duration: 60,
        total_amount: 1,
        conversation_status: null,
        payment_one_click_id: "SMHLuSxLLHc",
      };*/
      console.log('checkAmount 5')
      // Emit paid success action
      // this.paymentSuccess.emit(selectedProduct)
      this.checkAmount(total, duration, price);

    }
  }

  checkAmount(total, duration, price) {
    console.log('checkAmount 1')
    const dateNow = dayjs();
    this.spin('on');
    if (!this.continue) {
      return false;
    }

    this.paiementService.sendGetcards(this.customerID, this.merchantId).subscribe(
      (result2: any) => {
        console.log('checkAmount 2')
        if (result2.statusCode === undefined) {
          if (result2.data.cards.length === 0) {
            this.fail();
            return;
          }
          for (const card of result2.data.cards) {

            let dateString = card.exp_month + '-01-' + card.exp_year;
            dateString = dateString.replace(/-/g, '/');
            const carDate = dayjs(dateString).format();
            if (card.active === true && card.main_payment === true && dateNow.format() < carDate) {
              this.cardPartialNumber = card.partial_number;
              this.oneClickId = card.one_click_id;
            }
          }

          if (this.cardPartialNumber === null) {
            for (const card of result2.data.cards) {

              let dateString = card.exp_month + '-01-' + card.exp_year;
              dateString = dateString.replace(/-/g, '/');
              const carDate = dayjs(dateString).format();
              if (card.active === true && dateNow.format() < carDate) {
                this.cardPartialNumber = card.partial_number;
                this.oneClickId = card.one_click_id;
              }
            }
          }

          const data = {
            merchantId: this.merchantId.toString(), // <= toString a virer !#
            partialNumber: this.cardPartialNumber,
            amount: this.validCode ? this.finalTotal.toString() : total.toString(), // <= toString a virer !#
            customerEmail: this.userEmail || this.customerInfo?.customerEmail,
            consultantId: String(this.consultant.consultantid),
            consultationMode: this.consultationMode,
            duration: DateTimeHelper.MinutesToTimeFormat(duration),
            dateTime: this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')
          };

          if (!this.continue) {
            return false;
          }
          this.forfaitService.setHistory(data).subscribe(
            (res: any) => {
              if (res.id === undefined) {
                this.fail();
                return;
              }
              console.log('checkAmount 3')
              this.historyId = res.id;
              let amounts = [];
              this.useCodePromotion(this.codePromo, this.historyId , 'USE');
              if (this.isExtendCall) {
                localStorage.setItem('otid', localStorage.getItem('ntid'));
              }

              localStorage.setItem('ntid', res.id);


              const options = this.options;
              options.forEach(element => {
                if (element.paidAmount < data.amount) {
                  amounts.push(element.paidAmount);
                }
              });

              amounts = amounts.sort((a, b) => {
                return a > b ? -1 : 1; // sort desc
              });

              amounts.unshift(data.amount);

              const allAmounts = amounts.join('|');
              if (!this.continue) {
                return false;
              }
              this.forfaitService.getAskMoney(this.customerID, this.oneClickId, 'EUR', allAmounts, this.historyId).subscribe(
                (res2: any) => {
                  if (res2.statusCode === -16) {
                    this.fail(2);
                    this.useCodePromotion(this.codePromo, this.historyId , 'RELEASE');
                    return;
                  }
                  console.log('checkAmount 4')
                  if (res2.data === undefined) {
                    this.fail();
                    this.useCodePromotion(this.codePromo, this.historyId , 'RELEASE');
                    return;
                  }
                  // success on recupere le authorization_id
                  if (res2.data.authorization_id) {
                    this.authorizationId = res2.data.authorization_id;
                    this.selectedAmount = parseFloat(total); // montant retourner en string !#
                    this.spin('off');
                    const selectedOption = this.options.find(element => element.paidAmount === this.selectedAmount);
                    localStorage.setItem('buyId', selectedOption.buyId);
                    if (selectedOption === undefined) {
                      this.fail();
                      this.useCodePromotion(this.codePromo, this.historyId , 'RELEASE');
                      return;
                    }
                    const selectedProduct: PaidPackSchema = {
                      customer_id: this.customerID,
                      consultant_id: this.consultant.consultantid,
                      consultation_mode: this.consultationMode,
                      remaining_time: (selectedOption.numberPaidMin + this.bonusInTime) * 60,
                      transaction_id: this.historyId,
                      authorization_id: this.authorizationId,
                      enabled: false,
                      conversation_started_at: null,
                      watcher_value: null,
                      service_duration: (selectedOption.numberPaidMin + this.bonusInTime) * 60,
                      total_amount: this.finalTotal,
                      conversation_status: null,
                      payment_one_click_id: this.oneClickId,
                    };
                    console.log('checkAmount 5')
                    // Emit paid success action
                    this.paymentSuccess.emit(selectedProduct)
                  }
                },
                error => {
                  this.fail();
                }
              );
            },
            error => {
              this.fail();
              this.useCodePromotion(this.codePromo, this.historyId , 'RELEASE');
            }
          );

        } else {
          this.fail();

        }
      },
      error => {
        this.fail();
      }
    );
  }

  modalSelectConversation() {
    $('#converationSelect, #converationReSelect')
      .on('show.bs.modal', () => {
        this.reset();
      })
      .on('hidden.bs.modal', () => {
        this.reset();
      })
      .on('hide.bs.modal', (e) => {
        if (this.continue) {
          e.preventDefault();
          e.stopImmediatePropagation();
          return false;
        }
      });
  }

  next(step: number, redirect = false): void {
    this.step = step;
    this.error = false;
    this.spin('off');
    this.continue = false;
    if (step === 2 && !this.error) {
      if (redirect) {
        this.redirect();
      } else {
        this.closeInterval = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(this.closeInterval);
            this.redirect();
          }
        }, 1000);
      }
    }
  }

  fail(step = this.step): void {
    this.step = step;
    this.error = true;
    this.spin('off');
    clearInterval(this.closeInterval);
    this.continue = false;
  }

  reset(): void {
    this.step = 1;
    this.error = false;
    this.time = 10;
    this.spin('off');
    this.continue = false;
    this.isMinForfaitValid = true;
    this.isSelected = false;
    this.finalTotal = this.preTotal;
    this.validCode = false;
    this.bonusInTime = 0;
    if (!this.isExtendCall) {
      clearInterval(this.closeInterval);
    }

  }



  /**
   *  check forfait minimal
   */
  checkForfaitMinimal(price, bonus) {
    let minimalForfait: any;
    const minDiff = (bonus / 100) * this.typeBuyPromDiff;
    minimalForfait = bonus + minDiff;
    return minimalForfait;
  }

  /**
   *
   * @param e
   * @param price
   * @param minutes
   */
  stopEnterSubmitting(e, price, minutes) {
    this.preTotal = price * minutes;
    this.finalTotal = this.preTotal;
  }

  submitForm(e) {
    const finalTotal = (document.getElementById('finalTotal') as HTMLInputElement)?.getAttribute('data-value');
    if (e.key === 'Enter' && finalTotal) {
      document.getElementById('btnPaiementChat').click();
    }
  }

  onpaste(e) {
    const promoCode = document.getElementById('promoCodeChat');
    promoCode?.addEventListener('input', event => {
      const { target } = event;
      if (target) {
        const el = (target as HTMLButtonElement).value;
        this.validCode = el?.length === 4;
      }
    });
    console.log(this.validCode);
  }

  applyPromotion(prices, minutes) {
    console.log('minutes', minutes);
    this.codePromo = this.getPromoCOde();
    console.log('code promo', this.codePromo);
    this.preTotal = prices * minutes;
    this.finalTotal = this.preTotal;
    console.log('his.finalTotal', this.finalTotal);
    if (this.codePromo) {
      this.checkPromoCode(this.codePromo, minutes);
    }
  }

  getPromoCOde() {
    this.codePromo = (document.getElementById('promoCodeChat') as HTMLInputElement)?.value;
    if (this.codePromo?.length === 4) {
      return this.codePromo;
    }
    return undefined;
  }

  /* check promo code*/
  checkPromoCode(code, minutes) {
    const customerId = this.getCustomerID();
    console.log('this.getCustomerID', this.getCustomerID);
    this.promotionService.checkPromotion(code, customerId).subscribe(
      (res: any) => {
        if (res.statusCode === undefined) {
          if (res.isValid && res.isActive) {
            console.log('res', res);
            let selectedBuyType = this.options.find(element => element.numberPaidMin == minutes);
            console.log('selectedBuyType', selectedBuyType);
            if (res.typesBuy.indexOf(selectedBuyType.buyId) == -1) {
              this.isNotValidCodePromoMaxDuration = true;
              this.codepromoMaxDuration = res.dureeMax;
              console.log('this.codepromoMaxDuration', this.codepromoMaxDuration);
              this.isNotValidCodePromo = true;
            }
            else {
              this.isNotValidCodePromo = false;
              this.isNotValidCodePromoMaxDuration = false;
              this.calculateTotal(res.promotionNature, res.promotionValue);
            }
          } else {
            this.isNotValidCodePromo = true;
          }
        } else {
          this.erroCodePromo = true;
          this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
          this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
        }
      }, error => {
        this.erroCodePromo = true;
        this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  de vérifier le code de promotion';
        this.messageMerchant = 'Merci de contacter directement';

      });
  }

  getBonus(promotionType, promotionValue) {
    if (promotionType === 'AMOUNT') {
      return promotionValue + '€ de remise';
    } else if (promotionType === 'PERCENTAGE') {
      return promotionValue + '% de remise';
    } else {
      this.bonusInTime = promotionValue;
      return promotionValue + ' min. de bonus';
    }

  }

  /* calculate final total*/
  calculateTotal(promotionType, promotionValue) {
    this.promotionValue = this.getBonus(promotionType, promotionValue);
    if (promotionValue && promotionType === 'AMOUNT') {
      this.bonus = promotionValue + '€';
      this.minForfait = this.checkForfaitMinimal(this.preTotal, promotionValue);
      const diffForfait = this.preTotal - this.minForfait;
      if (diffForfait > 0) {
        this.finalTotal = this.preTotal - promotionValue;
        this.isMinForfaitValid = true;
      } else {
        this.isMinForfaitValid = false;
      }
    } else if (promotionValue && promotionType === 'PERCENTAGE') {
      const percentage = (this.preTotal / 100) * promotionValue;
      this.bonus = percentage.toFixed(2) + ' €';
      this.finalTotal = this.preTotal - percentage;
      if (this.finalTotal <= 0) {
        this.isMinForfaitValid = false;
        this.minForfait = this.checkForfaitMinimal(this.preTotal, promotionValue);
      } else {
        this.isMinForfaitValid = true;
      }
    } else {
      this.bonus = null;
      this.finalTotal = this.preTotal;
    }
  }

  getCustomerID() {
    this.loginService.sharedUser.subscribe(
      (customerId: any) => {
        return customerId;
      },
      error => {
        console.log(error);

      }
    );
  }

  extendCall() {
    const data = {
      merchantId: this.merchantId,
      oldTransactionId: localStorage.getItem('otid'),
      newTransactionId: localStorage.getItem('ntid'),
      oldSessionId: parseInt(localStorage.getItem('nsid')),
      consultantId: this.consultant.consultantid,
      clientId: this.customerID,
      record: 'true',
      duration: (this.duration + this.bonusInTime) * 60
    };

    this.privateConversationService.extendCall(data).subscribe(
      (res: any) => {
        if (res.sessionId !== undefined) {
          localStorage.setItem('osid', localStorage.getItem('nsid'));
          localStorage.setItem('nsid', res.sessionId);

        } else {
          $('#converationReSelect').modal('hide');
          $('#errorExtendCall').modal('show');

        }
      },
      error => {
        $('#converationReSelect').modal('hide');
        $('#errorExtendCall').modal('show');
      }
    );


  }

  changeOffer(buyId: any) {

  }
}
